<template>
  <div class="channel">
    <el-row style="margin-left: 55px;margin-top: 20px;">
      <el-col :span="2">
        <div class="grid-content bg-purple">
          <el-button
            v-if="$global.comparePermisson('系统管理', 'channel_add_update')"
            type="primary"
            plain
            class="rolebtn"
            @click="addChannelForm"
            >添加渠道</el-button
          >
          <!-- //角色信息弹窗 -->
          <el-dialog
            :title="title"
            :visible.sync="dialogFormVisible"
            :close-on-click-modal="false"
            @close="closeDialog"
          >
            <el-form
              v-loading="loading"
              ref="form"
              :model="form"
              :rules="rolerules"
            >
              <el-form-item
                label="渠道名称"
                :error="errorCodeMsg"
                prop="channelCode"
                label-width="260"
              >
                <el-input
                  :disabled="disabled"
                  v-if="isAdd"
                  v-model="form.channelCode"
                  autocomplete="off"
                  style="width:232px;height:24px;"
                  @blur="channelCodeBlur"
                ></el-input>
                <span v-if="!isAdd">{{ form.channelCode }}</span>
              </el-form-item>
              <el-form-item
                label="渠道描述"
                :error="errorDescMsg"
                prop="channelDescription"
                label-width="260"
              >
                <el-input
                  :disabled="disabled"
                  v-model="form.channelDescription"
                  autocomplete="off"
                  style="width:232px;height:24px;"
                  @blur="channelDescriptionBlur"
                ></el-input>
              </el-form-item>

              <el-form-item
                label="生成二维码"
                :error="errorDescMsg"
                prop="isQrcode"
                label-width="260"
              >
                <el-radio-group v-model="form.isQrcode">
                  <el-radio :label="1">生成</el-radio>
                  <el-radio :label="0">不生成</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
            <div
              v-if="$global.comparePermisson('系统管理', 'channel_add_update')"
              slot="footer"
              class="dialog-footer"
            >
              <el-button @click="hideDialogFormVisible">取 消</el-button>
              <el-button
                v-if="!isAdd"
                :disabled="loading || disabled"
                type="primary"
                @click="updateChannel"
                >确 定</el-button
              >
              <el-button
                v-else
                :autofocus="autofocus"
                :disabled="loading"
                type="primary"
                @click="addChannel"
                >确 定</el-button
              >
            </div>
          </el-dialog>
        </div>
      </el-col>
    </el-row>
    <div class="role-table">
      <el-table
        v-loading="listLoading"
        :data="rows"
        header-row-class-name="tableHead"
        style="padding-bottom:47px;text-align:center;"
      >
        <el-table-column
          v-if="$global.comparePermisson('系统管理', 'role_details')"
          :show-overflow-tooltip="false"
          prop
          label
          min-width="80"
          max-width="8%"
        >
          <template slot-scope="scope">
            <i class="el-icon-edit" @click="editChannelForm(scope.row)"></i>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="false"
          prop="channelCode"
          label="渠道名称"
          min-width="150"
          max-width="17%"
        >
          <template slot-scope="scope">
            <span style="display:inline-block;width:100%; text-align:center;">{{
              scope.row.channelCode
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="false"
          prop="desc"
          label="描述"
          class="description"
          min-width="200px"
          max-width="21%"
        >
          <template slot-scope="scope">
            <span
              style="display:inline-block;width:100%; text-align:left;color:#606266;"
              >{{ scope.row.channelDescription }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="false"
          prop="id"
          label="生成二维码"
  width="200">
          <template slot-scope="scope">

          <el-button
              type="primary"
              class="sub_review sub"
              style="width:50px;"
              plain
              @click="remove(scope.row)"
              >删除</el-button>

            <el-button
              v-if="scope.row.isQrcode"
              type="primary"
              class="sub_review sub"
              style="width:80px;"
              plain
              @click="createQrcode(scope.row)"
              >生成二维码</el-button
            >
          </template>
        </el-table-column>

        <!-- <el-table-column
          v-if="$global.comparePermisson('系统管理', 'role_delete')"
          :show-overflow-tooltip="false"
          prop="status"
          label
          min-width="100"
          max-width="16%"
        >
          <template slot-scope="scope">
            <i
              v-if="!(scope.row.isSystem == 1)"
              class="el-icon-close"
              @click="deleteRole(scope.row)"
            ></i>
          </template>
        </el-table-column> -->
      </el-table>
    </div>

    <el-dialog
      :title="title"
      :visible.sync="dialogQrcodeVisible"
      :close-on-click-modal="false"
      @close="closeDialog"
    >
      <div class="qrcode" ref="qrCodeUrl"></div>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  name: "User",
  data() {
    return {
      autofocus: false,
      loading: false,
      listLoading: false,
      //错误提示字段
      errorCodeMsg: "",
      errorDescMsg: "",
      errorIdMsg: "",
      errorPermisson: "",
      //控制输入roleid显示隐藏
      // hasRoleId: false,
      disabled: false,
      //权限组内值，可在此定义初始选项
      checkedList: [],
      //添加编辑弹窗内容变量
      title: null,
      // giveName: null,
      // giveDescription: null,
      //定义添加编辑变化开关
      isAdd: true,
      //table索引值
      // userIndex: null,
      //表单数集
      form: {
        channelCode: null,
        channelDescription: null,
        isQrcode: 0,
      },

      //列表数据
      rows: [],
      //弹窗显示隐藏状态
      dialogFormVisible: false,
      dialogQrcodeVisible: false,
      rolerules: {
        name: [
          { min: 1, max: 50, message: "长度需小于50个字符", trigger: "blur" },
        ],
        desc: [
          { min: 1, max: 50, message: "长度需小于50个字符", trigger: "blur" },
        ],
        roleId: [
          { min: 1, max: 50, message: "长度需小于50个字符", trigger: "blur" },
        ],
      },
      permissionList: [],
    };
  },
  created() {
    let data = {
      offset: -1,
    };
    // this.getPermissions(data)
    // this.getRoleList()

    this.getChannelList();
  },
  destroyed() {},
  methods: {
    //渠道名称失焦校验
    channelCodeBlur() {
      var reg = /^[a-z_0-9]+$/gi;
      var str = this.form.channelCode;
      if (!str) {
        this.errorCodeMsg = "渠道名称不能为空";
      } else if (!reg.test(str)) {
        this.errorCodeMsg = "渠道名称必须字母数字或者_的组合";
      } else {
        this.errorCodeMsg = "";
      }
      this.$forceUpdate();
    },
    //渠道描述失焦校验
    channelDescriptionBlur() {
      var reg = /(^\s+)|(\s+$)/g;
      var str = this.form.channelDescription;
      if (!str) {
        this.errorDescMsg = "渠道描述不能为空";
      }
      if (reg.test(str)) {
        this.errorDescMsg = "渠道描述首尾不能含空格";
      } else {
        this.errorDescMsg = "";
      }
      this.$forceUpdate();
    },
    //获取channel列表
    getChannelList() {
      let data = {
        offset: -1,
      };
      this.listLoading = true;
      this.$global
        .httpGetWithToken(this, "/channel/list", data)
        .then((res) => {
          console.log("列表", res);
          this.rows = res.data;
          // for (const attr in res.data) {
          //   this.roleList.push(res.data[attr]);
          // }
          this.listLoading = false;
          this.$forceUpdate();
        })
        .catch((error) => {
          this.listLoading = false;
        });
    },
    //添加渠道弹窗设置
    addChannelForm() {
      this.closeDialog();
      this.title = "添加渠道";
      this.dialogFormVisible = true;
      this.isAdd = true;
      // if (this.permissionList.length == 0) {
      //   this.loading = true
      // }
    },
    remove(row){
     const _this = this
     this.$confirm('是否确认删除该渠道?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        cancelButtonClass: '',
        autofocus: false,
        type: 'warning',
        center: true,
        beforeClose(action, instance, done) {
          if (action == 'confirm') {
            this.$nextTick(()=>{
              if (window.event.detail != 0) {
               //执行删除
                done()
                _this.listLoading = true
                _this.$global
                  .httpDeleteWithToken(_this, '/channel/remove/' + row.id)
                  .then((res) => {
                    _this.$notify({
                      title: '成功',
                      message: '删除成功',
                      type: 'success',
                      offset: 38,
                    })
                    _this.getChannelList()
                  })
                  .catch((err) => {
                    _this.listLoading = false;
                     _this.getChannelList()
                  })
              }
            })
          } else {
            done()
          }
        },
      }).catch(() => {})
    },
    createQrcode(row) {
      console.log(row);
      //https://vapi.msdp.cn/auth/wechat/register?channel_code=vbu
      let url=`${this.$global.baseUrl}/auth/wechat/register?channel_code=${row.channelCode}`;
      console.log(url);
      this.title = `${row.channelCode}的二维码`;
      this.dialogQrcodeVisible = true;
      setTimeout(() => {
        if (!this.qrcode) {
          this.qrcode = new QRCode(this.$refs.qrCodeUrl, {
            text: url, // 需要转换为二维码的内容
            width: 300,
            height: 300,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
          });
        } else {
          this.qrcode.clear(); // clear the code.
          this.qrcode.makeCode(url); // make another code.
        }
      }, 200);
    },
    resetForm() {
      this.form = {
        channelCode: null,
        channelDescription: null,
        isQrcode: 0,
      };
      this.dialogFormVisible = false;
    },
    addChannel() {
      if (!this.form.channelCode) {
        this.errorCodeMsg = "角色命名不能为空";
        return;
      } else if (!/^[a-z_0-9]+$/gi.test(this.form.channelCode)) {
        this.errorCodeMsg = "渠道名称必须字母数字或者_的组合";
        return;
      }

      if (!this.form.channelDescription) {
        this.errorDescMsg = "渠道描述不能为空";
        return;
      }
      let query = {
        data: {
          channelCode: this.form.channelCode,
          channelDescription: this.form.channelDescription,
          isQrcode: this.form.isQrcode,
        },
      };

      this.$global
        .httpPostWithToken(this, "/channel/add", query)
        .then((res) => {
          // this.roleList = []
          // for (const attr in res.data) {
          //   this.roleList.push(res.data[attr])
          // }
          console.log(res);
          this.$notify({
            title: "成功",
            message: "添加成功",
            type: "success",
            offset: 38,
          });
          this.resetForm();
          this.getChannelList();
          this.listLoading = false;
        })
        .catch((error) => {
          console.log("--error", error);
          this.$notify({
            title: "错误",
            message: error,
            type: "error",
            offset: 38,
            duration: 0,
          });

          this.listLoading = false;
        });
    },

    //修改渠道对话框
    editChannelForm(row) {
      // this.loading = true;
      // this.userIndex = index;
      // this.hasRoleId = true;
      // const id = this.roleList[this.userIndex].id;
      this.isAdd = false;
      this.title = "渠道修改";
      // this.giveName = "修改渠道";
      // this.giveDescription = "修改描述";
      this.dialogFormVisible = true;
      this.form.id = row.id;
      this.form.channelCode = row.channelCode;
      this.form.channelDescription = row.channelDescription;
      this.form.isQrcode = row.isQrcode;
      // this.getRoleDetail(id);
    },
    //修改
    updateChannel() {
      // if (!this.form.channelCode) {
      //   this.errorCodeMsg = "角色命名不能为空";
      //   return;
      // } else if (!/^[a-z_0-9]+$/gi.test(this.form.channelCode)) {
      //   this.errorCodeMsg = "渠道名称必须字母数字或者_的组合";
      //   return;
      // }

      if (!this.form.channelDescription) {
        this.errorDescMsg = "渠道描述不能为空";
        return;
      }
      let query = {
        data: {
          id: this.form.id,
          channelDescription: this.form.channelDescription,
          isQrcode: this.form.isQrcode,
        },
      };

      console.log(query);

      this.$global
        .httpPutWithToken(this, "/channel/update", query)
        .then((res) => {
          // this.roleList = []
          // for (const attr in res.data) {
          //   this.roleList.push(res.data[attr])
          // }
          console.log(res);
          this.$notify({
            title: "成功",
            message: "修改成功",
            type: "success",
            offset: 38,
          });
          this.resetForm();
          this.getChannelList();
          this.listLoading = false;
        })
        .catch((error) => {
          console.log("--error", error);
          this.$notify({
            title: "错误",
            message: error,
            type: "error",
            offset: 38,
            duration: 0,
          });

          this.listLoading = false;
        });
    },
    //取消操作
    hideDialogFormVisible() {
      this.dialogFormVisible = false;
      this.$notify.info({
        title: "消息",
        message: "取消操作",
        offset: 38,
      });
    },
    //关闭弹窗时，初始化字段。
    closeDialog() {
      if (this.$refs["form"]) {
        //初始化 form roules状态
        this.$refs["form"].resetFields();
      }
      this.loading = false;
      this.disabled = false;
      this.hasRoleId = false;
      this.permission = [];
      this.checkedList = [];
      this.form.name = null;
      this.form.desc = null;
      this.form.roleId = null;

      this.form.checkedList = [];

      this.clearErrorMsg();

      for (const i in this.permissionList) {
        for (const a in this.permissionList[i].list) {
          this.clearIndeterminateUser(this.permissionList[i].list[a]);
        }
      }
    },
    clearIndeterminateUser(moduleItem) {
      moduleItem.indeterminate = false;
      moduleItem.checkStatus = false;
    },
    //清除错误提示
    clearErrorMsg() {
      this.errorCodeMsg = "";
      this.errorDescMsg = "";
      this.errorIdMsg = "";
      this.errorPermisson = "";
    },
  },
};
</script>
<style scoped>
.channel {
  /* min-width: 1116px; */
  height: 100%;
}
.role-btn {
  margin: 10px;
}
.role-red {
  background-color: red;
  color: white;
  padding: 5px;
}
.role-green {
  background-color: green;
  color: white;
  padding: 5px;
}

.role-table {
  /* min-width: 941.76px; */
  width: 83.333%;
  margin-left: 55px;
  margin-top: 29px;
  box-shadow: 0px 0px 10px 0px rgba(99, 99, 99, 0.33);
}
.channel .el-input {
  width: 750px;
}
.channel .el-icon-edit:before {
  font-size: 20px;
  margin-left: 20px;
}
.channel .el-icon-user:before {
  font-size: 20px;
  margin-left: 10px;
}
.channel .el-input__prefix > .el-input__icon {
  position: absolute;
  left: 0;
  top: -5px;
  height: 100%;
  width: 16px;
  font-size: 16px;
  color: rgba(61, 143, 227, 1);
}
.channel .el-input input {
  width: 100%;
}
.rolebtn {
  width: 74px;
  height: 27px;
  font-size: 12px;
  text-align: center;
  background: #00857c;
  border-color: #00857c;
  color: #ffffff;
  padding: 0;
}
.rolebtn:hover {
  background: #00857c !important;
  color: #ffffff !important;
}
.el-button--primary.is-plain {
  color: #ffffff;
  background: #00857c;
  border-color: #00857c;
}
.channel .el-dialog .rolebtn {
  width: 55px;
  height: 24px;
}
</style>
<style type="text/css">
.el-radio__input.is-checked .el-radio__inner {
  border-color: #00857c !important;
  background-color: #00857c !important;
}
.el-radio__input.is-checked + .el-radio__label {
  color: #00857c!important;
}
.qrcode img {
  margin: 0 auto;
}
.sub {
  width: 44px;
  height: 20px!important;
  font-size: 12px!important;
  text-align: center!important;
  background: rgba(240, 242, 245, 1);
  color: #00857c;
  padding: 0!important;
}
</style>
