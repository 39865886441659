<template>
  <div>
    <el-row class="hcp_list">
      <span v-if="showSearch" class="right_icon" @click="shSearch">
        收起
        <i class="el-icon-d-arrow-right" style="display:inline-block;transform:rotate(90deg);"></i>
      </span>
      <span v-if="!showSearch" class="right_icon" @click="shSearch">
        展开
        <i class="el-icon-d-arrow-right" style="display:inline-block;"></i>
      </span>
      <el-col :span="20" :offset="1">
        <el-form
          v-if="showSearch"
          id="top_form_select"
          ref="tokenQueryForm"
          :model="tokenQueryForm"
          style="margin-top:-10px;"
          label-width="75px"
          class="form"
        >
          <!-- 筛选条件 -->
          <el-row>
            <el-col :span="5">
              <div class="grid-content bg-purple">
                <el-form-item label="clientId" prop="description" label-width="65px">
                  <el-input
                    v-model="tokenQueryForm.clientId"
                    class="bottom_input"
                    prop="title"
                  ></el-input>
                </el-form-item>
                <el-form-item label="userId" prop="description" label-width="65px">
                  <el-input
                    v-model="tokenQueryForm.userId"
                    class="bottom_input"
                    prop="title"
                  ></el-input>
                </el-form-item>
                <el-form-item label="clientName" prop="description" label-width="65px">
                  <el-input
                    v-model="tokenQueryForm.clientName"
                    class="bottom_input"
                    prop="title"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content bg-purple">
                <el-form-item label="type" prop="description" label-width="40px">
                  <el-input
                    v-model="tokenQueryForm.type"
                    class="bottom_input"
                    prop="title"
                  ></el-input>
                </el-form-item>
                <el-form-item label="data" prop="description" label-width="40px">
                  <el-input
                    v-model="tokenQueryForm.data"
                    class="bottom_input"
                    prop="title"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple-light">
                <div class="grid-content bg-purple-light">
                  <el-form-item
                    label="token时间"
                    prop="description"
                    label-width="75px"
                    style="margin-bottom:2px;"
                  >
                    <div class="grid-content bg-purple">
                      <div id="block" class="block" style="width:60%">
                        <el-date-picker
                          v-model="tokenQueryForm.signatureTimeFrom"
                          :picker-options="signature_start_date"
                          type="date"
                          maxlength="50"
                          style="width:100%"
                          placeholder="from"
                        ></el-date-picker>
                      </div>
                      <div
                        id="block"
                        class="block"
                        style="margin-left:60%;margin-top:-30px;width:70%;padding-left:22px;"
                      >
                        <el-date-picker
                          v-model="tokenQueryForm.signatureTimeTo"
                          :picker-options="signature_end_date"
                          type="date"
                          maxlength="50"
                          style="width:100%"
                          placeholder="to"
                        ></el-date-picker>
                      </div>
                    </div>
                  </el-form-item>
                  <el-form-item
                    label="创建时间"
                    prop="description"
                    label-width="75px"
                    style="margin-bottom:2px;"
                  >
                    <div class="grid-content bg-purple">
                      <div id="block" class="block" style="width:60%">
                        <el-date-picker
                          v-model="tokenQueryForm.createTimeFrom"
                          :picker-options="create_start_date"
                          type="date"
                          style="width:100%"
                          placeholder="from"
                        ></el-date-picker>
                      </div>
                      <div
                        id="block"
                        class="block"
                        style="margin-left:60%;margin-top:-30px;width:70%;padding-left:22px;"
                      >
                        <el-date-picker
                          v-model="tokenQueryForm.createTimeTo"
                          :picker-options="create_end_date"
                          type="date"
                          style="width:100%"
                          placeholder="to"
                        ></el-date-picker>
                      </div>
                    </div>
                  </el-form-item>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-button type="primary" class="sub hcp_top_form_sub" plain @click="getSearchList()">查找记录</el-button>
          <el-button
            type="primary"
            class="sub hcp_top_form_sub"
            plain
            @click="clearSearchForm"
          >清空筛选条件</el-button>
        </el-form>
        <!-- 客户列表 -->
        <el-form
          id="hcp_list"
          ref="tokenQueryForm"
          :model="tokenQueryForm"
          label-width="70px"
          class="form"
          style="margin-top:10px;"
        >
          <div class="block">
            <el-table
              v-loading="loading"
              ref="table_list"
              :data="hcpList"
              :default-sort="{ prop: 'createTime', order: 'descending' }"
              style="width: 100%;"
              header-row-class-name="tableHead"
              @sort-change="sortChange"
            >
              <el-table-column
                :show-overflow-tooltip="false"
                label="clientName"
                prop="clientName"
                align="center"
                min-width="150"
                max-width="10%"
              >
                <template slot-scope="scope">
                  <span
                    style="display:inline-block;white-space:nowrap;width:100%; text-align:center;"
                  >{{ scope.row.clientName }}</span>
                </template>
              </el-table-column>
              <el-table-column
                :show-overflow-tooltip="false"
                label="clientId"
                prop="clientId"
                align="center"
                min-width="150"
                max-width="10%"
              >
                <template slot-scope="scope">
                  <el-popover trigger="hover" placement="top">
                    <p>{{ scope.row.clientId }}</p>
                    <div slot="reference" class="name-wrapper">
                      <span
                        style="display:inline-block;white-space:nowrap;width:100%; text-align:center;"
                      >{{ scope.row.clientId }}</span>
                    </div>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column
                :show-overflow-tooltip="false"
                label="userId"
                prop="userId"
                align="center"
                min-width="100"
                max-width="8%"
              >
                <template slot-scope="scope">
                  <el-popover trigger="hover" placement="top">
                    <p>{{ scope.row.userId }}</p>
                    <div slot="reference" class="name-wrapper">
                      <span
                        style="display:inline-block;white-space:nowrap;width:100%; text-align:center;"
                      >{{ scope.row.userId }}</span>
                    </div>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column
                :show-overflow-tooltip="false"
                label="type"
                prop="type"
                align="center"
                min-width="100"
                max-width="8%"
              >
                <template slot-scope="scope">
                  <span
                    style="display:inline-block;white-space:nowrap;width:100%; text-align:center;"
                  >{{ scope.row.type }}</span>
                </template>
              </el-table-column>
              <el-table-column
                v-if="tagStatus.data"
                :show-overflow-tooltip="false"
                label="data"
                prop="data"
                align="center"
                min-width="150"
                max-width="15%"
              >
                <template slot-scope="scope">
                  <!-- hover时显示详细信息 -->
                  <el-popover trigger="hover" placement="top">
                    <p>{{ scope.row.data }}</p>
                    <div slot="reference" class="name-wrapper">
                      <span
                        style="display:inline-block;white-space:nowrap;width:100%; text-align:left;"
                      >{{ scope.row.data }}</span>
                    </div>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column
                v-if="tagStatus.signatureTime"
                :show-overflow-tooltip="false"
                label="token生成时间"
                prop="signatureTime"
                align="center"
                min-width="135"
                max-width="15%"
                sortable="custom"
              >
                <template slot-scope="scope">
                  <span
                    style="display:inline-block;white-space:nowrap;width:100%;text-align:center;"
                  >
                    {{
                    scope.row.signatureTime
                    ? $dtime(scope.row.signatureTime).format(
                    'YYYY-MM-DD HH:mm:ss'
                    )
                    : null
                    }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                v-if="tagStatus.createTime"
                :show-overflow-tooltip="false"
                label="创建时间"
                prop="createTime"
                align="center"
                min-width="135"
                max-width="15%"
                sortable="custom"
              >
                <template slot-scope="scope">
                  <span
                    style="display:inline-block;white-space:nowrap;width:100%;text-align:center;"
                  >
                    {{
                    scope.row.createTime
                    ? $dtime(scope.row.createTime).format(
                    'YYYY-MM-DD HH:mm:ss'
                    )
                    : null
                    }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                :show-overflow-tooltip="false"
                label="操作"
                prop="state"
                fixed="right"
                width="30"
              >
                <!-- 筛选列表触发按钮 -->
                <template slot="header" slot-scope="scope">
                  <img
                    class="shaixuan"
                    style="float:right;margin-top:7px;vertical-align: -3px;"
                    src="../../assets/image/shaixuan.png"
                    alt
                    @click="showNext"
                  />
                </template>
              </el-table-column>
            </el-table>
            <!-- 列表下方分页信息 -->
            <el-pagination
              :current-page="currentPage"
              :page-sizes="[20, 50, 100]"
              :page-size="parseInt(page.limit)"
              :total="page.total"
              style="margin:center;margin-top:12px;margin-bottom:12px;height:46px;text-align:center;"
              layout="total,sizes,prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            ></el-pagination>
            <!-- :current-page="page.offset" -->
          </div>
          <!-- 筛选列表容器 -->
          <el-transfer
            v-if="showShaixuan"
            :props="{ key: 'id', label: 'name' }"
            :titles="['筛选']"
            :left-default-checked="checkedShaixuan"
            :data="noData"
            style="width:130px;"
            @left-check-change="handleChange"
          >
            <el-button
              slot="left-footer"
              type="primary"
              class="transfer-footer sub_review sub"
              size="small"
              @click="determineShaixuan"
            >确定</el-button>
            <el-button
              slot="left-footer"
              type="primary"
              class="transfer-footer sub_review sub"
              size="small"
              @click="cancelShaixuan"
            >取消</el-button>
          </el-transfer>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>
<script>

import {getNowDate,listDateMin,listDateMax} from "./commfn";

export default {
  components: {},
  data() {
    return {
      //创建时间选择器关联
      create_start_date: {
        //开始时间范围限定
        disabledDate: (time) => {
          if (this.tokenQueryForm.createTimeTo) {
            return time.getTime() > this.tokenQueryForm.createTimeTo
          }
          return time.getTime() < Date.now() - 8.64e11
        },
      },
      create_end_date: {
        //结束时间范围限定
        disabledDate: (time) => {
          return time.getTime() <= this.tokenQueryForm.createTimeFrom - 1
        },
      },
      //token生成时间选择器关联
      signature_start_date: {
        //开始时间范围限定
        disabledDate: (time) => {
          if (this.tokenQueryForm.signatureTimeTo) {
            return time.getTime() > this.tokenQueryForm.signatureTimeTo
          }
          return time.getTime() < Date.now() - 8.64e11
        },
      },
      signature_end_date: {
        //结束时间范围限定
        disabledDate: (time) => {
          return time.getTime() <= this.tokenQueryForm.signatureTimeFrom - 1
        },
      },
      //filter显示状态
      showSearch: true,
      //初始筛选选中状态
      item: ['data','signatureTime','createTime',],
      //table列显示
      tagStatus: {
        data: true,
        createTime: true,
        signatureTime: true,
      },
      //列表Loading
      loading: false,
      //筛选初始赋值
      checkedShaixuan: ['data','signatureTime','createTime',],
      //筛选列表元素，disabled为不可选中
      noData: [
        { id: 'clientName', name: 'clientName', disabled: true },
        { id: 'clientId', name: 'clientId', disabled: true },
        { id: 'userId', name: 'userId', disabled: true },
        { id: 'type', name: 'type', disabled: true },
        { id: 'data', name: 'data'},
        { id: 'signatureTime', name: 'token生成时间' },
        { id: 'createTime', name: '创建时间' },
      ],
      //筛选弹窗显示隐藏
      showShaixuan: false,
      //客户列表存值数组
      hcpList: [],
      //分页默认设置
      page: {
        limit: 20,
        offset: 0,
        total: 0,
      },
      order: '+',
      sortProp: 'createTime',
      //初始显示页码
      currentPage: 1,
      //文章查询
      oldSearch: {
        clientName: null,
        clientId: null,
        userId: null,
        type: null,
        data: null, //data
        signatureTimeFrom: null,
        signatureTimeTo: null,
        createTimeFrom: null,
        createTimeTo: null,
      },
      tokenQueryForm: {
        clientName: null,
        clientId: null,
        userId: null,
        type: null,
        data: null, //data
        signatureTimeFrom: null,
        signatureTimeTo: null,
        createTimeFrom: null,
        createTimeTo: null,
      },
    }
  },
  computed: {},
  beforeCreate() {},
  created() {
        this.tokenQueryForm.createTimeFrom = getNowDate();
    this.tokenQueryForm.createTimeTo = getNowDate();
	
    const data = {
      offset: this.page.offset,
      limit: this.page.limit,
      order: this.order + this.sortProp,
      createTimeFrom:listDateMin(this.tokenQueryForm.createTimeFrom),
      createTimeTo:listDateMax(this.tokenQueryForm.createTimeTo)
    }
    this.getTokenList(data)
    // 获取存储在本地的筛选列表历史
    this.tagStatus = this.$global.getShaixuan('tokenListshaixuan')
      ? this.$global.getShaixuan('tokenListshaixuan')
      : this.tagStatus
    this.getCheckedPsrStatus()
  },
  mounted() {},
  methods: {
    shSearch() {
      this.showSearch = !this.showSearch
    },
    //读取筛选列表信息，更新筛选选择状态
    getCheckedPsrStatus() {
      this.checkedShaixuan = []
      for (const i in this.tagStatus) {
        if (this.tagStatus[i] == true) {
          this.checkedShaixuan.push(i)
        }
      }
      this.item = this.checkedShaixuan
    },
    //筛选勾选触发函数
    handleChange(value) {
      this.item = value
    },
    //确定筛选
    determineShaixuan() {
      //更新筛选状态
      this.checkedShaixuan = this.item
      this.showShaixuan = false
      for (const attr in this.tagStatus) {
        this.tagStatus[attr] = false
      }
      for (let i = 0; i < this.item.length; i++) {
        this.tagStatus[this.item[i]] = true
      }
      //存储筛选信息到本地
      this.$global.setShaixuan('tokenListshaixuan', this.tagStatus)
    },
    cancelShaixuan() {
      //取消筛选
      this.showShaixuan = false
    },
    //获取客户列表
    getTokenList(data) {
      this.loading = true
      this.$global
        .httpGetWithToken(this, '/log/token', data)
        .then((res) => {
          this.hcpList = res.data
          this.page = res.page
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
        })
    },
    clearSearchForm() {
      this.tokenQueryForm = {
        clientName: null,
        clientId: null,
        userId: null,
        type: null,
        data: null, //data
        signatureTimeFrom: null,
        signatureTimeTo: null,
        createTimeFrom: null,
        createTimeTo: null,
      }
      //清空文章板块
    },
    //点击分页时触发,改变列表内容
    handleSizeChange(val) {
      this.tokenQueryForm = JSON.parse(JSON.stringify(this.oldSearch));
      this.currentPage = 1
      this.page.limit = val
      this.page.offset = 0
      const data = {
        clientName: this.tokenQueryForm.clientName,
        clientId: this.tokenQueryForm.clientId,
        userId: this.tokenQueryForm.userId,
        type: this.tokenQueryForm.type,
        data: this.tokenQueryForm.data,
        signatureTimeFrom: this.tokenQueryForm.signatureTimeFrom,
        signatureTimeTo: this.tokenQueryForm.signatureTimeTo,
        createTimeFrom: this.tokenQueryForm.createTimeFrom,
        createTimeTo: this.tokenQueryForm.createTimeTo,
        offset: this.page.offset,
        limit: this.page.limit,
        order: this.order + this.sortProp,
      }
      if (this.tokenQueryForm.createTimeFrom) {
        data.createTimeFrom = JSON.stringify(
          new Date(
            new Date(this.tokenQueryForm.createTimeFrom).getTime() +
              8 * 60 * 60 * 1000
          )
        )
      }
      if (this.tokenQueryForm.createTimeTo) {
        data.createTimeTo = JSON.stringify(
          new Date(
            new Date(this.tokenQueryForm.createTimeTo).getTime() +
              31 * 60 * 60 * 1000 +
              59 * 60 * 1000 +
              59 * 1000
          )
        )
      }
      if (this.tokenQueryForm.signatureTimeFrom) {
        data.signatureTimeFrom = JSON.stringify(
          new Date(
            new Date(this.tokenQueryForm.signatureTimeFrom).getTime() +
              8 * 60 * 60 * 1000
          )
        )
      }
      if (this.tokenQueryForm.signatureTimeTo) {
        data.signatureTimeTo = JSON.stringify(
          new Date(
            new Date(this.tokenQueryForm.signatureTimeTo).getTime() +
              31 * 60 * 60 * 1000 +
              59 * 60 * 1000 +
              59 * 1000
          )
        )
      }
      for (var i in data) {
        if (data[i] == '' || !data[i]) {
          data[i] = null
        }
      }
      this.getTokenList(data)
    },
    //按页数展示
    handleCurrentChange(val) {
      this.tokenQueryForm = JSON.parse(JSON.stringify(this.oldSearch));
      this.currentPage = val
      this.page.offset = (val - 1) * this.page.limit
      const data = {
        clientName: this.tokenQueryForm.clientName,
        clientId: this.tokenQueryForm.clientId,
        userId: this.tokenQueryForm.userId,
        type: this.tokenQueryForm.type,
        data: this.tokenQueryForm.data,
        signatureTimeFrom: this.tokenQueryForm.signatureTimeFrom,
        signatureTimeTo: this.tokenQueryForm.signatureTimeTo,
        createTimeFrom: this.tokenQueryForm.createTimeFrom,
        createTimeTo: this.tokenQueryForm.createTimeTo,
        offset: this.page.offset,
        limit: this.page.limit,
        order: this.order + this.sortProp,
      }
      if (this.tokenQueryForm.createTimeFrom) {
        data.createTimeFrom = JSON.stringify(
          new Date(
            new Date(this.tokenQueryForm.createTimeFrom).getTime() +
              8 * 60 * 60 * 1000
          )
        )
      }
      if (this.tokenQueryForm.createTimeTo) {
        data.createTimeTo = JSON.stringify(
          new Date(
            new Date(this.tokenQueryForm.createTimeTo).getTime() +
              31 * 60 * 60 * 1000 +
              59 * 60 * 1000 +
              59 * 1000
          )
        )
      }
      if (this.tokenQueryForm.signatureTimeFrom) {
        data.signatureTimeFrom = JSON.stringify(
          new Date(
            new Date(this.tokenQueryForm.signatureTimeFrom).getTime() +
              8 * 60 * 60 * 1000
          )
        )
      }
      if (this.tokenQueryForm.signatureTimeTo) {
        data.signatureTimeTo = JSON.stringify(
          new Date(
            new Date(this.tokenQueryForm.signatureTimeTo).getTime() +
              31 * 60 * 60 * 1000 +
              59 * 60 * 1000 +
              59 * 1000
          )
        )
      }
      for (var i in data) {
        if (data[i] == '' || !data[i]) {
          data[i] = null
        }
      }
      this.getTokenList(data)
    },
    //后台排序
    sortChange(column, prop, order) {
      this.tokenQueryForm = JSON.parse(JSON.stringify(this.oldSearch));
      this.currentPage = 1
      this.page.offset = 0
      this.sortProp = column.prop
      //去除点击搜索后此时sort排序时创建时间排序的状态
      if (this.sortProp != 'createTime') {
        for (const attr in this.$refs.table_list.columns) {
          if (this.$refs.table_list.columns[attr].property == 'createTime') {
            this.$refs.table_list.columns[attr].order = ''
          }
        }
      }

      if (column.order == 'ascending') {
        this.order = '-'
      }
      if (column.order == 'descending' || column.order == null) {
        this.order = '+'
      }
      const data = {
        clientName: this.tokenQueryForm.clientName,
        clientId: this.tokenQueryForm.clientId,
        userId: this.tokenQueryForm.userId,
        type: this.tokenQueryForm.type,
        data: this.tokenQueryForm.data,
        signatureTimeFrom: this.tokenQueryForm.signatureTimeFrom,
        signatureTimeTo: this.tokenQueryForm.signatureTimeTo,
        createTimeFrom: this.tokenQueryForm.createTimeFrom,
        createTimeTo: this.tokenQueryForm.createTimeTo,
        offset: this.page.offset,
        limit: this.page.limit,
        order: this.order + this.sortProp,
      }
      if (this.tokenQueryForm.createTimeFrom) {
        data.createTimeFrom = JSON.stringify(
          new Date(
            new Date(this.tokenQueryForm.createTimeFrom).getTime() +
              8 * 60 * 60 * 1000
          )
        )
      }
      if (this.tokenQueryForm.createTimeTo) {
        data.createTimeTo = JSON.stringify(
          new Date(
            new Date(this.tokenQueryForm.createTimeTo).getTime() +
              31 * 60 * 60 * 1000 +
              59 * 60 * 1000 +
              59 * 1000
          )
        )
      }
      if (this.tokenQueryForm.signatureTimeFrom) {
        data.signatureTimeFrom = JSON.stringify(
          new Date(
            new Date(this.tokenQueryForm.signatureTimeFrom).getTime() +
              8 * 60 * 60 * 1000
          )
        )
      }
      if (this.tokenQueryForm.signatureTimeTo) {
        data.signatureTimeTo = JSON.stringify(
          new Date(
            new Date(this.tokenQueryForm.signatureTimeTo).getTime() +
              31 * 60 * 60 * 1000 +
              59 * 60 * 1000 +
              59 * 1000
          )
        )
      }
      for (var i in data) {
        if (data[i] == '' || !data[i]) {
          data[i] = null
        }
      }
      this.getTokenList(data)
    },
    //筛选文章列表表头
    showNext() {
      this.showShaixuan = true
      this.$nextTick(() => {
        var checked = document.getElementsByClassName('is-disabled')
        for (const i in checked) {
          if (checked[i].localName == 'span') {
            checked[i].classList.add('is-checked')
          }
        }
      })
    },
    //执行搜索
    getSearchList() {
      this.oldSearch = JSON.parse(JSON.stringify(this.tokenQueryForm));
      this.page.offset = 0
      this.$refs.table_list.clearSort()
      //点击搜索后恢复sort排序默认状态样式
      for (const attr in this.$refs.table_list.columns) {
        if (this.$refs.table_list.columns[attr].property == 'createTime') {
          this.$refs.table_list.columns[attr].order = 'descending'
        }
      }
      const data = {
        clientName: this.tokenQueryForm.clientName,
        clientId: this.tokenQueryForm.clientId,
        userId: this.tokenQueryForm.userId,
        type: this.tokenQueryForm.type,
        data: this.tokenQueryForm.data,
        signatureTimeFrom: this.tokenQueryForm.signatureTimeFrom,
        signatureTimeTo: this.tokenQueryForm.signatureTimeTo,
        createTimeFrom: this.tokenQueryForm.createTimeFrom,
        createTimeTo: this.tokenQueryForm.createTimeTo,
        offset: this.page.offset,
        limit: this.page.limit,
        order: this.order + this.sortProp,
      }
      if (this.tokenQueryForm.createTimeFrom) {
        data.createTimeFrom = JSON.stringify(
          new Date(
            new Date(this.tokenQueryForm.createTimeFrom).getTime() +
              8 * 60 * 60 * 1000
          )
        )
      }
      if (this.tokenQueryForm.createTimeTo) {
        data.createTimeTo = JSON.stringify(
          new Date(
            new Date(this.tokenQueryForm.createTimeTo).getTime() +
              31 * 60 * 60 * 1000 +
              59 * 60 * 1000 +
              59 * 1000
          )
        )
      }
      if (this.tokenQueryForm.signatureTimeFrom) {
        data.signatureTimeFrom = JSON.stringify(
          new Date(
            new Date(this.tokenQueryForm.signatureTimeFrom).getTime() +
              8 * 60 * 60 * 1000
          )
        )
      }
      if (this.tokenQueryForm.signatureTimeTo) {
        data.signatureTimeTo = JSON.stringify(
          new Date(
            new Date(this.tokenQueryForm.signatureTimeTo).getTime() +
              31 * 60 * 60 * 1000 +
              59 * 60 * 1000 +
              59 * 1000
          )
        )
      }
      for (var i in data) {
        if (data[i] == '' || !data[i]) {
          data[i] = null
        }
      }
      this.getTokenList(data)
      this.currentPage = 1
    },
  },
}
</script>
<style scoped>
/* TOP form样式 */
.hcp_list {
  margin-top: 8px;
  max-width: 93%;
}
.hcp_list > el-col {
  max-width: 83.3%;
}
.form {
  min-width: 400px;
  margin-bottom: 28px;
}
.form_header {
  text-align: left;
}
#top_form_select .el-form-item {
  margin-bottom: 0px;
}
#top_form_select img {
  width: 22px;
  vertical-align: -4px;
}
#block .el-input__prefix > .el-input__icon {
  position: absolute;
  left: 210px;
  top: 0;
  height: 100%;
  width: 22px;
  font-size: 22px;
}
#top_form_select .el-input input {
  width: 100%;
  height: 24px;
}
#top_form_select .el-button {
  margin-top: 24px;
}
/* 文章详情样式 */
.hcp_list #hcp_list .el-button--primary {
  background-color: #00857c;
  border-color: #00857c;
  color: #ffffff;
}
.hcp_list #hcp_list .el-button--primary:hover {
  background-color: #00857c !important;
  border-color: #00857c !important;
  color: #ffffff !important;
}
#hcp_list {
  box-shadow: 0px 0px 10px 0px rgba(99, 99, 99, 0.33);
}
#hcp_list .el-form-item {
  margin-left: -10px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  height: 100%;
  /* // border-bottom: 1px solid #BFCBD9; */
}
#hcp_list img {
  margin-left: 6px;
  display: inline-block;
  width: 16px;
}
.caozuo {
  font-size: 16px;
  color: #909399;
  font-weight: 400;
  border-bottom: 1px solid #909399;
  margin-right: 30px;
}
.caozuo:hover {
  cursor: pointer;
  color: #00857c;
  border-bottom-color: #00857c;
}
.shaixuan:hover {
  cursor: pointer;
}
.sub {
  width: 44px;
  height: 20px;
  font-size: 12px;
  text-align: center;
  background: rgba(240, 242, 245, 1);
  color: #00857c;
  padding: 0;
}
.sub:hover {
  background: rgba(240, 242, 245, 1);
  color: #00857c;
}
.hcp_list .hcp_top_form_sub {
  width: 104px;
  height: 27px;
  background: #00857c;
  border-color: #00857c;
  color: #ffffff;
}
.hcp_list .hcp_top_form_sub:hover {
  background: #00857c !important;
  color: #ffffff !important;
}
.hcp_list .el-button--primary.is-plain:focus,
.el-button--primary.is-plain:hover {
  background: #00857c !important;
  border-color: #00857c !important;
  color: #fff;
}
.hcp_list .el-button.is-active,
.el-button.is-plain:active {
  color: #fff;
  background: #00857c !important;
}
.hcp_list .el-button.is-plain:focus,
.el-button.is-plain:hover {
  background: #00857c !important;
  color: #fff;
}
.category_select {
  border: 1px solid #eaeefb;
  padding: 10px 30px 10px 10px;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.add_category_row {
  height: 0;
  overflow: hidden;
  transition: all 400ms;
  background: #f9fafc;
}
.showEdit {
  height: 185px;
}
.add_category {
  background: #f9fafc;
  padding: 10px 30px 0px 10px;
  border: 1px solid #eaeefb;
  border-top: none;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #20a0ff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
.cell {
  text-align: center;
}
.hcp_list .el-table__header-wrapper {
  background: #f5f7fa;
}
</style>
