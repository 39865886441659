

const getNowDate = function () {

  var now = new Date()
  var year = now.getFullYear() //得到年份
  var month = now.getMonth() //得到月份
  var date = now.getDate() //得到日期
  month = month + 1
  if (month < 10) month = "0" + month;
  if (date < 10) date = "0" + date;
  var res = year + "-" + month + "-" + date;
  return res;
}


const listDateMin = function (createTimeFrom) {
  return JSON.stringify(
    new Date(
      new Date(createTimeFrom).getTime() +
      8 * 60 * 60 * 1000
    )
  )
}

const listDateMax = function (createTimeTo) {
  return JSON.stringify(
    new Date(
      new Date(createTimeTo).getTime() +
      31 * 60 * 60 * 1000 +
      59 * 60 * 1000 +
      59 * 1000
    )
  )
}

module.exports = { getNowDate, listDateMin, listDateMax };