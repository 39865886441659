<template>
  <div>
    <el-row class="hcp_list">
      <span v-if="showSearch" class="right_icon" @click="shSearch">
        收起
        <i
          class="el-icon-d-arrow-right"
          style="display: inline-block; transform: rotate(90deg)"
        ></i>
      </span>
      <span v-if="!showSearch" class="right_icon" @click="shSearch">
        展开
        <i class="el-icon-d-arrow-right" style="display: inline-block"></i>
      </span>
      <el-col :span="20" :offset="1">
        <el-form
          v-if="showSearch"
          id="top_form_select"
          ref="registerQueryForm"
          :model="registerQueryForm"
          style="margin-top: -10px"
          label-width="75px"
          class="form"
        >
          <!-- 筛选条件 -->
          <el-row>
            <el-col :span="5">
              <div class="grid-content bg-purple">
                <el-form-item
                  label="TicketID"
                  prop="description"
                  label-width="60px"
                >
                  <el-input
                    v-model="registerQueryForm.ticketId"
                    class="bottom_input"
                    prop="title"
                    maxlength="50"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="UnionID"
                  prop="description"
                  label-width="60px"
                >
                  <el-input
                    v-model="registerQueryForm.unionId"
                    class="bottom_input"
                    prop="title"
                    maxlength="50"
                  ></el-input>
                </el-form-item>

                <el-form-item
                  label="OpenID"
                  prop="description"
                  label-width="60px"
                >
                  <el-input
                    v-model="registerQueryForm.openId"
                    class="bottom_input"
                    prop="title"
                    maxlength="50"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="DcrName"
                  prop="description"
                  label-width="60px"
                >
                  <el-input
                    v-model="registerQueryForm.requestName"
                    class="bottom_input"
                    prop="title"
                    maxlength="50"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content bg-purple">
                <el-form-item
                  label="DCR状态"
                  prop="description"
                  label-width="75px"
                >
                  <el-select
                    v-model="registerQueryForm.dcrState"
                    clearable
                    placeholder="请选择"
                  >
                    <el-option label="全部" value="null"></el-option>
                    <el-option
                      label="等待处理"
                      value="Pending Approval"
                    ></el-option>
                    <el-option
                      label="已接受"
                      value="CHANGE_ACCEPTED"
                    ></el-option>
                    <el-option
                      label="已拒绝"
                      value="CHANGE_REJECTED"
                    ></el-option>
                    <el-option label="成功" value="Processed_vod"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="MDM code"
                  prop="description"
                  label-width="75px"
                >
                  <el-input
                    v-model="registerQueryForm.mdmCode"
                    class="bottom_input"
                    prop="title"
                    maxlength="50"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="Ticket状态"
                  prop="description"
                  label-width="75px"
                >
                  <el-select
                    v-model="registerQueryForm.ticketState"
                    clearable
                    placeholder="请选择"
                  >
                    <el-option label="全部" value="null"></el-option>
                    <el-option label="pending" value="in progress"></el-option>
                    <el-option label="complete" value="complete"></el-option>
                    <el-option label="cancel" value="cancel"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="clientName"
                  prop="description"
                  label-width="75px"
                >
                  <el-input
                    v-model="registerQueryForm.clientName"
                    class="bottom_input"
                    prop="title"
                    maxlength="50"
                  ></el-input>
                </el-form-item>  
              </div>          
            </el-col>
            <el-col :span="5">
              <div class="grid-content bg-purple">
                <el-form-item
                  label="性别"
                  prop="description"
                  label-width="42px"
                >
                  <el-select
                    v-model="registerQueryForm.gender"
                    clearable
                    placeholder="请选择"
                  >
                    <el-option label="全部" value="null"></el-option>
                    <el-option label="男" value="男"></el-option>
                    <el-option label="女" value="女"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="部门"
                  prop="description"
                  label-width="42px"
                >
                  <el-input
                    v-model="registerQueryForm.department"
                    class="bottom_input"
                    prop="title"
                    maxlength="50"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="姓名"
                  prop="description"
                  label-width="42px"
                >
                  <el-input
                    v-model="registerQueryForm.name"
                    class="bottom_input"
                    prop="title"
                    maxlength="50"
                  ></el-input>
                </el-form-item>

                <el-form-item
                  label="pr no"
                  prop="pr no"
                  label-width="42px"
                >
                  <el-input
                    v-model="registerQueryForm.prNo"
                    class="bottom_input"
                    prop="title"
                    maxlength="50"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple-light">
                <div class="grid-content bg-purple-light">
                  <el-form-item
                    label="工作地点"
                    prop="description"
                    label-width="66px"
                  >
                    <el-input
                      v-model="registerQueryForm.workplaceName"
                      class="bottom_input"
                      prop="title"
                      maxlength="50"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="更新时间"
                    prop="description"
                    label-width="65px"
                    style="margin-bottom: 2px"
                  >
                    <div class="grid-content bg-purple">
                      <div id="block" class="block" style="width: 60%">
                        <el-date-picker
                          v-model="registerQueryForm.updateTimeFrom"
                          :picker-options="update_start_date"
                          type="date"
                          maxlength="50"
                          style="width: 100%"
                          placeholder="from"
                        ></el-date-picker>
                        <!-- <span style="display: inline-block; margin-left: 40px;margin-top:0px; background: #979797;height: 1px;width: 8px;vertical-align: 4px;"></span> -->
                      </div>
                      <div
                        id="block"
                        class="block"
                        style="
                          margin-left: 60%;
                          margin-top: -30px;
                          width: 70%;
                          padding-left: 22px;
                        "
                      >
                        <el-date-picker
                          v-model="registerQueryForm.updateTimeTo"
                          :picker-options="update_end_date"
                          type="date"
                          maxlength="50"
                          style="width: 100%"
                          placeholder="to"
                        ></el-date-picker>
                      </div>
                    </div>
                  </el-form-item>
                  <el-form-item
                    label="创建时间"
                    prop="description"
                    label-width="65px"
                    style="margin-bottom: 2px"
                  >
                    <div class="grid-content bg-purple">
                      <div id="block" class="block" style="width: 60%">
                        <el-date-picker
                          v-model="registerQueryForm.createTimeFrom"
                          :picker-options="create_start_date"
                          type="date"
                          style="width: 100%"
                          placeholder="from"
                        ></el-date-picker>
                        <!-- <span style="display: inline-block; margin-left: 40px;margin-top:0px; background: #979797;height: 1px;width: 8px;vertical-align: 4px;"></span> -->
                      </div>
                      <div
                        id="block"
                        class="block"
                        style="
                          margin-left: 60%;
                          margin-top: -30px;
                          width: 70%;
                          padding-left: 22px;
                        "
                      >
                        <el-date-picker
                          v-model="registerQueryForm.createTimeTo"
                          :picker-options="create_end_date"
                          type="date"
                          style="width: 100%"
                          placeholder="to"
                        ></el-date-picker>
                      </div>
                    </div>
                  </el-form-item>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-button
            type="primary"
            class="sub hcp_top_form_sub"
            plain
            @click="getSearchList()"
            >查找记录</el-button
          >
          <el-button
            type="primary"
            class="sub hcp_top_form_sub"
            plain
            @click="clearSearchForm"
            >清空筛选条件</el-button
          >
        </el-form>
        <!-- 客户列表 -->
        <el-form
          id="hcp_list"
          ref="registerQueryForm"
          :model="registerQueryForm"
          label-width="70px"
          class="form"
          style="margin-top: 10px"
        >
          <div class="block">
            <el-table
              v-loading="loading"
              ref="table_list"
              :data="hcpList"
              :default-sort="{ prop: 'updateTime', order: 'descending' }"
              style="width: 100%"
              header-row-class-name="tableHead"
              @sort-change="sortChange"
            >
              <el-table-column
                :show-overflow-tooltip="false"
                label="TicketID"
                prop="ticketId"
                align="center"
                min-width="180"
                max-width="15%"
              >
                <template slot-scope="scope">
                  <span
                    style="
                      display: inline-block;
                      white-space: nowrap;
                      width: 100%;
                      text-align: center;
                    "
                    >{{ scope.row.ticketId }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                :show-overflow-tooltip="false"
                label="UnionID"
                prop="unionId"
                align="center"
                min-width="230"
                max-width="15%"
              >
                <template slot-scope="scope">
                  <span
                    style="
                      display: inline-block;
                      white-space: nowrap;
                      width: 100%;
                      text-align: center;
                    "
                    >{{ scope.row.unionId }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                :show-overflow-tooltip="false"
                label="OpenID"
                prop="openId"
                align="center"
                min-width="230"
                max-width="15%"
              >
                <template slot-scope="scope">
                  <span
                    style="
                      display: inline-block;
                      white-space: nowrap;
                      width: 100%;
                      text-align: center;
                    "
                    >{{ scope.row.openId }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                :show-overflow-tooltip="false"
                label="clientName"
                prop="clientName"
                align="center"
                min-width="220"
                max-width="15%"
              >
                <template slot-scope="scope">
                  <span
                    style="
                      display: inline-block;
                      white-space: nowrap;
                      width: 100%;
                      text-align: center;
                    "
                    >{{ scope.row.clientName }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                :show-overflow-tooltip="false"
                label="DcrName"
                prop="requestName"
                align="center"
                min-width="220"
                max-width="15%"
              >
                <template slot-scope="scope">
                  <span
                    style="
                      display: inline-block;
                      white-space: nowrap;
                      width: 100%;
                      text-align: center;
                    "
                    >{{ scope.row.requestName }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                :show-overflow-tooltip="false"
                label="姓名"
                prop="name"
                align="center"
                min-width="100"
                max-width="7%"
              >
                <template slot-scope="scope">
                  <span
                    style="
                      display: inline-block;
                      width: 100%;
                      text-align: center;
                    "
                    >{{ scope.row.name }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                v-if="tagStatus.mdmCode"
                :show-overflow-tooltip="false"
                label="MDM Code"
                prop="mdmCode"
                align="center"
                min-width="90"
                max-width="7%"
              >
                <template slot-scope="scope">
                  <span
                    style="
                      display: inline-block;
                      width: 100%;
                      text-align: center;
                    "
                    >{{ scope.row.mdmCode }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                v-if="tagStatus.gender"
                :show-overflow-tooltip="false"
                label="性别"
                prop="name"
                align="center"
                min-width="50"
                max-width="7%"
              >
                <template slot-scope="scope">
                  <span
                    v-if="scope.row.gender === '男'"
                    style="
                      display: inline-block;
                      width: 100%;
                      text-align: center;
                    "
                    >男</span
                  >
                  <span
                    v-if="scope.row.gender === '女'"
                    style="
                      display: inline-block;
                      width: 100%;
                      text-align: center;
                    "
                    >女</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                v-if="tagStatus.ticketState"
                :show-overflow-tooltip="false"
                label="Ticket状态"
                prop="publishState"
                align="center"
                min-width="90"
                max-width="7%"
              >
                <template slot-scope="scope">
                  <span
                    v-if="scope.row.ticketState == 'in progress'"
                    style="
                      display: inline-block;
                      width: 100%;
                      text-align: center;
                    "
                    >pending</span
                  >
                  <span
                    v-if="scope.row.ticketState == 'cancel'"
                    style="
                      display: inline-block;
                      width: 100%;
                      text-align: center;
                    "
                    >cancel</span
                  >
                  <span
                    v-if="scope.row.ticketState == 'complete'"
                    style="
                      display: inline-block;
                      width: 100%;
                      text-align: center;
                    "
                    >complete</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                v-if="tagStatus.workplaceName"
                :show-overflow-tooltip="false"
                label="工作地点"
                prop="workplaceName"
                align="center"
                min-width="150"
                max-width="10%"
              >
                <template slot-scope="scope">
                  <!-- hover时显示详细信息 -->
                  <el-popover trigger="hover" placement="top">
                    <p>{{ scope.row.workplaceName }}</p>
                    <div slot="reference" class="name-wrapper">
                      <span
                        style="
                          display: inline-block;
                          white-space: nowrap;
                          width: 100%;
                          text-align: center;
                        "
                        >{{ scope.row.workplaceName }}</span
                      >
                    </div>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column
                v-if="tagStatus.department"
                :show-overflow-tooltip="false"
                label="部门"
                prop="department"
                align="center"
                min-width="150"
                max-width="10%"
              >
                <template slot-scope="scope">
                  <span
                    style="
                      display: inline-block;
                      white-space: nowrap;
                      width: 100%;
                      text-align: center;
                    "
                    >{{ scope.row.departmentName }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                v-if="tagStatus.customerTypeName"
                :show-overflow-tooltip="false"
                label="客户类型"
                prop="customerTypeName"
                align="center"
                min-width="100"
                max-width="10%"
              >
                <template slot-scope="scope">
                  <span
                    style="
                      display: inline-block;
                      white-space: nowrap;
                      width: 100%;
                      text-align: center;
                    "
                    >{{ scope.row.customerTypeName }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                v-if="tagStatus.dcrState"
                :show-overflow-tooltip="false"
                label="DCR状态"
                prop="dcrState"
                align="center"
                min-width="150"
                max-width="7%"
              >
                <template slot-scope="scope">
                  <span
                    v-if="scope.row.dcrState == 'Pending Approval'"
                    style="
                      display: inline-block;
                      width: 100%;
                      text-align: center;
                    "
                    >等待处理</span
                  >
                  <span
                    v-if="scope.row.dcrState == 'CHANGE_ACCEPTED'"
                    style="
                      display: inline-block;
                      width: 100%;
                      text-align: center;
                    "
                    >已接受</span
                  >
                  <span
                    v-if="scope.row.dcrState == 'CHANGE_REJECTED'"
                    style="
                      display: inline-block;
                      width: 100%;
                      text-align: center;
                    "
                    >已拒绝</span
                  >
                  <span
                    v-if="scope.row.dcrState == 'Processed_vod'"
                    style="
                      display: inline-block;
                      width: 100%;
                      text-align: center;
                    "
                    >成功</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                v-if="tagStatus.secondMaasTime"
                :show-overflow-tooltip="false"
                label="二次验证"
                prop="secondMaasTime"
                align="center"
                min-width="135"
                max-width="15%"
              >
                <template slot-scope="scope">
                  <span
                    style="
                      display: inline-block;
                      white-space: nowrap;
                      width: 100%;
                      text-align: center;
                    "
                  >
                    {{
                      scope.row.secondMaasTime
                        ? $dtime(scope.row.secondMaasTime).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )
                        : null
                    }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                v-if="tagStatus.maasResult"
                :show-overflow-tooltip="false"
                label="maasResult"
                prop="maasResult"
                align="center"
                min-width="150"
                max-width="10%"
              >
                <template slot-scope="scope">
                  <!-- hover时显示详细信息 -->
                  <el-popover trigger="hover" placement="top">
                    <p>{{ scope.row.maasResult }}</p>
                    <div slot="reference" class="name-wrapper">
                      <span
                        style="
                          display: inline-block;
                          white-space: nowrap;
                          width: 100%;
                          text-align: center;
                        "
                        >{{ scope.row.maasResult }}</span
                      >
                    </div>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column
                v-if="tagStatus.workplaceName"
                :show-overflow-tooltip="false"
                label="hcpAccountResult"
                prop="hcpAccountResult"
                align="center"
                min-width="150"
                max-width="10%"
              >
                <template slot-scope="scope">
                  <!-- hover时显示详细信息 -->
                  <el-popover trigger="hover" placement="top">
                    <p>{{ scope.row.hcpAccountResult }}</p>
                    <div slot="reference" class="name-wrapper">
                      <span
                        style="
                          display: inline-block;
                          white-space: nowrap;
                          width: 100%;
                          text-align: center;
                        "
                        >{{ scope.row.hcpAccountResult }}</span
                      >
                    </div>
                  </el-popover>
                </template>
              </el-table-column>


              <el-table-column
                :show-overflow-tooltip="false"
                label="pr no"
                prop="pr no"
                align="center"
                min-width="150"
                max-width="10%"
              >
                <template slot-scope="scope">
                 {{ scope.row.prNo }}
                </template>
              </el-table-column>

              <el-table-column
                v-if="tagStatus.updateTime"
                :show-overflow-tooltip="false"
                label="更新时间"
                prop="updateTime"
                align="center"
                min-width="135"
                max-width="15%"
                sortable="custom"
              >
                <template slot-scope="scope">
                  <span
                    style="
                      display: inline-block;
                      white-space: nowrap;
                      width: 100%;
                      text-align: center;
                    "
                  >
                    {{
                      scope.row.updateTime
                        ? $dtime(scope.row.updateTime).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )
                        : null
                    }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                v-if="tagStatus.createTime"
                :show-overflow-tooltip="false"
                label="创建时间"
                prop="createTime"
                align="center"
                min-width="135"
                max-width="15%"
                sortable="custom"
              >
                <template slot-scope="scope">
                  <span
                    style="
                      display: inline-block;
                      white-space: nowrap;
                      width: 100%;
                      text-align: center;
                    "
                  >
                    {{
                      scope.row.createTime
                        ? $dtime(scope.row.createTime).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )
                        : null
                    }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                :show-overflow-tooltip="false"
                label="操作"
                prop="state"
                fixed="right"
                width="30"
              >
                <!-- 筛选列表触发按钮 -->
                <template slot="header" slot-scope="scope">
                  <img
                    class="shaixuan"
                    style="float: right; margin-top: 7px; vertical-align: -3px"
                    src="../../assets/image/shaixuan.png"
                    alt
                    @click="showNext"
                  />
                </template>
              </el-table-column>
            </el-table>
            <!-- 列表下方分页信息 -->
            <el-pagination
              :current-page="currentPage"
              :page-sizes="[20, 50, 100]"
              :page-size="parseInt(page.limit)"
              :total="page.total"
              style="
                margin: center;
                margin-top: 12px;
                margin-bottom: 12px;
                height: 46px;
                text-align: center;
              "
              layout="total,sizes,prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            ></el-pagination>
            <!-- :current-page="page.offset" -->
          </div>
          <!-- 筛选列表容器 -->
          <el-transfer
            v-if="showShaixuan"
            :props="{ key: 'id', label: 'name' }"
            :titles="['筛选']"
            :left-default-checked="checkedShaixuan"
            :data="noData"
            style="width: 130px"
            @left-check-change="handleChange"
          >
            <el-button
              slot="left-footer"
              type="primary"
              class="transfer-footer sub_review sub"
              size="small"
              @click="determineShaixuan"
              >确定</el-button
            >
            <el-button
              slot="left-footer"
              type="primary"
              class="transfer-footer sub_review sub"
              size="small"
              @click="cancelShaixuan"
              >取消</el-button
            >
          </el-transfer>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      //创建时间选择器关联
      create_start_date: {
        //开始时间范围限定
        disabledDate: (time) => {
          if (this.registerQueryForm.createTimeTo) {
            return time.getTime() > this.registerQueryForm.createTimeTo;
          }
          return time.getTime() < Date.now() - 8.64e11;
        },
      },
      create_end_date: {
        //结束时间范围限定
        disabledDate: (time) => {
          return time.getTime() <= this.registerQueryForm.createTimeFrom - 1;
        },
      },
      //更新时间选择器关联
      update_start_date: {
        //开始时间范围限定
        disabledDate: (time) => {
          if (this.registerQueryForm.updateTimeTo) {
            return time.getTime() > this.registerQueryForm.updateTimeTo;
          }
          return time.getTime() < Date.now() - 8.64e11;
        },
      },
      update_end_date: {
        //结束时间范围限定
        disabledDate: (time) => {
          return time.getTime() <= this.registerQueryForm.updateTimeFrom - 1;
        },
      },
      //filter显示状态
      showSearch: true,
      //初始筛选选中状态
      item: ["workplaceName", "ticketState"],
      //table列显示
      tagStatus: {
        // name:true,
        // ticketId:true,
        // Unionid:true,
        workplaceName: true,
        // psr:true,
        gender: false,
        ticketState: true,
        mdmCode: false,
        department: false,
        customerTypeName: false,
        createTime: false,
        updateTime: false,
        dcrState: true,
        secondMaasTime: false,
        maasResult: false,
        hcpAccountResult: false,
      },
      //列表Loading
      loading: false,
      //筛选初始赋值
      checkedShaixuan: ["workplaceName", "ticketState"],
      //筛选列表元素，disabled为不可选中
      noData: [
        { id: "ticketId", name: "TicketID", disabled: true },
        { id: "Unionid", name: "UnionID", disabled: true },
        { id: "OpenId", name: "OpenID", disabled: true },
        { id: "requestName", name: "DcrName", disabled: true },
        { id: "clientName", name: "clientName", disabled: true },
        { id: "name", name: "姓名", disabled: true },
        { id: "mdmCode", name: "MDM code" },
        // { id: 'Unionid', name: 'Unionid', disabled: true },
        { id: "gender", name: "性别" },
        { id: "workplaceName", name: "工作地点" },
        { id: "department", name: "部门" },
        { id: "customerTypeName", name: "客户类型" },
        { id: "dcrState", name: "DCR状态" },
        { id: "ticketState", name: "ticket状态" },
        { id: "updateTime", name: "更新时间" },
        { id: "createTime", name: "创建时间" },
        { id: "secondMaasTime", name: "二次验证" },
        { id: "maasResult", name: "maasResult" },
        { id: "hcpAccountResult", name: "hcpAccountResult" },
      ],
      //筛选弹窗显示隐藏
      showShaixuan: false,
      //客户列表存值数组
      hcpList: [],
      //分页默认设置
      page: {
        limit: 20,
        offset: 0,
        total: 0,
      },
      order: "+",
      sortProp: "updateTime",
      //初始显示页码
      currentPage: 1,
      //文章查询
      oldSearch: {
        name: null, //客户名称
        unionId: null,
        openId: null,
        ticketId: null, //MDMId
        requestName: null,
        clientName: null,
        workplaceName: null, //工作地点
        department: null, //部门
        gender: null, //性别
        dcrState: null,
        ticketState: null,
        mdmCode: null,
        updateTimeFrom: null,
        updateTimeTo: null,
        createTimeFrom: null,
        createTimeTo: null,
      },
      registerQueryForm: {
        name: null, //客户名称
        unionId: null,
        openId: null,
        ticketId: null, //MDMId
        requestName: null,
        clientName: null,
        workplaceName: null, //工作地点
        department: null, //部门
        gender: null, //性别
        dcrState: null,
        ticketState: null,
        mdmCode: null,
        updateTimeFrom: null,
        updateTimeTo: null,
        createTimeFrom: null,
        createTimeTo: null,
        prNo: null,
      },
    };
  },
  computed: {},
  beforeCreate() {},
  created() {
    if (this.$route.query.id) {
      //get带参跳转
      this.registerQueryForm.unionId = this.$route.query.id;
      const data = {
        offset: this.page.offset,
        limit: this.page.limit,
        order: this.order + this.sortProp,
        unionId: this.$route.query.id,
      };
      this.getRegisterList(data);
    } else {
      //获取客户列表
      const data = {
        offset: this.page.offset,
        limit: this.page.limit,
        order: this.order + this.sortProp,
      };
      this.getRegisterList(data);
    }
    // 获取存储在本地的筛选列表历史
    this.tagStatus = this.$global.getShaixuan("registershaixuan")
      ? this.$global.getShaixuan("registershaixuan")
      : this.tagStatus;
    this.getCheckedPsrStatus();
    //获取客户列表
  },
  mounted() {},
  methods: {
    shSearch() {
      this.showSearch = !this.showSearch;
    },
    //读取筛选列表信息，更新筛选选择状态
    getCheckedPsrStatus() {
      this.checkedShaixuan = [];
      for (const i in this.tagStatus) {
        if (this.tagStatus[i] == true) {
          this.checkedShaixuan.push(i);
        }
      }
      this.item = this.checkedShaixuan;
    },
    //筛选勾选触发函数
    handleChange(value) {
      this.item = value;
    },
    //确定筛选
    determineShaixuan() {
      //更新筛选状态
      this.checkedShaixuan = this.item;
      this.showShaixuan = false;
      for (const attr in this.tagStatus) {
        this.tagStatus[attr] = false;
      }
      for (let i = 0; i < this.item.length; i++) {
        this.tagStatus[this.item[i]] = true;
      }
      //存储筛选信息到本地
      this.$global.setShaixuan("registershaixuan", this.tagStatus);
    },
    cancelShaixuan() {
      //取消筛选
      this.showShaixuan = false;
    },
    //获取客户列表
    getRegisterList(data) {
      console.log(data);
      this.loading = true;
      this.$global
        .httpGetWithToken(this, "/dcrValidation/list", data)
        .then((res) => {
          this.hcpList = res.data;
          this.page = res.page;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    clearSearchForm() {
      this.registerQueryForm = {
        name: null, //客户名称
        unionId: null,
        openId: null,
        ticketId: null, //MDMId
        requestName: null,
        clientName: null,
        workplaceName: null, //工作地点
        department: null, //部门
        gender: null, //性别
        dcrState: null,
        ticketState: null,
        mdmCode: null,
        updateTimeFrom: null,
        updateTimeTo: null,
        createTimeFrom: null,
        createTimeTo: null,
        prNo: null,
      };
      //清空文章板块
    },
    //点击分页时触发,改变列表内容
    handleSizeChange(val) {
      this.registerQueryForm = JSON.parse(JSON.stringify(this.oldSearch));

      //get带参跳转  选择分页记住 id
      if (this.$route.query.id) {
        this.registerQueryForm.unionId = this.$route.query.id;
      }

      this.currentPage = 1;
      this.page.limit = val;
      this.page.offset = 0;
      const data = {
        name: this.registerQueryForm.name,
        unionId: this.registerQueryForm.unionId,
        openId: this.registerQueryForm.openId,
        ticketId: this.registerQueryForm.ticketId,
        requestName: this.registerQueryForm.requestName,
        clientName: this.registerQueryForm.clientName,
        workplaceName: this.registerQueryForm.workplaceName,
        departmentName: this.registerQueryForm.department,
        gender: this.registerQueryForm.gender,
        dcrState: this.registerQueryForm.dcrState,
        ticketState: this.registerQueryForm.ticketState,
        mdmCode: this.registerQueryForm.mdmCode,
        updateTimeFrom: this.registerQueryForm.updateTimeFrom,
        updateTimeTo: this.registerQueryForm.updateTimeTo,
        createTimeFrom: this.registerQueryForm.createTimeFrom,
        createTimeTo: this.registerQueryForm.createTimeTo,
        offset: this.page.offset,
        limit: this.page.limit,
        order: this.order + this.sortProp,
      };
      if (this.registerQueryForm.createTimeFrom) {
        data.createTimeFrom = JSON.stringify(
          new Date(
            new Date(this.registerQueryForm.createTimeFrom).getTime() +
              8 * 60 * 60 * 1000
          )
        );
      }
      if (this.registerQueryForm.createTimeTo) {
        data.createTimeTo = JSON.stringify(
          new Date(
            new Date(this.registerQueryForm.createTimeTo).getTime() +
              31 * 60 * 60 * 1000 +
              59 * 60 * 1000 +
              59 * 1000
          )
        );
      }
      if (this.registerQueryForm.updateTimeFrom) {
        data.updateTimeFrom = JSON.stringify(
          new Date(
            new Date(this.registerQueryForm.updateTimeFrom).getTime() +
              8 * 60 * 60 * 1000
          )
        );
      }
      if (this.registerQueryForm.updateTimeTo) {
        data.updateTimeTo = JSON.stringify(
          new Date(
            new Date(this.registerQueryForm.updateTimeTo).getTime() +
              31 * 60 * 60 * 1000 +
              59 * 60 * 1000 +
              59 * 1000
          )
        );
      }
      for (var i in data) {
        if (data[i] == "" || !data[i]) {
          data[i] = null;
        }
      }
      this.getRegisterList(data);
    },
    //按页数展示
    handleCurrentChange(val) {
      this.registerQueryForm = JSON.parse(JSON.stringify(this.oldSearch));
      this.currentPage = val;
      this.page.offset = (val - 1) * this.page.limit;
      const data = {
        name: this.registerQueryForm.name,
        unionId: this.registerQueryForm.unionId,
        openId: this.registerQueryForm.openId,
        ticketId: this.registerQueryForm.ticketId,
        requestName: this.registerQueryForm.requestName,
        clientName: this.registerQueryForm.clientName,
        workplaceName: this.registerQueryForm.workplaceName,
        departmentName: this.registerQueryForm.department,
        gender: this.registerQueryForm.gender,
        dcrState: this.registerQueryForm.dcrState,
        ticketState: this.registerQueryForm.ticketState,
        mdmCode: this.registerQueryForm.mdmCode,
        updateTimeFrom: this.registerQueryForm.updateTimeFrom,
        updateTimeTo: this.registerQueryForm.updateTimeTo,
        createTimeFrom: this.registerQueryForm.createTimeFrom,
        createTimeTo: this.registerQueryForm.createTimeTo,
        offset: this.page.offset,
        limit: this.page.limit,
        order: this.order + this.sortProp,
      };
      if (this.registerQueryForm.createTimeFrom) {
        data.createTimeFrom = JSON.stringify(
          new Date(
            new Date(this.registerQueryForm.createTimeFrom).getTime() +
              8 * 60 * 60 * 1000
          )
        );
      }
      if (this.registerQueryForm.createTimeTo) {
        data.createTimeTo = JSON.stringify(
          new Date(
            new Date(this.registerQueryForm.createTimeTo).getTime() +
              31 * 60 * 60 * 1000 +
              59 * 60 * 1000 +
              59 * 1000
          )
        );
      }
      if (this.registerQueryForm.updateTimeFrom) {
        data.updateTimeFrom = JSON.stringify(
          new Date(
            new Date(this.registerQueryForm.updateTimeFrom).getTime() +
              8 * 60 * 60 * 1000
          )
        );
      }
      if (this.registerQueryForm.updateTimeTo) {
        data.updateTimeTo = JSON.stringify(
          new Date(
            new Date(this.registerQueryForm.updateTimeTo).getTime() +
              31 * 60 * 60 * 1000 +
              59 * 60 * 1000 +
              59 * 1000
          )
        );
      }
      for (var i in data) {
        if (data[i] == "" || !data[i]) {
          data[i] = null;
        }
      }
      this.getRegisterList(data);
    },
    //后台排序
    sortChange(column, prop, order) {
      this.registerQueryForm = JSON.parse(JSON.stringify(this.oldSearch));
      this.currentPage = 1;
      this.page.offset = 0;
      this.sortProp = column.prop;
      //去除点击搜索后此时sort排序时创建时间排序的状态
      if (this.sortProp != "updateTime") {
        for (const attr in this.$refs.table_list.columns) {
          if (this.$refs.table_list.columns[attr].property == "updateTime") {
            this.$refs.table_list.columns[attr].order = "";
          }
        }
      }

      if (column.order == "ascending") {
        this.order = "-";
      }
      if (column.order == "descending" || column.order == null) {
        this.order = "+";
      }
      const data = {
        name: this.registerQueryForm.name,
        unionId: this.registerQueryForm.unionId,
        openId: this.registerQueryForm.openId,
        ticketId: this.registerQueryForm.ticketId,
        requestName: this.registerQueryForm.requestName,
        clientName: this.registerQueryForm.clientName,
        workplaceName: this.registerQueryForm.workplaceName,
        departmentName: this.registerQueryForm.department,
        gender: this.registerQueryForm.gender,
        dcrState: this.registerQueryForm.dcrState,
        ticketState: this.registerQueryForm.ticketState,
        mdmCode: this.registerQueryForm.mdmCode,
        updateTimeFrom: this.registerQueryForm.updateTimeFrom,
        updateTimeTo: this.registerQueryForm.updateTimeTo,
        createTimeFrom: this.registerQueryForm.createTimeFrom,
        createTimeTo: this.registerQueryForm.createTimeTo,
        offset: this.page.offset,
        limit: this.page.limit,
        order: this.order + this.sortProp,
      };
      if (this.registerQueryForm.createTimeFrom) {
        data.createTimeFrom = JSON.stringify(
          new Date(
            new Date(this.registerQueryForm.createTimeFrom).getTime() +
              8 * 60 * 60 * 1000
          )
        );
      }
      if (this.registerQueryForm.createTimeTo) {
        data.createTimeTo = JSON.stringify(
          new Date(
            new Date(this.registerQueryForm.createTimeTo).getTime() +
              31 * 60 * 60 * 1000 +
              59 * 60 * 1000 +
              59 * 1000
          )
        );
      }
      if (this.registerQueryForm.updateTimeFrom) {
        data.updateTimeFrom = JSON.stringify(
          new Date(
            new Date(this.registerQueryForm.updateTimeFrom).getTime() +
              8 * 60 * 60 * 1000
          )
        );
      }
      if (this.registerQueryForm.updateTimeTo) {
        data.updateTimeTo = JSON.stringify(
          new Date(
            new Date(this.registerQueryForm.updateTimeTo).getTime() +
              31 * 60 * 60 * 1000 +
              59 * 60 * 1000 +
              59 * 1000
          )
        );
      }
      for (var i in data) {
        if (data[i] == "" || !data[i]) {
          data[i] = null;
        }
      }
      this.getRegisterList(data);
    },
    //筛选文章列表表头
    showNext() {
      this.showShaixuan = true;
      this.$nextTick(() => {
        var checked = document.getElementsByClassName("is-disabled");
        for (const i in checked) {
          if (checked[i].localName == "span") {
            checked[i].classList.add("is-checked");
          }
        }
      });
    },
    //执行搜索
    getSearchList() {
      this.oldSearch = JSON.parse(JSON.stringify(this.registerQueryForm));
      this.page.offset = 0;
      this.$refs.table_list.clearSort();
      //点击搜索后恢复sort排序默认状态样式
      for (const attr in this.$refs.table_list.columns) {
        if (this.$refs.table_list.columns[attr].property == "updateTime") {
          this.$refs.table_list.columns[attr].order = "descending";
        }
      }
      const data = {
        name: this.registerQueryForm.name,
        unionId: this.registerQueryForm.unionId,
        openId: this.registerQueryForm.openId,
        ticketId: this.registerQueryForm.ticketId,
        requestName: this.registerQueryForm.requestName,
        clientName: this.registerQueryForm.clientName,
        workplaceName: this.registerQueryForm.workplaceName,
        departmentName: this.registerQueryForm.department,
        gender: this.registerQueryForm.gender,
        dcrState: this.registerQueryForm.dcrState,
        ticketState: this.registerQueryForm.ticketState,
        mdmCode: this.registerQueryForm.mdmCode,
        updateTimeFrom: this.registerQueryForm.updateTimeFrom,
        updateTimeTo: this.registerQueryForm.updateTimeTo,
        createTimeFrom: this.registerQueryForm.createTimeFrom,
        createTimeTo: this.registerQueryForm.createTimeTo,
        prNo: this.registerQueryForm.prNo,
        offset: this.page.offset,
        limit: this.page.limit,
        order: this.order + this.sortProp,
      };
      if (this.registerQueryForm.createTimeFrom) {
        data.createTimeFrom = JSON.stringify(
          new Date(
            new Date(this.registerQueryForm.createTimeFrom).getTime() +
              8 * 60 * 60 * 1000
          )
        );
      }
      if (this.registerQueryForm.createTimeTo) {
        data.createTimeTo = JSON.stringify(
          new Date(
            new Date(this.registerQueryForm.createTimeTo).getTime() +
              31 * 60 * 60 * 1000 +
              59 * 60 * 1000 +
              59 * 1000
          )
        );
      }
      if (this.registerQueryForm.updateTimeFrom) {
        data.updateTimeFrom = JSON.stringify(
          new Date(
            new Date(this.registerQueryForm.updateTimeFrom).getTime() +
              8 * 60 * 60 * 1000
          )
        );
      }
      if (this.registerQueryForm.updateTimeTo) {
        data.updateTimeTo = JSON.stringify(
          new Date(
            new Date(this.registerQueryForm.updateTimeTo).getTime() +
              31 * 60 * 60 * 1000 +
              59 * 60 * 1000 +
              59 * 1000
          )
        );
      }
      for (var i in data) {
        if (data[i] == "" || !data[i]) {
          data[i] = null;
        }
      }
      this.getRegisterList(data);
      this.currentPage = 1;
    },
  },
};
</script>
<style scoped>
/* TOP form样式 */
.hcp_list {
  margin-top: 8px;
  max-width: 93%;
}
.hcp_list > el-col {
  max-width: 83.3%;
}
.form {
  min-width: 400px;
  margin-bottom: 28px;
}
.form_header {
  text-align: left;
}
#top_form_select .el-form-item {
  margin-bottom: 0px;
}
#top_form_select img {
  width: 22px;
  vertical-align: -4px;
}
#block .el-input__prefix > .el-input__icon {
  position: absolute;
  left: 210px;
  top: 0;
  height: 100%;
  width: 22px;
  font-size: 22px;
}
#top_form_select .el-input input {
  width: 100%;
  height: 24px;
}
#top_form_select .el-button {
  margin-top: 24px;
}
/* 文章详情样式 */
.hcp_list #hcp_list .el-button--primary {
  background-color: #00857c;
  border-color: #00857c;
  color: #ffffff;
}
.hcp_list #hcp_list .el-button--primary:hover {
  background-color: #00857c !important;
  border-color: #00857c !important;
  color: #ffffff !important;
}
#hcp_list {
  box-shadow: 0px 0px 10px 0px rgba(99, 99, 99, 0.33);
}
#hcp_list .el-form-item {
  margin-left: -10px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  height: 100%;
  /* // border-bottom: 1px solid #BFCBD9; */
}
#hcp_list img {
  margin-left: 6px;
  display: inline-block;
  width: 16px;
}
.caozuo {
  font-size: 16px;
  color: #909399;
  font-weight: 400;
  border-bottom: 1px solid #909399;
  margin-right: 30px;
}
.caozuo:hover {
  cursor: pointer;
  color: #00857c;
  border-bottom-color: #00857c;
}
.shaixuan:hover {
  cursor: pointer;
}
.sub {
  width: 44px;
  height: 20px;
  font-size: 12px;
  text-align: center;
  background: rgba(240, 242, 245, 1);
  color: #00857c;
  padding: 0;
}
.sub:hover {
  background: rgba(240, 242, 245, 1);
  color: #00857c;
}
.hcp_list .hcp_top_form_sub {
  width: 104px;
  height: 27px;
  background: #00857c;
  border-color: #00857c;
  color: #ffffff;
}
.hcp_list .hcp_top_form_sub:hover {
  background: #00857c !important;
  color: #ffffff !important;
}
.hcp_list .el-button--primary.is-plain:focus,
.el-button--primary.is-plain:hover {
  background: #00857c !important;
  border-color: #00857c !important;
  color: #fff;
}
.hcp_list .el-button.is-active,
.el-button.is-plain:active {
  color: #fff;
  background: #00857c !important;
}
.hcp_list .el-button.is-plain:focus,
.el-button.is-plain:hover {
  background: #00857c !important;
  color: #fff;
}
.category_select {
  border: 1px solid #eaeefb;
  padding: 10px 30px 10px 10px;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.add_category_row {
  height: 0;
  overflow: hidden;
  transition: all 400ms;
  background: #f9fafc;
}
.showEdit {
  height: 185px;
}
.add_category {
  background: #f9fafc;
  padding: 10px 30px 0px 10px;
  border: 1px solid #eaeefb;
  border-top: none;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #20a0ff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
.cell {
  text-align: center;
}
.hcp_list .el-table__header-wrapper {
  background: #f5f7fa;
}
</style>
