// export default {
//   // NODE_ENV: 'local',
//   VUE_APP_URL: 'https://vapitst.msdp.cn',
//   VUE_SIGN_URL: 'vapitst.msdp.cn',
//   VUE_CLIENT_ID: '8GQtw4C6iDchJWNmn0SJeA',
//   VUE_GRANT_TYPE: 'implicit',
//   VUE_REQUEST_MODE: 'sso',
//   // VUE_SKIP_REGISTRATION: '1',//默认不传
//   VUE_REDIRECT_URL: 'https://vadmintst.msdp.cn/login',
//   VUE_RESPONSE_TYPEL: 'token',
//   VUE_STATE: 'state'
// }

export default {  
  // NODE_ENV: 'development',
  // VUE_APP_URL: 'https://vapidev.msdp.cn',
  // VUE_SIGN_URL: 'vapidev.msdp.cn',
  VUE_APP_URL: 'https://vapidev.msdp.cn',
  VUE_SIGN_URL: 'vapidev.msdp.cn',
  VUE_CLIENT_ID: 'y4wpsAJQFwIDwp28VPbg',
  VUE_GRANT_TYPE: 'implicit',
  VUE_REQUEST_MODE: 'sso',
  // VUE_SKIP_REGISTRATION: '1',//默认不传
  // VUE_REDIRECT_URL: 'https://vadmindev.msdp.cn/login',
  VUE_REDIRECT_URL: 'https://vadmindev.msdp.cn/login',
  VUE_RESPONSE_TYPEL: 'token',
  VUE_STATE: 'state'
}
