<template>
  <div class="not_found">
    404 File Not Found
  </div>
</template>

<script>
// import axios from 'axios'
export default {
  data() {
    return {}
  },
  created() {},
  methods: {},
}
</script>

<style scoped>
.not_found {
  font-size: 20px;
  font-weight: 400;
}
</style>
