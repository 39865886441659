import axios from 'axios'
import xss from 'xss'
import store from '../vuex/store'
import ErrorCode from './errorCode'

import dev from '../config/dev.env'
import local from '../config/local.env'
import prod from '../config/prod.env'
import test from '../config/test.env'

var bcrypt = require('bcryptjs')
// 防止同一时间多次请求同一接口
axios.defaults.timeout = 30000
const pending = [] // 声明一个数组用于存储每个ajax请求的取消函数和ajax标识
const CancelToken = axios.CancelToken
const removePending = (config) => {
  for (const p in pending) {
    if (pending[p].u === config.url + '&' + config.method) {
      // 当当前请求在数组中存在时执行函数体
      pending[p].f() // 执行取消操作
      pending.splice(p, 1) // 把这条记录从数组中移除
    }
  }
}
// 在main.js设置全局的请求次数，请求的间隙
axios.defaults.retry = 0
axios.defaults.retryDelay = 1000
axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
  var config = err.config
  // console.log(err)
  // If config does not exist or the retry option is not set, reject
  if (!config || !config.retry) return Promise.reject(err)

  // Set the variable for keeping track of the retry count
  config.__retryCount = config.__retryCount || 0

  // Check if we've maxed out the total number of retries
  if (config.__retryCount >= config.retry) {
    // Reject with the error
    return Promise.reject(err)
  }

  // Increase the retry count
  config.__retryCount += 1

  // Create new promise to handle exponential backoff
  var backoff = new Promise(function(resolve) {
    setTimeout(function() {
      resolve()
    }, config.retryDelay || 1)
  })

  // Return the promise in which recalls axios to retry the request
  return backoff.then(function() {
    return axios(config)
  })
})
// 添加请求拦截器
axios.interceptors.request.use(
  (config) => {
    removePending(config) // 在一个ajax发送前执行一下取消操作
    config.cancelToken = new CancelToken((c) => {
      // 这里的ajax标识我是用请求地址&请求方式拼接的字符串，当然你可以选择其他的一些方式
      pending.push({ u: config.url + '&' + config.method, f: c })
      store.commit('pushToken', { cancelToken: c })
    })
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
axios.interceptors.response.use((response)=> {
  if(response.data.data){
    response.data.data = JSON.parse(xss(JSON.stringify(response.data.data)))
  }
  return response;
},
)



console.log('环境', process.env.NODE_ENV || 'production')
let conf
switch (process.env.NODE_ENV) {
  case 'development':
    conf = local
    break
  case 'production':
    conf = prod
    break
  case 'test':
    conf = test
    break
  default:
    conf = prod
    break
}

console.log('conf', conf)

export default {
  baseUrl: conf.VUE_APP_URL,
  signUrl: conf.VUE_SIGN_URL,
  clientId: conf.VUE_CLIENT_ID,
  grantType: conf.VUE_GRANT_TYPE,
  requestMode: conf.VUE_REQUEST_MODE,
  // skipRegistration: conf.VUE_SKIP_REGISTRATION,//默认不传
  redirectUrl: conf.VUE_REDIRECT_URL,
  responseType: conf.VUE_RESPONSE_TYPEL,
  state: conf.VUE_STATE,
  userPermission: [],
  goAuthSSO() {
    const data = {
      client_id: this.clientId,
      grant_type: this.grantType,
      request_mode: this.requestMode,
      // skip_registration: this.skipRegistration,//默认不传
      redirect_uri: encodeURIComponent(this.redirectUrl),
      response_type: this.responseType,
      state: this.state,
    }
    window.location.href =
      this.baseUrl + '/auth/authorize?' + this.getHttpData(data)
  },
  getMe(self) {
    const selfState = self.state
    console.log(selfState)
    this.httpGetWithToken(self, '/admin/me')
      .then((res) => {
        // 存储权限
        console.log(res)
        self.$cookie.set('name', res.data.id)
        this.setPermisson(res.data.permission)
        self.$router.push({ path: '/management' })
      })
      .catch((err) => {
        self.testLoginStatus = true
        self.stateName = null
        self.start = true
      })
  },
  comparePermisson(groupname, value) {
    const permissonList = this.getPermisson()
    const groupNameArray = []
    const valueArray = []
    for (const attr in permissonList) {
      if (groupNameArray.indexOf(permissonList[attr].groupname) == -1) {
        groupNameArray.push(permissonList[attr].groupname)
      }
      if (valueArray.indexOf(permissonList[attr].value) == -1) {
        valueArray.push(permissonList[attr].value)
      }
    }

    if (value) {
      return (
        groupNameArray.indexOf(groupname) != -1 &&
        valueArray.indexOf(value) != -1
      )
    } else if (groupname) {
      return groupNameArray.indexOf(groupname) != -1
    } else {
      return false
    }
  },
  createOptionsSign(option, url, data) {
    if (data) {
      data.sign = null
      const signStr =
        option +
        ',' +
        this.signUrl +
        url +
        ',' +
        this.paramsSort(this.postHttpData(data.data))
      const salt = bcrypt.genSaltSync(10)
      const hash = bcrypt.hashSync(signStr, salt)
      return hash
    } else {
      const signStr = option + ',' + this.signUrl + url
      const salt = bcrypt.genSaltSync(10)
      const hash = bcrypt.hashSync(signStr, salt)
      return hash
    }
  },
  createGetSign(option, url, data) {
    if (data) {
      data.sign = null
      data.timestamp = null
      if (JSON.stringify(this.postHttpData(data)) === '{}') {
        const signStr = option + ',' + this.signUrl + url
        console.log(signStr)
        const salt = bcrypt.genSaltSync(10)
        const hash = bcrypt.hashSync(signStr, salt)
        return hash
      } else {
        const signStr =
          option +
          ',' +
          this.signUrl +
          url +
          ',' +
          this.paramsSort(this.postHttpData(data))
        const salt = bcrypt.genSaltSync(10)
        const hash = bcrypt.hashSync(signStr, salt)
        return hash
      }
    } else {
      const signStr = option + ',' + this.signUrl + url
      console.log('str', signStr)
      const salt = bcrypt.genSaltSync(10)
      const hash = bcrypt.hashSync(signStr, salt)
      return hash
    }
  },
  paramsSort(obj) {
    const sdic = Object.keys(obj).sort()
    let temp = ''

    for (const index in sdic) {
      if (sdic[index] !== 'timestamp') {
        if (obj[sdic[index]] !== '') {
          if (typeof obj[sdic[index]] !== 'object') {
            temp += `${sdic[index]}=${obj[sdic[index]]}&`
          } else {
            temp += `${sdic[index]}=${JSON.stringify(obj[sdic[index]])}&`
          }
        }
      }
    }

    return temp.substring(0, temp.length - 1)
  },
  success(obj, msg, url) {
    obj.$notify({
      message: msg,
      type: 'success',
      offset: 38,
      duration: '2000',
      onClose: function() {
        if (url !== '' && url != null) {
          obj.$router.push(url)
        }
      },
    })
  },
  error(obj, msg, url) {
    obj.$notify.error({
      message: msg,
      title: '错误',
      type: 'error',
      offset: 38,
      duration: 0,
      onClose: function() {
        if (url !== '' && url != null) {
          obj.$router.push(url)
        }
      },
    })
  },
  httpGetWithToken(self, url, data) {
    const _this = this
    const sign = this.createGetSign('get', url, data)
    if (data) {
      data['sign'] = sign
      data['timestamp'] = Date.now()
    } else {
      data = {
        sign: sign,
        timestamp: Date.now(),
      }
    }
    if (data.order) {
      data.order = encodeURIComponent(data.order)
    }
    encodeURIComponent(this.order)
    return new Promise((resolve, reject) => {
      axios
        .get(this.baseUrl + url + '?' + this.getHttpData(data), {
          headers: {
            Authorization: 'Bearer ' + _this.getToken(self),
          },
        })
        .then((res) => {
          if (res.data.status == 1) {
            resolve(res.data)
          } else {
            let error = {}
            error.response = res
            console.log(error)
            this.errorMessage(error, self)
            reject(res.data.message)
          }
        })
        .catch((error) => {
          reject(error)
          console.log(error)
          if (error.toString().indexOf('Network Error') !== -1) {
            return this.error(self, '当前网络状态不稳定，请重试', '')
          }
          this.errorMessage(error, self)
        })
    })
  },
  httpDeleteWithToken(self, url, comment, data) {
    const _this = this
    const sign = this.createGetSign('delete', url, data)
    if (data) {
      data['sign'] = sign
      data['timestamp'] = Date.now()
    } else {
      data = {
        sign: sign,
        timestamp: Date.now(),
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .delete(this.baseUrl + url + '?' + this.getHttpData(data), {
          data: {
            data: comment?JSON.parse(xss(JSON.stringify(comment))):null,
          },
          headers: {
            Authorization: 'Bearer ' + _this.getToken(self),
          },
        })
        .then((res) => {
          console.log(res)
          if (res.status == 204) {
            console.log('resolve', res)
            resolve(res)
          } else if (res.data.code) {
            let error = {}
            error.response = res
            this.errorMessage(error, self)
            reject(res)
          }
        })
        .catch((error) => {
          if (error.toString().indexOf('Network Error') !== -1) {
            return this.error(self, '当前网络状态不稳定，请重试', '')
          }
          this.errorMessage(error, self)
        })
    })
  },
  httpPostWithToken(self, url, data) {
    const _this = this
    console.log(data)
    const sign = this.createOptionsSign('post', url, data)
    if (data) {
      data['sign'] = sign
      data['timestamp'] = Date.now()
    } else {
      data = {
        sign: sign,
        timestamp: Date.now(),
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .post(this.baseUrl + url, this.postHttpData(data), {
          headers: {
            Authorization: 'Bearer ' + _this.getToken(self),
          },
        })
        .then((res) => {
          if (res.data.status == 1) {
            resolve(res.data)
          } else {
            let error = {}
            error.response = res
            this.errorMessage(error, self)
            reject(res.data.message)
          }
        })
        .catch((error) => {
          reject(error)
          if (error.toString().indexOf('Network Error') !== -1) {
            return this.error(self, '当前网络状态不稳定，请重试', '')
          }
          this.errorMessage(error, self)
        })
    })
  },
  httpPutWithToken(self, url, data) {
    const _this = this
    const sign = this.createOptionsSign('put', url, data)
    if (data) {
      data['sign'] = sign
      data['timestamp'] = Date.now()
    } else {
      data = {
        sign: sign,
        timestamp: Date.now(),
      }
    }
    console.log(data)
    return new Promise((resolve, reject) => {
      axios
        .put(this.baseUrl + url, this.postHttpData(data), {
          headers: {
            Authorization: 'Bearer ' + _this.getToken(self),
          },
        })
        .then((res) => {
          if (res && res.data.status == 1) {
            resolve(res.data)
          } else {
            let error = {}
            error.response = res
            this.errorMessage(error, self)
            reject(res.data.message)
          }
        })
        .catch((error) => {
          if (error.toString().indexOf('Network Error') !== -1) {
            return this.error(self, '当前网络状态不稳定，请重试', '')
          }
          this.errorMessage(error, self)
          reject(error)
        })
    })
  },
  httpGet(self, url, data) {
    const sign = this.createGetSign('get', url, data)
    if (data) {
      data['sign'] = sign
    } else {
      data = {
        sign: sign,
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .get(this.baseUrl + url + '?' + this.getHttpData(data))
        .then((res) => {
          if (res.status == 200) {
            resolve(res.data)
          } else if (res.status == 0) {
            console.log('status:0')
          } else {
            let error = {}
            error.response = res
            this.errorMessage(error, self)
            reject(res.data.message)
          }
        })
        .catch((error) => {
          reject(error)
          if (error.toString().indexOf('Network Error') !== -1) {
            return this.error(self, '当前网络状态不稳定，请重试', '')
          }
          this.errorMessage(error, self)
        })
    })
  },
  postHttpData: function(data) {
    let paramsData = JSON.parse(xss(JSON.stringify(data)))
    var temp = {}
    for (var i in paramsData) {
      if (paramsData[i] !== null && paramsData[i] !== 'null') {
        temp[i] = paramsData[i]
      }
    }
    return temp
  },
  postHttpDataWithToken: function(data) {
    var temp = this.postHttpData(data)
    temp.append('token', this.getToken())
    return temp
  },
  getHttpData: function(data) {
    let paramsData = JSON.parse(xss(JSON.stringify(data)))
    var temp = ''
    for (var i in paramsData) {
      if (paramsData[i] != null && paramsData[i] != 'null') {
        if(typeof paramsData[i] == 'string'){
          if(paramsData[i].indexOf('&')!=-1){
            paramsData[i] = encodeURIComponent(paramsData[i])
          }
        }
        
        temp = temp + '&' + i + '=' + paramsData[i]
      }
    }
    temp = temp.substr(1, temp.length - 1)
    return temp
  },
  getPermisson: function() {
    return JSON.parse(localStorage.getItem('userPermission'))
  },
  setPermisson: function(permission) {
    localStorage.setItem('userPermission', JSON.stringify(permission))
  },
  clearPermisson: function() {
    localStorage.removeItem('userPermission')
  },
  getShaixuan: function(name) {
    return JSON.parse(localStorage.getItem(name))
  },
  setShaixuan: function(name, status) {
    localStorage.setItem(name, JSON.stringify(status))
  },
  clearShaixuan: function(name) {
    localStorage.removeItem(name)
  },
  toStringArray(data, dataList) {
    const dataArray = []
    const nameList = []
    for (const attr in data) {
      const id = parseInt(data[attr])
      if (!isNaN(id)) {
        dataArray.push(id)
      }
    }
    for (const attr in dataArray) {
      for (const att in dataList) {
        if (dataArray[attr] == dataList[att].id) {
          nameList.push(dataList[att].name)
        }
      }
    }
    return nameList
  },
  toNumArray(data) {
    const dataArray = []
    for (const attr in data) {
      const id = parseInt(data[attr])
      if (!isNaN(id)) {
        dataArray.push(id)
      }
    }
    return dataArray
  },
  setToken(name, value) {
    this.token = value
    // self.$cookie.set('token', token, 1 * 30 * 60)
    var exp = new Date()
    exp.setTime(exp.getTime() + 1 * 30 * 60 * 1000)
    window.document.cookie =
      name + '=' + escape(value) + ';expires=' + exp.toGMTString()
  },
  getToken(self) {
    return self.$cookie.get('token')
  },
  getIsid(self) {
    return self.$cookie.get('isid')
  },
  // 时间转换
  toDate(date) {
    if (date == null) {
      return null
    } else {
      var date2 = new Date(date)
      var toTwo = function(time) {
        if (time < 10) {
          time = '0' + time
          return time
        }
        return time
      }
      return (
        date2.getFullYear() +
        '-' +
        toTwo(date2.getMonth() + 1) +
        '-' +
        toTwo(date2.getDate()) +
        ' ' +
        toTwo(date2.getHours()) +
        ':' +
        toTwo(date2.getMinutes()) +
        ':' +
        toTwo(date2.getSeconds())
      )
    }
  },
  //errorMessage
  errorMessage(error, self) {
    for (let i in ErrorCode) {
      if (error.response) {
        if (error.response.status == i) {
          //匹配status
          if (i == 500) {
            this.error(self, '系统错误', '')
          }
          if (i == 403) {
            self.$router.push({ path: '/noEmployess' })
          }
          if (i == 404) {
            self.$router.push({ path: '/error' })
          }
          for (let attr2 in ErrorCode[i]) {
            if (error.response.data.code == attr2) {
              //匹配code码
              this.error(self, ErrorCode[i][attr2], '')
              if (i == 401) {
                self.$cookie.delete('name')
                self.$cookie.delete('isid')
                self.$cookie.delete('token')
                self.$global.clearPermisson()
                self.$router.push({ path: '/login' })
              }
            }
          }
        }
      }
    }
  },
}
