<template>
  <div>
    <el-row class="hcp_list">
      <span v-if="showSearch" class="right_icon" @click="shSearch">
        收起
        <i class="el-icon-d-arrow-right" style="display:inline-block;transform:rotate(90deg);"></i>
      </span>
      <span v-if="!showSearch" class="right_icon" @click="shSearch">
        展开
        <i class="el-icon-d-arrow-right" style="display:inline-block;"></i>
      </span>
      <el-col :span="20" :offset="1">
        <el-form
          v-if="showSearch"
          id="top_form_select"
          ref="HcpQueryForm"
          :model="HcpQueryForm"
          style="margin-top:-10px;"
          label-width="75px"
          class="form"
        >
          <!-- 筛选条件 -->
          <el-row>
            <el-col :span="5">
              <div class="grid-content bg-purple">
                <el-form-item
                  label="UnionID"
                  prop="description"
                  label-width="60px"
                  style="text-align:left;"
                >
                  <el-input
                    v-model="HcpQueryForm.unionId"
                    class="bottom_input"
                    prop="title"
                    maxlength="50"
                  ></el-input>
                </el-form-item>
                <el-form-item label="工作地点" prop="description" label-width="60px">
                  <el-input
                    v-model="HcpQueryForm.workplaceName"
                    class="bottom_input"
                    prop="title"
                    maxlength="50"
                  ></el-input>
                </el-form-item>
                <el-form-item label="绑定状态" prop="description" label-width="60px">
                  <el-select v-model="HcpQueryForm.bindState" clearable placeholder="请选择">
                    <el-option label="全部" value="null"></el-option>
                    <el-option label="已绑定" value="1"></el-option>
                    <el-option label="未绑定" value="0"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="微信昵称" prop="description" label-width="60px">
                  <el-input
                    v-model="HcpQueryForm.nickname"
                    class="bottom_input"
                    prop="title"
                    maxlength="50"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content bg-purple">
                <el-form-item label="extUserId" prop="description" label-width="70px">
                  <el-input
                    v-model="HcpQueryForm.extUserId"
                    class="bottom_input"
                    prop="title"
                    maxlength="50"
                  ></el-input>
                </el-form-item>
                <el-form-item label="clientName" prop="description" label-width="70px">
                  <el-input
                    v-model="HcpQueryForm.clientName"
                    class="bottom_input"
                    prop="title"
                    maxlength="50"
                  ></el-input>
                </el-form-item>
                <el-form-item label="MDM状态" prop="description" label-width="70px">
                  <el-select v-model="HcpQueryForm.validateState" clearable placeholder="请选择">
                    <el-option label="全部" value="null"></el-option>
                    <el-option label="验证中" value="pending"></el-option>
                    <el-option label="已验证" value="validated"></el-option>
                    <el-option label="被拒绝" value="rejected"></el-option>
                    <el-option label="实习医生" value="intern"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="客户来源" prop="description" label-width="70px">
                  <el-select v-model="HcpQueryForm.bindSource" clearable placeholder="请选择">
                    <el-option label="全部" value="null"></el-option>
                    <el-option label="register" value="register"></el-option>
                    <el-option label="scan" value="scan"></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content bg-purple">
                <el-form-item label="OpenID" prop="description" label-width="50px">
                  <el-input
                    v-model="HcpQueryForm.openId"
                    class="bottom_input"
                    prop="title"
                    maxlength="50"
                  ></el-input>
                </el-form-item>
                <el-form-item label="姓名" prop="description" label-width="50px">
                  <el-input
                    v-model="HcpQueryForm.name"
                    class="bottom_input"
                    prop="title"
                    maxlength="50"
                  ></el-input>
                </el-form-item>
                <el-form-item label="邀请人" prop="description" label-width="50px">
                  <el-input
                    v-model="HcpQueryForm.inviter"
                    class="bottom_input"
                    prop="title"
                    maxlength="50"
                  ></el-input>
                </el-form-item>
                <el-form-item label="部门" prop="description" label-width="50px">
                  <el-input
                    v-model="HcpQueryForm.department"
                    class="bottom_input"
                    prop="title"
                    maxlength="50"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple-light">
                <div class="grid-content bg-purple-light">
                  <el-form-item label="MDM code" prop="description" label-width="75px">
                    <el-input
                      v-model="HcpQueryForm.mdmCode"
                      class="bottom_input"
                      prop="title"
                      maxlength="50"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="关注状态" prop="description" label-width="75px">
                    <el-select v-model="HcpQueryForm.isFollow" clearable placeholder="请选择">
                      <el-option label="全部" value="null"></el-option>
                      <el-option label="已关注" value="1"></el-option>
                      <el-option label="未关注" value="0"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    label="绑定时间"
                    prop="description"
                    label-width="75px"
                    style="margin-bottom:0px;"
                  >
                    <div class="grid-content bg-purple">
                      <div id="block" class="block" style="width:60%">
                        <el-date-picker
                          v-model="HcpQueryForm.bindTimeFrom"
                          :picker-options="bind_start_date"
                          type="date"
                          style="width:100%"
                          placeholder="from"
                        ></el-date-picker>
                      </div>
                      <div
                        id="block"
                        class="block"
                        style="margin-left:60%;margin-top:-30px;width:70%;padding-left:22px;"
                      >
                        <el-date-picker
                          v-model="HcpQueryForm.bindTimeTo"
                          :picker-options="bind_end_date"
                          type="date"
                          style="width:100%"
                          placeholder="to"
                        ></el-date-picker>
                      </div>
                    </div>
                  </el-form-item>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-button type="primary" class="sub hcp_top_form_sub" plain @click="getSearchList()">查找客户</el-button>
          <el-button
            type="primary"
            class="sub hcp_top_form_sub"
            plain
            @click="clearSearchForm"
          >清空筛选条件</el-button>
        </el-form>
        <!-- 客户列表 -->
        <el-form
          id="hcp_list"
          ref="HcpQueryForm"
          :model="HcpQueryForm"
          label-width="70px"
          class="form"
          style="margin-top:10px;"
        >
          <div class="block">
            <el-table
              v-loading="loading"
              ref="table_list"
              :data="hcpList"
              :default-sort="{prop: 'createTime', order: 'descending'}"
              style="width: 100%;"
              header-row-class-name="tableHead"
              @sort-change="sortChange"
            >
              <el-table-column
                :show-overflow-tooltip="false"
                label="姓名"
                prop="name"
                align="center"
                min-width="70"
                max-width="7%"
              >
                <template slot-scope="scope">
                  <span
                    style="display:inline-block;width:100%; text-align:center;"
                  >{{ scope.row.name }}</span>
                </template>
              </el-table-column>
              <el-table-column
                :show-overflow-tooltip="false"
                label="UnionID"
                prop="unionId"
                align="center"
                min-width="230"
                max-width="15%"
              >
                <template slot-scope="scope">
                  <span
                    style="display:inline-block;white-space:nowrap;width:100%; text-align:center;"
                  >{{ scope.row.id }}</span>
                </template>
              </el-table-column>
              <el-table-column
                :show-overflow-tooltip="false"
                label="OpenID"
                prop="openId"
                align="center"
                min-width="230"
                max-width="15%"
              >
                <template slot-scope="scope">
                  <span
                    style="display:inline-block;white-space:nowrap;width:100%; text-align:center;"
                  >{{ scope.row.openId }}</span>
                </template>
              </el-table-column>
              <el-table-column
                :show-overflow-tooltip="false"
                label="clientName"
                prop="clientName"
                align="center"
                min-width="90"
                max-width="7%"
              >
                <template slot-scope="scope">
                  <span
                    style="display:inline-block;width:100%; text-align:center;"
                  >{{ scope.row.clientName }}</span>
                </template>
              </el-table-column>
              <el-table-column
                :show-overflow-tooltip="false"
                label="MDM Code"
                prop="mdmCode"
                align="center"
                min-width="90"
                max-width="7%"
              >
                <template slot-scope="scope">
                  <span
                    style="display:inline-block;width:100%; text-align:center;"
                  >{{ scope.row.mdmCode }}</span>
                </template>
              </el-table-column>
              <el-table-column
                v-if="tagStatus.extUserId"
                :show-overflow-tooltip="false"
                label="extUserId"
                prop="title"
                align="center"
                min-width="100"
                max-width="10%"
              >
                <template slot-scope="scope">
                  <span
                    style="display:inline-block;white-space:nowrap;width:100%; text-align:center;"
                  >{{ scope.row.extUserId }}</span>
                </template>
              </el-table-column>
              <el-table-column
                v-if="tagStatus.workplaceName"
                :show-overflow-tooltip="false"
                label="工作地点"
                prop="workplaceName"
                align="center"
                min-width="150"
                max-width="10%"
              >
                <template slot-scope="scope">
                  <!-- hover时显示详细信息 -->
                  <el-popover trigger="hover" placement="top">
                    <p>{{ scope.row.workplaceName }}</p>
                    <div slot="reference" class="name-wrapper">
                      <span
                        style="display:inline-block;white-space:nowrap;width:100%; text-align:center;"
                      >{{ scope.row.workplaceName }}</span>
                    </div>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column
                v-if="tagStatus.department"
                :show-overflow-tooltip="false"
                label="部门"
                prop="title"
                align="center"
                min-width="100"
                max-width="10%"
              >
                <template slot-scope="scope">
                  <span
                    style="display:inline-block;white-space:nowrap;width:100%; text-align:center;"
                  >{{ scope.row.department }}</span>
                </template>
              </el-table-column>
              <el-table-column
                v-if="tagStatus.wechatAvatar"
                :show-overflow-tooltip="false"
                label="头像"
                prop="mdmCode"
                align="center"
                min-width="50"
                max-width="5%"
              >
                <template slot-scope="scope">
                <el-image 
                  v-if="scope.row.wechatAvatar"
                  style="width: 40px; height: 40px"
                  :src="scope.row.wechatAvatar" 
                  :preview-src-list="[scope.row.wechatAvatar]">
                </el-image>
                </template>
              </el-table-column>

               <el-table-column
                v-if="tagStatus.nickname"
                :show-overflow-tooltip="false"
                label="微信昵称"
                prop="mdmCode"
                align="center" 
                min-width="90"
                max-width="7%"
              >
                <template slot-scope="scope">
                  <span
                    style="display:inline-block;width:100%; text-align:center;"
                  >{{ scope.row.nickname }}</span>
                </template>
              </el-table-column>
              <el-table-column
                v-if="tagStatus.inviter"
                :show-overflow-tooltip="false"
                label="邀请人"
                prop="title"
                align="center"
                min-width="70"
                max-width="7%"
              >
                <template slot-scope="scope">
                  <span
                    style="display:inline-block;white-space:nowrap;width:100%; text-align:center;"
                  >{{ scope.row.inviter }}</span>
                </template>
              </el-table-column>
              <el-table-column
                v-if="tagStatus.isFollow"
                :show-overflow-tooltip="false"
                label="关注状态"
                prop="publishState"
                align="center"
                min-width="80"
                max-width="7%"
              >
                <template slot-scope="scope">
                  <span
                    v-if="scope.row.isFollow==1"
                    style="display:inline-block;width:100%; text-align:center;"
                  >已关注</span>
                  <span
                    v-if="scope.row.isFollow==0"
                    style="display:inline-block;width:100%; text-align:center;"
                  >未关注</span>
                </template>
              </el-table-column>
              <el-table-column
                v-if="tagStatus.bindState"
                :show-overflow-tooltip="false"
                label="绑定状态"
                prop="publishState"
                align="center"
                min-width="80"
                max-width="7%"
              >
                <template slot-scope="scope">
                  <span
                    v-if="scope.row.validateState=='validated'"
                    style="display:inline-block;width:100%; text-align:center;"
                  >已绑定</span>
                  <span
                    v-if="scope.row.validateState!=='validated'"
                    style="display:inline-block;width:100%; text-align:center;"
                  >未绑定</span>
                </template>
              </el-table-column>
              <el-table-column
                v-if="tagStatus.validateState"
                :show-overflow-tooltip="false"
                label="MDM状态"
                prop="publishState"
                align="center"
                min-width="80"
                max-width="7%"
              >
                <template slot-scope="scope">
                  <span
                    v-if="scope.row.validateState == 'pending'"
                    style="display:inline-block;width:100%; text-align:center;"
                  >验证中</span>
                  <span
                    v-if="scope.row.validateState == 'rejected'"
                    style="display:inline-block;width:100%; text-align:center;"
                  >被拒绝</span>
                  <span
                    v-if="scope.row.validateState == 'validated'"
                    style="display:inline-block;width:100%; text-align:center;"
                  >已验证</span>
                  <span
                    v-if="scope.row.validateState == 'intern'"
                    style="display:inline-block;width:100%; text-align:center;"
                  >实习医生</span>
                </template>
              </el-table-column>
              <el-table-column
                v-if="tagStatus.bindTime"
                :show-overflow-tooltip="false"
                label="绑定时间"
                prop="bindTime"
                align="center"
                min-width="130"
                max-width="15%"
                sortable="custom"
              >
                <template slot-scope="scope">
                  <span
                    style="display:inline-block;white-space:nowrap;width:100%;text-align:center;"
                  >{{ scope.row.bindTime ? $dtime(scope.row.bindTime).format('YYYY-MM-DD HH:mm:ss'): null }}</span>
                </template>
              </el-table-column>
              <el-table-column
                v-if="tagStatus.createTime"
                :show-overflow-tooltip="false"
                label="创建时间"
                prop="createTime"
                align="center"
                min-width="130"
                max-width="15%"
                sortable="custom"
              >
                <template slot-scope="scope">
                  <span
                    style="display:inline-block;white-space:nowrap;width:100%;text-align:center;"
                  >{{ scope.row.createTime ? $dtime(scope.row.createTime).format('YYYY-MM-DD HH:mm:ss'): null }}</span>
                </template>
              </el-table-column>
              <el-table-column
                v-if="tagStatus.bindSource"
                :show-overflow-tooltip="false"
                label="客户来源"
                prop="bindSource"
                align="center"
                min-width="80"
                max-width="7%"
              >
                <template slot-scope="scope">
                  <span
                    style="display:inline-block;width:100%; text-align:center;"
                  >{{ scope.row.bindSource }}</span>
                </template>
              </el-table-column>
              <el-table-column
                :show-overflow-tooltip="false"
                label="操作"
                prop="state"
                fixed="right"
                width="150"
              >
                <!-- 筛选列表触发按钮 -->
                <template slot="header" slot-scope="scope">
                  <img
                    class="shaixuan"
                    style="float:right;margin-top:7px;vertical-align: -3px;"
                    src="../../assets/image/shaixuan.png"
                    alt
                    @click="showNext"
                  />
                </template>
                <!-- 解绑按钮 -->
                <template
                  v-if="$global.comparePermisson('客户管理', 'dcr_validation_list')||$global.comparePermisson('客户管理', 'hcp_account_log_list')||$global.comparePermisson('客户管理', 'hcp_unbind')"
                  slot-scope="scope"
                >
                  <span>
                    <el-button
                      v-if="$global.comparePermisson('客户管理', 'dcr_validation_list')"
                      type="primary"
                      class="sub_review sub"
                      style="width:54px;"
                      plain
                      @click="toRegister(scope.row)"
                    >绑定记录</el-button>
                    <el-button
                      v-if="$global.comparePermisson('客户管理', 'hcp_account_log_list')"
                      type="primary"
                      class="sub_review sub"
                      style="width:54px;"
                      plain
                      @click="toAccount(scope.row)"
                    >变更记录</el-button>
                  </span>
                  <span>
                    <el-button
                      v-if="(scope.row.validateState=='validated'||scope.row.validateState=='rejected')&&$global.comparePermisson('客户管理', 'hcp_unbind')"
                      type="primary"
                      class="sub_review sub"
                      style="width:54px;"
                      plain
                      @click="deleteHcp($event,scope.row)"
                    >解绑</el-button>
                  </span>
                </template>
              </el-table-column>
            </el-table>
            <!-- 列表下方分页信息 -->
            <el-pagination
              :current-page="currentPage"
              :page-sizes="[20,50,100]"
              :page-size="parseInt(page.limit)"
              :total="page.total"
              style="margin:center;margin-top:12px;margin-bottom:12px;height:46px;text-align:center;"
              layout="total,sizes,prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            ></el-pagination>
            <!-- :current-page="page.offset" -->
          </div>
          <!-- 筛选列表容器 -->
          <el-transfer
            v-if="showShaixuan"
            :props="{key: 'id',label: 'name'}"
            :titles="['筛选']"
            :left-default-checked="checkedShaixuan"
            :data="noData"
            style="width:130px;"
            @left-check-change="handleChange"
          >
            <el-button
              slot="left-footer"
              type="primary"
              class="transfer-footer sub_review sub"
              size="small"
              @click="determineShaixuan"
            >确定</el-button>
            <el-button
              slot="left-footer"
              type="primary"
              class="transfer-footer sub_review sub"
              size="small"
              @click="cancelShaixuan"
            >取消</el-button>
          </el-transfer>
        </el-form>
      </el-col>
    </el-row>
  </div>


</template>
<script>

export default {
  components: { },
  data() {
    return {
      //绑定时间选择器关联
      bind_start_date: {
        //开始时间范围限定
        disabledDate: (time) => {
          if (this.HcpQueryForm.bindTimeTo) {
            return time.getTime() > this.HcpQueryForm.bindTimeTo
          }
          return time.getTime() < Date.now() - 8.64e11
        },
      },
      bind_end_date: {
        //结束时间范围限定
        disabledDate: (time) => {
          return time.getTime() <= this.HcpQueryForm.bindTimeFrom - 1
        },
      },
      //filter显示状态
      showSearch: true,
      //初始筛选选中状态
      item: ['workplaceName', 'inviter'],
      //table列显示
      tagStatus: {
        name: true,
        mdmCode: true,
        clientName: true,
        Unionid: true,
        OpenId: true,
        extUserId: false,
        workplaceName: true,
        inviter: true,
        nickname: true,
        wechatAvatar:true,
        department: false,
        isFollow: false,
        bindSource: false,
        bindTime: false,
        createTime: false,
        bindState: true,
        validateState: false,
      },
      //列表Loading
      loading: false,
      //筛选初始赋值
      checkedShaixuan: ['workplaceName', 'inviter'],
      //筛选列表元素，disabled为不可选中
      noData: [
        { id: 'name', name: '姓名', disabled: true },
        { id: 'Unionid', name: 'UnionID', disabled: true },
        { id: 'OpenId', name: 'OpenID', disabled: true },
        { id: 'mdmCode', name: 'MDM code', disabled: true },
        { id: 'clientName', name: 'clientName', disabled: true },
        { id: 'workplaceName', name: '工作地点' },
        { id: 'nickname', name: '微信昵称' },
        { id: 'wechatAvatar', name: '头像' },
        { id: 'extUserId', name: 'extUserId' },
        { id: 'department', name: '部门' },
        { id: 'inviter', name: '邀请人' },
        { id: 'isFollow', name: '关注状态' },
        { id: 'bindState', name: '绑定状态' },
        { id: 'validateState', name: 'MDM状态' },
        { id: 'bindTime', name: '绑定时间' },
        { id: 'createTime', name: '创建时间' },
        { id: 'bindSource', name: '客户来源' },
      ],
      //筛选弹窗显示隐藏
      showShaixuan: false,
      //客户列表存值数组
      hcpList: [],
      //分页默认设置
      page: {
        limit: 20,
        offset: 0,
        total: 0,
      },
      order: '+',
      sortProp: 'createTime',
      //初始显示页码
      currentPage: 1,
      oldSearch:{
        name: null, //客户名称
        unionId: null,
        openId: null,
        extUserId: null,
        mdmCode: null, //MDMId
        clientName: null,
        nickname: null,
        workplaceName: null, //工作地点
        inviter: null, //inviter
        bindState: null,
        validateState: null, //状态
        isFollow: null,
        bindSource: null,
        department: null,
        bindTimeTo: null,
        bindTimeFrom: null,
      },
      //文章查询
      HcpQueryForm: {
        name: null, //客户名称
        unionId: null,
        openId: null,
        extUserId: null,
        mdmCode: null, //MDMId
        clientName: null,
        nickname: null,
        workplaceName: null, //工作地点
        inviter: null, //inviter
        bindState: null,
        validateState: null, //状态
        isFollow: null,
        bindSource: null,
        department: null,
        bindTimeTo: null,
        bindTimeFrom: null,
      },
      commentMessage: '',
      errorMessage: '',
    }
  },
  computed: {
  },
  beforeCreate() {},
  created() {
    // 获取存储在本地的筛选列表历史
    this.tagStatus = this.$global.getShaixuan('hcpshaixuan')
      ? this.$global.getShaixuan('hcpshaixuan')
      : this.tagStatus
    this.getCheckedPsrStatus()
    //获取客户列表
    const data = {
      offset: this.page.offset,
      limit: this.page.limit,
      order: this.order + this.sortProp,
    }
    this.getHcpList(data)
  },
  mounted() {},
  methods: {
    toAccount(parms) {
      const href = this.$router.resolve({
        name: 'Account',
        query: { id: parms.id },
      })
      window.open(href.href, '_blank')
    },
    toRegister(parms) {
      const href = this.$router.resolve({
        name: 'Register',
        query: { id: parms.id },
      })
      window.open(href.href, '_blank')
    },
    shSearch() {
      this.showSearch = !this.showSearch
    },
    //读取筛选列表信息，更新筛选选择状态
    getCheckedPsrStatus() {
      this.checkedShaixuan = []
      for (const i in this.tagStatus) {
        if (this.tagStatus[i] == true) {
          this.checkedShaixuan.push(i)
        }
      }
      this.item = this.checkedShaixuan
    },
    //筛选勾选触发函数
    handleChange(value) {
      this.item = value
    },
    //确定筛选
    determineShaixuan() {
      //更新筛选状态
      this.checkedShaixuan = this.item
      this.showShaixuan = false
      for (const attr in this.tagStatus) {
        this.tagStatus[attr] = false
      }
      for (let i = 0; i < this.item.length; i++) {
        this.tagStatus[this.item[i]] = true
      }
      //存储筛选信息到本地
      this.$global.setShaixuan('hcpshaixuan', this.tagStatus)
    },
    cancelShaixuan() {
      //取消筛选
      this.showShaixuan = false
    },
    //获取客户列表
    getHcpList(data) {
      this.loading = true
      this.$global
        .httpGetWithToken(this, '/hcp', data)
        .then((res) => {
          this.hcpList = res.data
          this.page = res.page
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
        })
    },
    getEmployee(row){
       console.log('row-->',row)
      this.loading = true
      return this.$global
        //.httpGetWithToken(this, '/hcp', data)
        .httpPostWithToken(this, "/hcp/rc/getEmployee", { data: {union_id:row.id} })
        .then((res) => {
          //console.log('getEmployee-->',res)
          // this.hcpList = res.data
          // this.page = res.page
          this.loading = false
          return res.data;
        })
        .catch((error) => {
          // console.log('getEmployee-->',res)
          this.loading = false
          return null;
        })
    },
    //解绑确认弹窗及接口
    deleteHcp($event,row) {
      this.getEmployee(row).then(employee=>{
          //console.log('getEmployee',res)
          //let employee=res.data;
          this.deleteHcp2($event,row,employee)
        })
    },
    deleteHcp2($event,row,employee) {
      if($event.detail==0){
        return
      }
  
      const _this = this
      const h = this.$createElement
      var dom = document.getElementById('foo')
      var errorDom = document.getElementById('error_worning')
      if (errorDom) {
        _this.errorMessage = ''
        errorDom.innerHTML = ''
      }
      if (dom) {
        dom.value = ''
      }
      this.$msgbox({
        title: '提示',
        message: h('div', { style: '' }, [
          h(
            'p',
            { style: 'margin-bottom:10px;font-size:13px;' },
            '解除绑定后将清除该用户在本系统中的所有信息,确定解除绑定?'
          ), h(
            'p',
            { style: 'margin-bottom:10px;font-size:13px;' },
            'isid:'+(!employee?'无':employee.isid)
          ),
          h(
            'div',
            {
              class: {
                textarea: true,
                bar: false,
              },
            },
            [
              h('span', { style: 'float:left;color: red;' }, '*'),
              h('span', { style: 'float:left;font-size:13px;' }, '解绑原因:'),
              h(
                'textarea',
                {
                  class: {
                    textarea: true,
                    bar: false,
                  },
                  attrs: {
                    id: 'foo',
                    placeholder: '请输入解绑原因',
                    type: 'text',
                    resize: 'none',
                    rows: '4',
                  },
                  style: {
                    float: 'right',
                    'font-size': '13px',
                    resize: 'none',
                    width: '80%',
                    border: '1px solid #DCDFE6',
                    maxlength: 255,
                  },
                  on: {
                    input: (event) => {
                      var dom = document.getElementById('foo')
                      var errorDom = document.getElementById('error_worning')
                      _this.errorMessage = ''
                      errorDom.innerHTML = ''
                      _this.commentMessage = dom.value.trim()
                    },
                    blur: () => {
                      var errorDom = document.getElementById('error_worning')
                      if (_this.commentMessage.length == 0) {
                        _this.errorMessage = '解绑原因不能为空'
                        errorDom.innerHTML = _this.errorMessage
                      } else {
                        _this.errorMessage = ''
                        errorDom.innerHTML = ''
                      }
                    },
                  },
                },
                ''
              ),
              h(
                'span',
                {
                  attrs: {
                    id: 'error_worning',
                  },
                  style:
                    'float:right;width:80%;font-size:13px;color:red;text-align:left;text-indent:5px;height:18px;',
                },
                ''
              ),
            ]
          ),
        ]),
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        cancelButtonClass: '',
        autofocus: true,
        type: 'warning',
        center: true,
        beforeClose(action, instance, done) {
          if (action == 'confirm') {
            this.$nextTick(()=>{
              if (window.event.detail != 0) {
                var errorDom = document.getElementById('error_worning')
                if (_this.commentMessage.length == 0) {
                  _this.errorMessage = '解绑原因不能为空'
                  errorDom.innerHTML = _this.errorMessage
                } else {
                  _this.errorMessage = ''
                  errorDom.innerHTML = ''
                }
                if (_this.commentMessage.length == 0) {
                  return
                }
                if (_this.errorMessage.length != 0) {
                  return
                }
                done()
                _this.loading = true
                const commentData = {
                  comment: _this.commentMessage,
                }
                _this.$global
                  .httpDeleteWithToken(
                    _this,
                    '/hcp/' + row.id + '/unbind',
                    commentData
                  )
                  .then((res) => {
                    _this.$notify({
                      title: '成功',
                      message: '解绑成功',
                      type: 'success',
                      offset: 38,
                    })
                    _this.loading = false
                    var errorDom = document.getElementById('error_worning')
                    _this.errorMessage = ''
                    errorDom.innerHTML = ''
                    _this.commentMessage = ''
                    _this.getSearchList()
                  })
                  .catch(() => {
                    _this.loading = false
                    var errorDom = document.getElementById('error_worning')
                    _this.errorMessage = ''
                    errorDom.innerHTML = ''
                    _this.commentMessage = ''
                  })
              }
            })
          } else {
            const errorDom = document.getElementById('error_worning')
            _this.errorMessage = ''
            errorDom.innerHTML = ''
            _this.commentMessage = ''
            done()
          }
        },
      })
      .then(()=>{
      })
      .catch(() => {
      })
    },
    clearSearchForm() {
      this.HcpQueryForm = {
        name: null, //客户名称
        unionId: null,
        openId: null,
        extUserId: null,
        mdmCode: null, //MDMId
        clientName: null,
        nickname: null,
        workplaceName: null, //工作地点
        inviter: null, //inviter
        bindState: null,
        validateState: null, //状态
        isFollow: null,
        bindSource: null,
        department: null,
        bindTimeTo: null,
        bindTimeFrom: null,
      }
      //清空文章板块
    },
    //点击分页时触发,改变列表内容
    handleSizeChange(val) {
      this.HcpQueryForm = JSON.parse(JSON.stringify(this.oldSearch));
      this.currentPage = 1
      this.page.limit = val
      this.page.offset = 0
      const data = {
        name: this.HcpQueryForm.name,
        unionId: this.HcpQueryForm.unionId,
        openId: this.HcpQueryForm.openId,
        extUserId: this.HcpQueryForm.extUserId,
        mdmCode: this.HcpQueryForm.mdmCode, //MDMId
        clientName: this.HcpQueryForm.clientName,
        nickname: this.HcpQueryForm.nickname,
        workplaceName: this.HcpQueryForm.workplaceName, //工作地点
        inviter: this.HcpQueryForm.inviter, //inviter
        bindState: this.HcpQueryForm.bindState,
        validateState: this.HcpQueryForm.validateState, //状态
        isFollow: this.HcpQueryForm.isFollow,
        bindSource: this.HcpQueryForm.bindSource,
        department: this.HcpQueryForm.department,
        bindTimeFrom: this.HcpQueryForm.bindTimeFrom,
        bindTimeTo: this.HcpQueryForm.bindTimeTo,
        offset: this.page.offset,
        limit: this.page.limit,
        order: this.order + this.sortProp,
      }
      if (this.HcpQueryForm.bindTimeFrom) {
        data.bindTimeFrom = JSON.stringify(
          new Date(
            new Date(this.HcpQueryForm.bindTimeFrom).getTime() +
              8 * 60 * 60 * 1000
          )
        )
      }
      if (this.HcpQueryForm.bindTimeTo) {
        data.bindTimeTo = JSON.stringify(
          new Date(
            new Date(this.HcpQueryForm.bindTimeTo).getTime() +
              31 * 60 * 60 * 1000 +
              59 * 60 * 1000 +
              59 * 1000
          )
        )
      }
      for (var i in data) {
        if (data[i] == '' || !data[i]) {
          data[i] = null
        }
      }
      this.getHcpList(data)
    },
    //按页数展示
    handleCurrentChange(val) {
      this.HcpQueryForm = JSON.parse(JSON.stringify(this.oldSearch));
      this.currentPage = val
      this.page.offset = (val - 1) * this.page.limit
      const data = {
        name: this.HcpQueryForm.name,
        unionId: this.HcpQueryForm.unionId,
        openId: this.HcpQueryForm.openId,
        extUserId: this.HcpQueryForm.extUserId,
        mdmCode: this.HcpQueryForm.mdmCode, //MDMId
        clientName: this.HcpQueryForm.clientName,
        nickname: this.HcpQueryForm.nickname,
        workplaceName: this.HcpQueryForm.workplaceName, //工作地点
        inviter: this.HcpQueryForm.inviter, //inviter
        bindState: this.HcpQueryForm.bindState,
        validateState: this.HcpQueryForm.validateState, //状态
        isFollow: this.HcpQueryForm.isFollow,
        bindSource: this.HcpQueryForm.bindSource,
        department: this.HcpQueryForm.department,
        bindTimeFrom: this.HcpQueryForm.bindTimeFrom,
        bindTimeTo: this.HcpQueryForm.bindTimeTo,
        offset: this.page.offset,
        limit: this.page.limit,
        order: this.order + this.sortProp,
      }
      if (this.HcpQueryForm.bindTimeFrom) {
        data.bindTimeFrom = JSON.stringify(
          new Date(
            new Date(this.HcpQueryForm.bindTimeFrom).getTime() +
              8 * 60 * 60 * 1000
          )
        )
      }
      if (this.HcpQueryForm.bindTimeTo) {
        data.bindTimeTo = JSON.stringify(
          new Date(
            new Date(this.HcpQueryForm.bindTimeTo).getTime() +
              31 * 60 * 60 * 1000 +
              59 * 60 * 1000 +
              59 * 1000
          )
        )
      }
      for (var i in data) {
        if (data[i] == '' || !data[i]) {
          data[i] = null
        }
      }
      this.getHcpList(data)
    },
    //后台排序
    sortChange(column, prop, order) {
      this.HcpQueryForm = JSON.parse(JSON.stringify(this.oldSearch));
      this.currentPage = 1
      this.page.offset = 0
      this.sortProp = column.prop
      //去除点击搜索后此时sort排序时创建时间排序的状态
      if (this.sortProp != 'createTime') {
        for (const attr in this.$refs.table_list.columns) {
          if (this.$refs.table_list.columns[attr].property == 'createTime') {
            this.$refs.table_list.columns[attr].order = ''
          }
        }
      }
      if (column.order == 'ascending') {
        this.order = '-'
      }
      if (column.order == 'descending' || column.order == null) {
        this.order = '+'
      }
      const data = {
        name: this.HcpQueryForm.name,
        unionId: this.HcpQueryForm.unionId,
        openId: this.HcpQueryForm.openId,
        extUserId: this.HcpQueryForm.extUserId,
        mdmCode: this.HcpQueryForm.mdmCode, //MDMId
        clientName: this.HcpQueryForm.clientName,
        nickname: this.HcpQueryForm.nickname,
        workplaceName: this.HcpQueryForm.workplaceName, //工作地点
        inviter: this.HcpQueryForm.inviter, //inviter
        bindState: this.HcpQueryForm.bindState,
        validateState: this.HcpQueryForm.validateState, //状态
        isFollow: this.HcpQueryForm.isFollow,
        bindSource: this.HcpQueryForm.bindSource,
        department: this.HcpQueryForm.department,
        bindTimeFrom: this.HcpQueryForm.bindTimeFrom,
        bindTimeTo: this.HcpQueryForm.bindTimeTo,
        offset: this.page.offset,
        limit: this.page.limit,
        order: this.order + this.sortProp,
      }
      if (this.HcpQueryForm.bindTimeFrom) {
        data.bindTimeFrom = JSON.stringify(
          new Date(
            new Date(this.HcpQueryForm.bindTimeFrom).getTime() +
              8 * 60 * 60 * 1000
          )
        )
      }
      if (this.HcpQueryForm.bindTimeTo) {
        data.bindTimeTo = JSON.stringify(
          new Date(
            new Date(this.HcpQueryForm.bindTimeTo).getTime() +
              31 * 60 * 60 * 1000 +
              59 * 60 * 1000 +
              59 * 1000
          )
        )
      }
      for (var i in data) {
        if (data[i] == '' || !data[i]) {
          data[i] = null
        }
      }
      this.getHcpList(data)
    },
    //筛选文章列表表头
    showNext() {
      this.showShaixuan = true
      this.$nextTick(() => {
        var checked = document.getElementsByClassName('is-disabled')
        for (const i in checked) {
          if (checked[i].localName == 'span') {
            checked[i].classList.add('is-checked')
          }
        }
      })
    },
    //执行搜索
    getSearchList() {
      this.oldSearch = JSON.parse(JSON.stringify(this.HcpQueryForm));
      this.page.offset = 0
      this.$refs.table_list.clearSort()
      //点击搜索后恢复sort排序默认状态样式
      for (const attr in this.$refs.table_list.columns) {
        if (this.$refs.table_list.columns[attr].property == 'createTime') {
          this.$refs.table_list.columns[attr].order = 'descending'
        }
      }
      const data = {
        name: this.HcpQueryForm.name,
        unionId: this.HcpQueryForm.unionId,
        openId: this.HcpQueryForm.openId,
        extUserId: this.HcpQueryForm.extUserId,
        mdmCode: this.HcpQueryForm.mdmCode, //MDMId
        clientName: this.HcpQueryForm.clientName,
        nickname: this.HcpQueryForm.nickname,
        workplaceName: this.HcpQueryForm.workplaceName, //工作地点
        inviter: this.HcpQueryForm.inviter, //inviter
        bindState: this.HcpQueryForm.bindState,
        validateState: this.HcpQueryForm.validateState, //状态
        isFollow: this.HcpQueryForm.isFollow,
        bindSource: this.HcpQueryForm.bindSource,
        department: this.HcpQueryForm.department,
        bindTimeFrom: this.HcpQueryForm.bindTimeFrom,
        bindTimeTo: this.HcpQueryForm.bindTimeTo,
        offset: this.page.offset,
        limit: this.page.limit,
        order: this.order + this.sortProp,
      }
      if (this.HcpQueryForm.bindTimeFrom) {
        data.bindTimeFrom = JSON.stringify(
          new Date(
            new Date(this.HcpQueryForm.bindTimeFrom).getTime() +
              8 * 60 * 60 * 1000
          )
        )
      }
      if (this.HcpQueryForm.bindTimeTo) {
        data.bindTimeTo = JSON.stringify(
          new Date(
            new Date(this.HcpQueryForm.bindTimeTo).getTime() +
              31 * 60 * 60 * 1000 +
              59 * 60 * 1000 +
              59 * 1000
          )
        )
      }
      for (var i in data) {
        if (data[i] == '' || !data[i]) {
          data[i] = null
        }
      }
      this.getHcpList(data)
      this.currentPage = 1
    },
  },
}
</script>
<style scoped>
.textarea {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: bottom;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, Arial, sans-serif;
  outline: none !important;
}
.textarea:focus {
  outline: normal !important;
  border: '1px solid #409EFF';
}
/* TOP form样式 */
.hcp_list {
  margin-top: 8px;
  width: 93%;
}
.hcp_list > el-col {
  max-width: 83.3%;
}
.form {
  min-width: 400px;
  margin-bottom: 28px;
}
.form_header {
  text-align: left;
}
#top_form_select .el-form-item {
  margin-bottom: 0px;
}
#top_form_select img {
  width: 22px;
  vertical-align: -4px;
}
#block .el-input__prefix > .el-input__icon {
  position: absolute;
  left: 210px;
  top: 0;
  height: 100%;
  width: 22px;
  font-size: 22px;
}
#top_form_select .el-input input {
  width: 100%;
  height: 24px;
}
#top_form_select .el-button {
  margin-top: 24px;
}
/* 文章详情样式 */
.hcp_list #hcp_list .el-button--primary {
  background-color: #00857c;
  border-color: #00857c;
  color: #ffffff;
}
.hcp_list #hcp_list .el-button--primary:hover {
  background-color: #00857c !important;
  border-color: #00857c !important;
  color: #ffffff !important;
}
#hcp_list {
  box-shadow: 0px 0px 10px 0px rgba(99, 99, 99, 0.33);
}
#hcp_list .el-form-item {
  margin-left: -10px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  height: 100%;
}
#hcp_list img {
  margin-left: 6px;
  display: inline-block;
  width: 16px;
}
.caozuo {
  font-size: 16px;
  color: #909399;
  font-weight: 400;
  border-bottom: 1px solid #909399;
  margin-right: 30px;
}
.caozuo:hover {
  cursor: pointer;
  color: #00857c;
  border-bottom-color: #00857c;
}
.shaixuan:hover {
  cursor: pointer;
}
.sub {
  width: 44px;
  height: 20px;
  font-size: 12px;
  text-align: center;
  background: rgba(240, 242, 245, 1);
  color: #00857c;
  padding: 0;
}
.sub:hover {
  background: rgba(240, 242, 245, 1);
  color: #00857c;
}
.hcp_list .hcp_top_form_sub {
  width: 104px;
  height: 27px;
  background: #00857c;
  border-color: #00857c;
  color: #ffffff;
}
.hcp_list .hcp_top_form_sub:hover {
  background: #00857c !important;
  color: #ffffff !important;
}
.hcp_list .el-button--primary.is-plain:focus,
.el-button--primary.is-plain:hover {
  background: #00857c !important;
  border-color: #00857c !important;
  color: #fff;
}
.hcp_list .el-button.is-active,
.el-button.is-plain:active {
  color: #fff;
  background: #00857c !important;
}
.hcp_list .el-button.is-plain:focus,
.el-button.is-plain:hover {
  background: #00857c !important;
  color: #fff;
}
.category_select {
  border: 1px solid #eaeefb;
  padding: 10px 30px 10px 10px;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.add_category_row {
  height: 0;
  overflow: hidden;
  transition: all 400ms;
  background: #f9fafc;
}
.showEdit {
  height: 185px;
}
.add_category {
  background: #f9fafc;
  padding: 10px 30px 0px 10px;
  border: 1px solid #eaeefb;
  border-top: none;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #20a0ff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
.cell {
  text-align: center;
}
.hcp_list .el-table__header-wrapper {
  background: #f5f7fa;
}
</style>




