<template>
  <div class="app-wrapper">
    <div
      style="position:fixed;top:0px;left:0px;width:100%;height:55px;backgroundColor:#00857c;z-index:9999;"
    >
      <div style="float:left;width:180px;height:100%">
        <img
          src="../../assets/image/LOGO.png"
          style="display:inline-block;float:left;width:25px;margin-top:15px;margin-left:25px;"
          alt
        />
        <img
          src="../../assets/image/msd.png"
          style="display:inline-block;float:left;width:48px;margin-top:19px;margin-left:10px;"
          alt
        />
      </div>
      <div class="right-info">
        <span class="user_name" style="margin-right:20px;">
          <img style="width:16px;vertical-align: -3px;" src="../../assets/image/role.png" alt />
          {{ user }}
        </span>
        <span class="logout" style="margin-right:20px;" @click="logout">
          <img style="width:16px;vertical-align: -3px;" src="../../assets/image/layout.png" alt />
          退出登录
        </span>
      </div>
    </div>
    <div class="sidebar-container">
      <el-menu
        :show-timeout="200"
        :collapse="false"
        :unique-opened="true"
        mode="vertical"
        background-color="#37424A"
        text-color="#bfcbd9"
        active-text-color="#409EFF"
        class="menu-container"
        :default-active="activeKey"
        @select="goPage"
      >
        <el-menu-item style="background-color:rgb(31,45,62);">
          <img style="width:25px;vertical-align: -7px;" src="../../assets/image/msd.png" alt />
          <span slot="title" style="font-size:18px;font-weight:bold;color:white;">MSD</span>
        </el-menu-item>

        <el-submenu
          v-for="(item,index) in data"
          v-if="item.name!=='咨询管理'&&item.user"
          :index="index+''"
          :key="index"
        >
          <template slot="title">
            <img :src="item.src" style="width:16px;vertical-align: -3px;" alt />
            <span style="font-size:12px;font-weight:400">{{ item.name }}</span>
          </template>
          <el-menu-item
            v-for="(item1,index1) in item.data"
            v-if="item1[2]"
            :index="index+'-'+index1"
            :key="index1"
          >
            <span slot="title" style="font-size:12px;font-weight:400">{{ item1[0] }}</span>
          </el-menu-item>
        </el-submenu>
        <el-menu-item
          v-for="(item,index) in data"
          v-if="item.name=='咨询管理'&&item.user"
          :index="index+''"
          :key="index"
        >
          <img :src="item.src" style="width:16px;vertical-align: -3px;" alt />
          <span slot="title" style="font-size:12px;font-weight:400">{{ item.name }}</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div id="main-container" class="main-container">
      <div class="navbar" style="margin-top:55px;line-height:50px;margin-left:3.3%;">
        <el-breadcrumb separator="/" style="margin-top:auto;line-height:50px;">
          <el-breadcrumb-item
            v-for="(item, index,) in $route.meta"
            :to="{ path: item.pathName }"
            :key="index"
            style="margin-top:auto;line-height:50px;"
          >{{ item.name }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <section class="app-main">
        <transition name="fade-transform" mode="out-in">
          <router-view />
        </transition>
      </section>
    </div>
    <div id="footer" class="bottom_footer">
      <a
        target="_blank"
        style=" width:50px; height:18px;color:#BFCBD9;margin-left:50px;font-size:12px;border-bottom:1px solid #BFCBD9;"
        @click="goTerms"
      >使用条款</a>
      <a
        target="_blank"
        style=" width:50px; height:18px;color:#BFCBD9;margin-left:50px;font-size:12px;border-bottom:1px solid #BFCBD9;"
        @click="goStatement"
      >隐私政策</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Layout',
  data() {
    return {
      user: '未登录',
      token: null,
      activeKey: null,
      data: [
        //需添加三级路由
        {
          user: this.$global.comparePermisson('系统管理'),
          name: '系统管理',
          src: require('../../assets/image/user.png'),
          data: [
            [
              '管理员管理',
              'User',
              this.$global.comparePermisson('系统管理', 'admin_list'),
            ],
            [
              '角色管理',
              'Role',
              this.$global.comparePermisson('系统管理', 'role_list'),
            ],
            [
              '客户端管理',
              'Client',
              this.$global.comparePermisson('系统管理', 'client_list'),
            ],
             [
              '渠道管理',
              'Channel',
              this.$global.comparePermisson('系统管理', 'channel_list'),
            ],
          ],
        },
        {
          user: this.$global.comparePermisson('客户管理'),
          name: '客户管理',
          src: require('../../assets/image/user.png'),
          data: [
            [
              '账号管理',
              'Hcp',
              this.$global.comparePermisson('客户管理', 'hcp_list'),
            ],[
              '黑名单管理',
              'Blacklist',
              this.$global.comparePermisson('客户管理', 'blacklist_list'),
            ],[
              '高频禁用管理',
              'Overclock',
              this.$global.comparePermisson('客户管理', 'overclock_list'),
            ],
            [
              'DCR绑定日志',
              'Register',
              this.$global.comparePermisson('客户管理', 'dcr_validation_list'),
            ],
            [
              '账号变更日志',
              'Account',
              this.$global.comparePermisson('客户管理', 'hcp_account_log_list'),
            ],
            [
              '重复绑定日志',
              'Config',
              this.$global.comparePermisson('客户管理', 'binding_conflict_list'),
            ],[
              'Consent管理',
              'Consent',
              this.$global.comparePermisson('客户管理', 'consent_list'),
            ],
          ],
        },
        {
          user: this.$global.comparePermisson('日志管理'),
          name: '日志管理',
          src: require('../../assets/image/user.png'),
          data: [
            [
              'token生成日志',
              'TokenList',
              this.$global.comparePermisson('日志管理', 'token_list'),
            ],
            [
              '标签推送日志',
              'JingsocialTag',
              this.$global.comparePermisson('日志管理', 'jingsocial_tag_list'),
            ],
            [
              '微信推送日志',
              'WechatEvent',
              this.$global.comparePermisson('日志管理', 'notification_list'),
            ],
            [
              '管理员变更日志',
              'AdminAccountLog',
              this.$global.comparePermisson('日志管理', 'admin_account_list'),
            ],
            [
              '生成HCP二维码日志',
              'QrcodeLog',
              this.$global.comparePermisson('日志管理', 'qrcode_list'),
            ],
            [
              '客户端调用日志',
              'ThirdClientApi',
              this.$global.comparePermisson('日志管理', 'third_client_api_list'),
            ],
            [
              '三方接口调用日志',
              'ThirdPartyApi',
              this.$global.comparePermisson('日志管理', 'third_party_api_list'),
            ],[
              'Aktana推送日志',
              'aktana_push_message',
              this.$global.comparePermisson('日志管理', 'aktana_push_message'),
            ],
          ],
        },
      ],
    }
  },
  computed: {

  },
  watch:{
    $route(to,from){
      this.computeKey(to.name)
    }
  },
  beforeCreate() {},
  created() {
    this.token = this.$cookie.get('token')
    this.user = this.$cookie.get('name')
    if (!this.token) {
      this.$notify({
        title: '错误',
        message: '登录已失效，请重新登录',
        type: 'error',
        offset: 38,
        duration: 0,
      })
      this.$router.push({ path: '/login' })
    }
  },
  destroyed() {},
  methods: {
    computeKey(name){
      this.activeKey = null
      this.data.forEach(item=>{
        console.log(item)
        item.data.forEach(item2=>{
          if(name == item2[1]){
            this.activeKey = this.data.indexOf(item) + '-' + item.data.indexOf(item2)
            return
          }
        })
      })
    },
    logout() {
      this.$cookie.delete('token')
      this.$cookie.delete('name')
      this.$cookie.delete('isid')
      this.$global.clearPermisson()
      this.$global.clearShaixuan('psrshaixuan')
      this.token = null
      this.$router.push({
        path: '/login',
        query: {
          name: 'logout',
        },
      })
    },
    goPage(key) {
      if (key.length > 1) {
        const temp = key.split('-')
        this.selectData = this.data[temp[0]].data[temp[1]]
        this.$router.push({ name: this.selectData[1] })
      } else {
        const temp = key
        this.selectData = this.data[temp].data[0][1]
        this.$router.push({ name: this.selectData })
      }
    },
    goStatement() {
      window.open(
        'https://www.msdprivacy.com/cn/cn/internet-privacy-policy.html',
        '_blank'
      )
    },
    goTerms() {
      window.open('http://www.msdchina.com.cn/termsofuse/', '_blank')
    },
  },
}
</script>

<style scoped>
.navbar {
  height: 50px;
  line-height: 50px;
  border-radius: 0px !important;
  font-size: 20px;
  font-weight: bold;
  padding-left: 20px;
  padding-left: 0px;
  display: inline-block;
  border-bottom: 2px solid #00857c;
  width: 90%;
}
.right-info {
  float: right;
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  min-width: 180px;
  height: 55px;
  line-height: 55px;
  border-bottom: 0px solid transparent;
}
.logout {
  cursor: pointer;
}
.main-container {
  min-height: 100%;
  transition: margin-left 0.28s;
  margin-left: 180px;
  position: relative;
}
.menu-container {
  border: none;
  background-color: rgb(48, 65, 86);
  height: 100%;
}
.app-main {
  margin-bottom: 100px;
}
.sidebar-container {
  transition: width 0.28s;
  width: 180px !important;
  height: 100%;
  min-height: 700px;
  position: fixed;
  font-size: 0px;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: ;
  z-index: 1001;
  overflow: hidden;
  .horizontal-collapse-transition {
    transition: 0s width ease-in-out, 0s padding-left ease-in-out,
      0s padding-right ease-in-out;
  }
  .scrollbar-wrapper {
    overflow-x: hidden !important;
    .el-scrollbar__view {
      height: 100%;
    }
  }
  .el-scrollbar__bar.is-vertical {
    right: 0px;
  }
  .is-horizontal {
    display: none;
  }
  a {
    display: inline-block;
    width: 100%;
    overflow: hidden;
  }
  .el-menu {
    border: none !important;
    height: 100%;
    width: 100% !important;
  }
  .is-active > .el-submenu__title {
    color: #f4f4f5 !important;
  }
}

.app-wrapper {
  @include clearfix;
  position: relative;

  height: 100%;
  width: 100%;
  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}
.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}
.bottom_footer {
  width: 100%;
  height: 40px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background-color: #37424a;
  text-align: center;
  line-height: 40px;
}
</style>
<style>
</style>
