<template>
  <div id="app">
    <!-- <img src="./assets/logo.png"> -->
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
body {
  margin: 0;
  height: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, Arial, sans-serif;
}
label {
  font-weight: 700;
}

html {
  height: 100%;
  box-sizing: border-box;
}

#app {
  height: 100%;
}
p {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
} /*清除内外边距*/
.el-textarea__inner {
  font-size: 13px;
  color: #303133;
  font-weight: 400;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, Arial, sans-serif;
}
.el-input__inner {
  font-size: 13px;
  color: #303133 !important;
  font-weight: 400;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, Arial, sans-serif;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

.no-padding {
  padding: 0px !important;
}

.padding-content {
  padding: 4px 0;
}

a:focus,
a:active {
  outline: none;
}

a,
a:focus,
a:hover {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

div:focus {
  outline: none;
}

.fr {
  float: right;
}

.fl {
  float: left;
}

.pr-5 {
  padding-right: 5px;
}

.pl-5 {
  padding-left: 5px;
}

.block {
  display: block;
}

.pointer {
  cursor: pointer;
}

.inlineBlock {
  display: block;
}

.clearfix {
  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
  }
}

code {
  background: #eef1f6;
  padding: 15px 16px;
  margin-bottom: 20px;
  display: block;
  line-height: 36px;
  font-size: 15px;
  font-family: 'Source Sans Pro', 'Helvetica Neue', Arial, sans-serif;
  a {
    color: #337ab7;
    cursor: pointer;
    &:hover {
      color: rgb(32, 160, 255);
    }
  }
}

.warn-content {
  background: rgba(66, 185, 131, 0.1);
  border-radius: 2px;
  padding: 16px;
  padding: 1rem;
  line-height: 1.6rem;
  word-spacing: 0.05rem;
  a {
    color: #42b983;
    font-weight: 600;
  }
}

.app-container {
  padding: 20px;
}

.components-container {
  margin: 30px 50px;
  position: relative;
}

.pagination-container {
  margin-top: 30px;
}

.text-center {
  text-align: center;
}

.sub-navbar {
  height: 50px;
  line-height: 50px;
  position: relative;
  width: 100%;
  text-align: right;
  padding-right: 20px;
  transition: 600ms ease position;
  background: linear-gradient(
    90deg,
    rgba(32, 182, 249, 1) 0%,
    rgba(32, 182, 249, 1) 0%,
    rgba(33, 120, 241, 1) 100%,
    rgba(33, 120, 241, 1) 100%
  );
  .subtitle {
    font-size: 20px;
    color: #fff;
  }
  &.draft {
    background: #d0d0d0;
  }
  &.deleted {
    background: #d0d0d0;
  }
}

.link-type,
.link-type:focus {
  color: #337ab7;
  cursor: pointer;
  &:hover {
    color: rgb(32, 160, 255);
  }
}

.filter-container {
  padding-bottom: 10px;
  .filter-item {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 10px;
  }
}
.multiselect {
  line-height: 16px;
}

.multiselect--active {
  z-index: 1000 !important;
}
.el-munu {
  border-right: none !important;
}
.preview img {
  width: 100%;
}
.ql-toolbar .ql-font span[data-value='Arial']::before {
  content: 'Arial' !important;
  font-family: 'Arial';
}
.ql-toolbar .ql-font span[data-value='宋体']::before {
  content: '宋体' !important;
  font-family: '宋体';
}
.ql-toolbar .ql-font span[data-value='黑体']::before {
  content: '黑体' !important;
  font-family: '黑体';
}
.ql-toolbar .ql-font span[data-value='微软雅黑']::before {
  content: '微软雅黑' !important;
  font-family: '微软雅黑';
}
/*编辑器内容用*/
.ql-editor .ql-font-Arial {
  font-family: 'Arial';
}
.ql-editor .ql-font-宋体 {
  font-family: '宋体';
}
.ql-editor .ql-font-黑体 {
  font-family: '黑体';
}
.ql-editor .ql-font-微软雅黑 {
  font-family: '微软雅黑';
}
.el-message-box__message p {
  display: inline-block;
  margin: 0;
  line-height: 24px;
  /* width: 200px; */
  /* text-align: center; */
}
.el-table th > .cell {
  text-align: center !important;
}
.hcp_list .el-input--prefix .el-input__inner {
  padding-left: 5px !important;
}
.el-input--suffix .el-input__inner {
  padding-right: 8px !important;
}
.hcp_list .el-form-item__content {
  line-height: 29px !important;
}
.hcp_list .el-form-item__label {
  line-height: 32px !important;
}
.hcp_list .el-input__icon {
  line-height: 30px !important;
}
#hcp_list{
  margin-bottom: 60px;
}
#hcp_list .el-checkbox-group {
  font-size: 0;
}
.el-table {
  font-size: 12px !important;
}
.el-table thead {
  font-size: 13px !important;
}
.el-col-20 {
  width: 95% !important;
}
.el-table__empty-text {
  width: 16% !important;
}
.el-table__empty-block {
  text-align: left !important;
}
.hcp_list .right_icon {
  margin-left: 95%;
  display: inline-block;
  font-size: 12px;
}
.hcp_list .right_icon:hover {
  color: #00857c;
  cursor: pointer;
}
.el-form-item__label {
  text-align: left !important;
}
.el-table td,
.el-table th {
  padding: 5px 0 !important;
}
.el-transfer-panel__list {
  height: 135px!important;
}
.hcp_list .el-input {
  width: 95%;
}
.hcp_list .el-select {
  width: 100%;
}
.hcp_list .el-input__prefix {
  width: none;
  left: 100%;
}
.el-pagination button,
.el-pagination span:not([class*='suffix']) {
  max-width: 90px;
}
.client .el-dialog {
    min-width: 600px!important;
    max-width: 600px!important;
}
.el-submenu .el-menu {
  max-height: 51vh!important;
  overflow-x: hidden!important;
}
.el-form--inline .el-form-item__content{
  width: 250px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none; 
    margin: 0; 
}
input{
    -moz-appearance:textfield;
}
.el-checkbox__input.is-checked+.el-checkbox__label {
  color: #00857c;
}
.el-select-dropdown__item.selected{
  color: #00857c;
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
  color: #00857c;
}
.el-menu-item.is-active{
  color: #00857c!important;
}
.el-input.is-active .el-input__inner, .el-input__inner:focus {
  border-color: #00857c;
}
.el-select .el-input.is-focus .el-input__inner {
  border-color:#00857c;
}
.el-select .el-input__inner:focus {
  border-color: #00857c;
}
.el-date-table td.today span {
  color: #00857c;
}
.el-date-table td.current:not(.disabled) span {
  background-color: #00857c;
}
.el-date-table td.available:hover {
  color: #00857c;
}
.el-pagination__sizes .el-input .el-input__inner:hover {
  border-color: #00857c;
}
.el-table .descending .sort-caret.descending {
  border-top-color: #00857c;
}
.el-table .ascending .sort-caret.ascending{
  border-bottom-color: #00857c;
}
.el-notification .el-icon-success {
    color: #00857c;
}
.el-transfer-panel__item:hover {
    color:  #00857c;
}
</style>
