<template>
  <div class="park error">
    <div class="header">
      <img src="../../assets/image/badge.png" class="badge" alt=""/>
      <img src="../../assets/image/blueLogo.png" class="logo" alt=""/>
    </div>
    <div class="content">
      <el-row style="width:80%; margin:auto">
        <el-col :span="14">
          <div class="grid-content bg-purple-dark">
            <div class="left_img">
              <img src="../../assets/image/404.png" class="error404" alt=""/>
            </div>
          </div>
        </el-col>
        <el-col :span="10">
          <div class="grid-content bg-purple-dark">
            <div class="login_button" @click="toIndex">
              <p style="font-size:36px;font-weight:500;color:rgba(255,255,255,1);font-family:PingFangSC-Semibold,PingFang SC;">返回首页</p>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
export default {
  data() {
    return {
      access_token: null,
      token: null,
      isid: null,
      code: null,
      name: null,
      state: '/management'
    }
  },
  created() {
  },
  methods: {   
    toIndex() {
      this.$router.push({ path: '/management' })
    }
  }
}
</script>

<style scoped>
.park{
  width: 100%;
  /* margin: 30px auto; */
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  color: #333333;
}
.error .header{
  width:100%;
  min-height: 100px;
  background: rgba(255,255,255,1);
  display: flex;
  align-items:center;
}
.error .header .badge{
  width: 51px;
  margin-left: 60px;
}
.error .header .logo{
  height: 36px;
  margin-left: 27px;
}
.error .content{
  width:100%;
  flex: 1;
  display: flex;
  align-items:center;
  background: rgba(255,255,255,1);
}
.error .content .left_img{
  width: 450px;
  height: 350px;
  /* margin-left: 85px; */
}
.error .content .left_img .error404{
  /* float: left; */
  /* margin: auto; */
  width: 100%;
  height: 100%;
}
.error .content .login_button{
  /* float: right; */
  right: 0px;
  margin: auto;
  margin-top:100px;
  width: 200px;
  height: 200px;
  background-color: #00857c;
  border-radius: 200px;
  text-align: center;
  line-height: 200px;
  cursor:pointer;
  
}
a{
color: #13A29C;  
}
p{
  margin-bottom: 20px;
}


</style>
