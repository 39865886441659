<template>
  <div class="park login">
    <div class="header">
      <img src="../../assets/image/badge.png" class="badge" alt />
      <img src="../../assets/image/blueLogo.png" class="logo" alt />
    </div>
    <div class="content">
      <el-row style="width:80%; margin:auto">
        <el-col :span="14">
          <div class="grid-content bg-purple-dark">
            <div class="left_img">
              <img src="../../assets/image/people.png" class="people" alt />
            </div>
          </div>
        </el-col>
        <el-col :span="10">
          <div v-if="stateName" class="grid-content bg-purple-dark">
            <div class="warning">
              <img src="../../assets/image/warning.png" alt />
            </div>
            <div class="permisson_text">
              <p>已登出</p>
            </div>
            <div v-if="!testLoginStatus" class="logout_button" @click="login()">
              <p
                style="font-size:24px;font-weight:400;color:rgba(255,255,255,1);font-family:PingFangSC-Semibold,PingFang SC;"
              >去登录</p>
            </div>
          </div>
          <div v-else class="grid-content bg-purple-dark">
            <div
              v-if="testLoginStatus && !stateName && start"
              class="login_button"
              @click="login()"
            >
              <p
                style="font-size:48px;font-weight:600;color:rgba(255,255,255,1);font-family:PingFangSC-Semibold,PingFang SC;"
              >login</p>
            </div>
            <div v-if="testLoginStatus && !stateName && !start" class="login_button">
              <p
                style="font-size:48px;font-weight:600;color:rgba(255,255,255,1);font-family:PingFangSC-Semibold,PingFang SC;"
              >登录中</p>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div id="footer" class="bottom_footer">
      <a
        target="_blank"
        style=" width:50px; height:18px;color:#BFCBD9;margin-left:50px;font-size:12px;border-bottom:1px solid #BFCBD9;"
        @click="goTerms"
      >使用条款</a>
      <a
        target="_blank"
        style=" width:50px; height:18px;color:#BFCBD9;margin-left:50px;font-size:12px;border-bottom:1px solid #BFCBD9;"
        @click="goStatement"
      >隐私政策</a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      testLoginStatus: true,
      token: null,
      error: null,
      start: true,
      stateName: null,
    }
  },
  created() {
    var url = window.location.href //获取地址栏 url
    var index = url.indexOf('access_token=') //获取#的位置 console.log(0)
    var endIndex = url.indexOf('&state')
    this.error = this.$route.query.error
    this.stateName = this.$route.query.name
    if (this.stateName) {
      this.testLoginStatus = false
    }
    if (index !== -1) {
      if (endIndex !== -1) {
        this.token = url.substr(index + 13, endIndex - index - 13)
      } else {
        this.token = url.substr(index + 13, url.length - index - 13)
      }
    }
    if (this.error) {
      this.$router.push({ name: 'noEmployess', params: { error: this.error } })
    } else {
      if (this.token) {
        this.$global.setToken('token', this.token)
        this.testLogin()
      } else {
        if (
          this.$cookie.get('token') &&
          localStorage.getItem('userPermission')
        ) {
          this.testLogin()
        }
      }
    }
  },
  methods: {
    testLogin() {
      this.stateName = null
      const _this = this
      this.testLoginStatus = true
      this.start = false
      _this.$global.getMe(_this)
    },
    login() {
      this.testLoginStatus = true
      this.stateName = null
      this.start = false
      this.$global.goAuthSSO()
    },
    goStatement() {
      window.open(
        'https://www.msdprivacy.com/cn/cn/internet-privacy-policy.html',
        '_blank'
      )
    },
    goTerms() {
      window.open('http://www.msdchina.com.cn/termsofuse/', '_blank')
    },
  },
}
</script>

<style scoped>
.park {
  width: 100%;
  /* margin: 30px auto; */
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  color: #333333;
}
.login .header {
  width: 100%;
  min-height: 100px;
  background: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
}
.login .header .badge {
  width: 51px;
  margin-left: 60px;
}
.login .header .logo {
  height: 36px;
  margin-left: 27px;
}
.login .content {
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 1);
}
.login .content .left_img {
  width: 669px;
  height: 502px;
}
.login .content .left_img .people {
  width: 100%;
  height: 100%;
}
.login .content .login_button {
  right: 0px;
  margin: auto;
  margin-top: 200px;
  width: 200px;
  height: 200px;
  background-color: #00857c;
  border-radius: 200px;
  text-align: center;
  line-height: 200px;
  cursor: pointer;
}
.login .content .warning {
  margin: auto;
  margin-top: 100px;
  width: 143px;
  height: 128px;
}
.login .content .warning img {
  width: 100%;
  height: 100%;
}
.login .content .permisson_text {
  text-align: center;
}
.login .content .permisson_text {
  font-size: 24px;
  font-weight: 400;
  color: #37424a;
}
.login .content .logout_button {
  margin: auto;
  width: 100px;
  height: 100px;
  background-color: #00857c;
  border-radius: 100px;
  text-align: center;
  line-height: 100px;
  cursor: pointer;
}
.login .footer {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 100px;
  background: rgba(255, 255, 255, 1);
}
a {
  color: #13a29c;
}
p {
  margin-bottom: 20px;
}
.bottom_footer {
  width: 100%;
  height: 40px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background-color: #37424a;
  text-align: center;
  line-height: 40px;
}
</style>
