<template>
  <div class="park user_list">
    <!-- 搜索及添加管理员 -->
    <el-row style="margin-left: 55px;margin-top: 20px;">
      <el-col :span="16">
        <div class="grid-content bg-purple">
          <el-input v-model="search" placeholder="请输入ISID">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </div>
      </el-col>
      <el-col :span="2" style="margin-left: 10px;">
        <div class="grid-content bg-purple-light">
          <el-button type="primary" class="userbtn" plain @click="searchUser">搜索管理员</el-button>
        </div>
      </el-col>
      <el-col :span="2">
        <div class="grid-content bg-purple">
          <el-button
            v-if="$global.comparePermisson('系统管理', 'admin_add_update')"
            type="primary"
            plain
            class="userbtn"
            @click="addUser"
          >添加管理员</el-button>
          <el-dialog
            :close-on-click-modal="false"
            :title="title"
            :visible.sync="dialogFormVisible"
            @close="closeDialog"
          >
            <el-form v-loading="loading" :model="form">
              <el-form-item id="searchIsid" :error="errorIsidMsg" label="ISID" label-width="260">
                <span
                  v-if="hasISId"
                  style="display:inline-block; width:232px;height:24px;"
                >{{ form.isid }}</span>
                <span
                  v-if="hasAdmin"
                  style="display:inline-block; width:232px;height:24px;"
                >{{ form.isid }}</span>
                <el-input
                  v-if="!hasAdmin&&!hasISId"
                  v-model="form.isid"
                  autocomplete="off"
                  placeholder="请输入要添加管理员的ISID"
                  style="width:232px;height:24px;"
                  @blur="isidBlur"
                  @input="isidChange"
                ></el-input>
              </el-form-item>
              <el-form-item id="searchRole" :error="errorRoleMsg" label="角色" label-width="260">
                <el-select
                  v-model="form.roleId"
                  placeholder="请选择角色"
                  style="width:232px;height:24px;"
                  @change="selectRole"
                >
                  <el-option
                    v-for="(item,index) in roleList"
                    :label="item.name"
                    :value="item.id"
                    :key="index"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div
              v-if="$global.comparePermisson('系统管理', 'admin_add_update')"
              slot="footer"
              class="dialog-footer"
            >
              <el-button @click="hideDialogFormVisible">取 消</el-button>
              <el-button
                v-if="hasISId"
                :disabled="loading"
                type="primary"
                @click="editorUserRole"
              >确 定</el-button>
              <el-button v-else type="primary" @click="addUserList">确 定</el-button>
            </div>
          </el-dialog>
        </div>
      </el-col>
    </el-row>
    <!-- 管理员列表 -->
    <div class="park-table">
      <el-table
        v-loading="listLoading"
        :data="userList"
        header-row-class-name="tableHead"
        style="width:100%;padding-bottom:47px;text-align:center;"
      >
        <el-table-column
          v-if="$global.comparePermisson('系统管理', 'admin_details')"
          :show-overflow-tooltip="false"
          prop
          label
          min-width="70"
          max-width="8%"
        >
          <template slot-scope="scope">
            <i
              v-if="!(scope.row.userState||$cookie.get('isid')==scope.row.id||scope.row.roleId=='SuperAdmin')"
              class="el-icon-edit"
              @click="editUser(scope.row)"
            ></i>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="false"
          prop="isid"
          label="ISID"
          min-width="140"
          max-width="21%"
        >
          <template slot-scope="scope">
            <span style="display:inline-block;width:100%; text-align:center;">{{ scope.row.id }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="false"
          prop="roleName"
          label="角色"
          min-width="140"
          max-width="21%"
        >
          <template slot-scope="scope">
            <span
              style="display:inline-block;width:100%; text-align:center;"
            >{{ scope.row.roleName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="false"
          prop="activeState"
          label
          min-width="100"
          max-width="16%"
        >
          <template slot-scope="scope">
            <el-switch
              v-if="!($cookie.get('isid')==scope.row.id||scope.row.roleId=='SuperAdmin')&&$global.comparePermisson('系统管理', 'admin_add_update')"
              v-model="scope.row.activeState"
              @change="editorUserRoleState(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      listLoading: false,
      //自定义校验
      errorIsidMsg: '',
      errorRoleMsg: '',
      //角色列表
      roleList: [],
      //添加编辑title变量
      title: null,
      //定义添加编辑状态开关
      hasISId: false,
      hasAdmin: false,
      //搜索
      search: null,
      //表单索引
      userRole: null,
      //表单数集
      form: {
        id: null,
        isid: null,
        name: null,
        roleId: null,
        roleName: null,
      },
      //管理员列表
      userList: [],
      //弹窗显示隐藏状态
      dialogFormVisible: false,
    }
  },
  created() {
    let data = {
      offset: -1,
    }
    this.getUserList(data)
  },
  mounted() {},
  methods: {
    //获取角色列表
    getRoleList() {
      let data = {
        offset: -1,
      }
      this.loading = true
      this.$global
        .httpGetWithToken(this, '/role', data)
        .then((res) => {
          this.loading = false
          this.roleList = []
          for (const attr in res.data) {
            this.roleList.push(res.data[attr])
          }
        })
        .catch((error) => {
          this.loading = false
        })
    },
    //修改管理员接口
    putUser(_this, url, dataJson) {
      this.listLoading = true
      this.loading = true
      this.$global
        .httpPutWithToken(_this, url, dataJson)
        .then((res) => {
          this.dialogFormVisible = false
          this.userRole = null
          if (this.search == '') {
            this.search = null
          }
          const data = {
            filter: this.search,
            offset: -1,
          }
          this.getUserList(data)
          this.loading = false
          this.$notify({
            title: '成功',
            message: '修改成功',
            type: 'success',
            offset: 38,
          })
        })
        .catch(() => {
          const data = {
            filter: this.search,
            offset: -1,
          }
          this.loading = false
          this.getUserList(data)
        })
    },
    //搜索管理员
    searchUser() {
      if (this.search == '') {
        this.search = null
      }
      const data = {
        filter: this.search,
        offset: -1,
      }
      this.getUserList(data)
    },
    //搜索ISID失焦
    isidBlur() {
      if (!this.form.isid) {
        this.errorIsidMsg = String(Math.random())
        this.$nextTick(() => {
          this.errorIsidMsg = '请输入ISID'
        })
      } else {
        this.errorIsidMsg = ''
        var reg = /(^\s+)|(\s+$)|\s+/g
        var str = this.form.isid
        if (reg.test(str)) {
          this.errorIsidMsg = String(Math.random())
          this.$nextTick(() => {
            this.errorIsidMsg = 'isid不能包含空格'
          })
        }
      }
    },
    //isidchange输入实时触发
    isidChange() {
      var reg = /(^\s+)|(\s+$)|\s+/g
      var str = this.form.isid
      if (reg.test(str)) {
        this.errorIsidMsg = String(Math.random())
        this.$nextTick(() => {
          this.errorIsidMsg = 'isid不能包含空格'
        })
      } else {
        this.errorIsidMsg = ''
      }
    },
    //角色选择校验
    selectRole() {
      if (this.form.roleId) {
        this.errorRoleMsg = ''
      }
    },
    //获取管理员列表
    getUserList(data) {
      this.listLoading = true
      this.$global
        .httpGetWithToken(this, '/admin', data)
        .then((res) => {
          this.userList = []
          //接收转换参数以便开关按钮使用
          for (const attr in res.data) {
            if (res.data[attr].activeState == 0) {
              res.data[attr].activeState = false
            } else if (res.data[attr].activeState == 1) {
              res.data[attr].activeState = true
            } else if (res.data[attr].activeState == -1) {
              res.data[attr].activeState = false
              res.data[attr].userState = true
            }
            this.userList.push(res.data[attr])
          }
          this.listLoading = false
        })
        .catch(() => {
          this.listLoading = false
        })
    },
    //获取employees
    getEmployees(_this, data) {
      this.loading = true
      this.$global
        .httpGetWithToken(_this, '/employee/' + data.isid)
        .then((res) => {
          this.loading = false
          this.$notify({
            title: '成功',
            message: '验证通过',
            type: 'success',
            offset: 38,
          })
          this.form.id = res.data.id
          this.form.isid = res.data.isid
          this.form.name = res.data.name
          this.hasAdmin = true
        })
        .catch(() => {})
    },
    //添加管理员接口
    postUser(_this, data) {
      this.loading = true
      this.$global
        .httpPostWithToken(_this, '/admin', data)
        .then((res) => {
          this.loading = false
          this.$notify({
            title: '成功',
            message: '添加管理员成功',
            type: 'success',
            offset: 38,
          })
          this.hasAdmin = false
          this.dialogFormVisible = false
          this.search = null
          let data = {
            offset: -1,
          }
          this.getUserList(data)
        })
        .catch((error) => {
          this.loading = false
          // console.log('=>',error)
          this.dialogFormVisible = true
        })
    },
    //管理员搜索及判断
    searchISID(ISID) {
      if (ISID) {
        this.userForm = {}
        const data = {
          isid: encodeURIComponent(ISID),
        }
        const _this = this
        this.getEmployees(_this, data)
      } else {
        this.errorIsidMsg = String(Math.random())
        this.$nextTick(() => {
          this.errorIsidMsg = '请输入ISID'
        })
      }
    },
    //添加管理员时点击清除，重置表单信息
    clearISID() {
      this.hasAdmin = false
      this.form.ISID = null
      this.form.id = null
      this.form.isid = null
      this.form.name = null
      this.form.roleId = null
      this.errorIsidMsg = ''
    },
    //添加管理员弹窗内容
    addUser() {
      this.hasISId = false
      this.hasAdmin = false
      this.title = '添加管理员'
      this.dialogFormVisible = true
      this.form.roleId = null
      this.form.ISID = null
      this.form.name = null
      if (this.$global.comparePermisson('系统管理', 'role_list')) {
        this.getRoleList()
      } else {
        this.roleList = []
      }
    },
    //修改管理员弹窗内容
    editUser(row) {
      console.log('row', row)
      this.hasISId = true
      this.title = '修改管理员权限'
      this.dialogFormVisible = true
      this.form.roleId = row.roleId
      this.form.isid = row.id
      this.form.name = row.employeeName
      this.userRole = row
      if (this.$global.comparePermisson('系统管理', 'role_list')) {
        this.getRoleList()
      } else {
        this.roleList = [
          {
            id: row.roleId,
            isSystem: 0,
            name: row.roleName,
          },
        ]
      }
    },
    //修改管理员状态改变
    editorUserRole() {
      // this.dialogFormVisible = false
      this.listLoading = true
      const data = {
        data: {
          activeState: this.userRole.activeState == true ? 1 : 0,
          roleId: this.form.roleId || this.userRole.roleId,
        },
      }
      const _this = this
      this.putUser(_this, '/admin/' + _this.userRole.id, data)
    },

    //点击开关修改管理员状态改变
    editorUserRoleState(row) {
      this.dialogFormVisible = false
      const data = {
        data: {
          activeState: row.activeState == true ? 1 : 0,
          roleId: row.roleId,
        },
      }
      const _this = this
      this.putUser(_this, '/admin/' + row.id, data)
    },
    //添加管理员状态改变
    addUserList() {
      let status = true
      if (!this.form.isid) {
        this.errorIsidMsg = String(Math.random())
        this.$nextTick(() => {
          this.errorIsidMsg = '请输入ISID'
        })
        status = false
      }
      if (!this.form.roleId) {
        this.errorRoleMsg = String(Math.random())
        this.$nextTick(() => {
          this.errorRoleMsg = '请选择管理员角色'
        })
        status = false
      }
      if (this.errorIsidMsg || this.errorRoleMsg) {
        status = false
      }
      if (status) {
        const data = {
          data: {
            activeState: 0,
            id: this.form.isid,
            roleId: this.form.roleId,
          },
        }
        const _this = this
        this.postUser(_this, data)
      }
    },
    //取消操作
    hideDialogFormVisible() {
      this.hasAdmin = false
      this.dialogFormVisible = false
      this.$notify.info({
        title: '消息',
        message: '取消操作',
        offset: 38,
      })
    },
    //关闭弹窗，重置信息
    closeDialog() {
      this.hasAdmin = false
      this.dialogFormVisible = false
      this.form.isid = null
      this.errorIsidMsg = ''
      this.errorRoleMsg = ''
    },
  },
}
</script>
<style scoped>
.park {
  height: 100%;
}
.park-btn {
  margin: 10px;
}
.park-red {
  background-color: red;
  color: white;
  padding: 5px;
}
.park-green {
  background-color: green;
  color: white;
  padding: 5px;
}
.park .el-table th > .cell {
  text-align: center;
}
.park-table {
  /* min-width: 941px; */
  width: 83.323%;
  margin-left: 55px;
  margin-top: 29px;
  box-shadow: 0px 0px 10px 0px rgba(99, 99, 99, 0.33);
}
.park .el-input {
  /* width: 750px; */
}
.park .el-icon-edit:before {
  font-size: 20px;
  margin-left: 20px;
}
.park .el-icon-user:before {
  font-size: 20px;
  margin-left: 10px;
}
.park .el-input__prefix > .el-input__icon {
  position: absolute;
  left: 0;
  top: -5px;
  height: 100%;
  width: 16px;
  font-size: 16px;
  color: #979797;
}
.park .el-input input {
  width: 100%;
}
.userbtn {
  width: 74px;
  height: 27px;
  font-size: 12px;
  text-align: center;
  background: #00857c;
  border-color: #00857c;
  color: #ffffff;
  padding: 0;
}
.userbtn:hover {
  background: #00857c !important;
  color: #ffffff !important;
}
.el-button--primary.is-plain {
  color: #ffffff;
  background: #00857c;
  border-color: #00857c;
}
.park .el-dialog .userbtn {
  width: 55px;
  height: 24px;
  background-color: #00857c;
  border-color: #00857c;
  color: #ffffff;
}
.park .el-dialog .userbtn:hover {
  width: 55px;
  height: 24px;
  background-color: #00857c !important;
  border-color: #00857c !important;
  color: #ffffff !important;
}
</style>
<style type="text/css">
</style>
