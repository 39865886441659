<template>
  <div class="Nopark loginNo">
    <div class="header">
      <img src="../../assets/image/badge.png" class="badge" alt=""/>
      <img src="../../assets/image/blueLogo.png" class="logo" alt=""/>
    </div>
    <div class="content">
      <el-row style="width:80%; margin:auto">
        <el-col :span="14">
          <div class="grid-content bg-purple-dark">
            <div class="left_img">
              <img src="../../assets/image/people.png" class="people" alt=""/>
            </div>
          </div>
        </el-col>
        <el-col :span="10">
          <div class="grid-content bg-purple-dark">
            <div class="warning">
              <img src="../../assets/image/warning.png" alt="">
            </div>
            <div class="permisson_text">
              <p>您无进入该系统权限,请联系管理员！</p>
            </div>
            <div class="login_button" @click="login">
              <p style="font-size:24px;font-weight:400;color:rgba(255,255,255,1);font-family:PingFangSC-Semibold,PingFang SC;">重试</p>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div id="footer" class="bottom_footer">
      <a target="_blank" style=" width:50px; height:18px;color:#BFCBD9;margin-left:50px;font-size:12px;border-bottom:1px solid #BFCBD9;" @click="goTerms">使用条款</a>
      <a target="_blank" style=" width:50px; height:18px;color:#BFCBD9;margin-left:50px;font-size:12px;border-bottom:1px solid #BFCBD9;" @click="goStatement">隐私政策</a>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // error: null,
    }
  },
  created() {
    // this.error = this.$route.params.error
    // this.state = this.$route.query.state
    // if (this.error) {

    // }
  },
  methods: {   
    goStatement() {
      window.open(
        'https://www.msdprivacy.com/cn/cn/internet-privacy-policy.html',
        '_blank'
      )
    },
    goTerms() {
      window.open('http://www.msdchina.com.cn/termsofuse/', '_blank')
    },
    login() {
      this.$router.push({ path: '/login' })
    }
  }
}
</script>

<style scoped>
.Nopark{
  width: 100%;
  /* margin: 30px auto; */
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  color: #333333;
}
.loginNo .header{
  width:100%;
  min-height: 100px;
  background: rgba(255,255,255,1);
  display: flex;
  align-items:center;
}
.loginNo .header .badge{
  width: 51px;
  margin-left: 60px;
}
.loginNo .header .logo{
  height: 36px;
  margin-left: 27px;
}
.loginNo .content{
  width:100%;
  flex: 1;
  display: flex;
  align-items:center;
  background: rgba(255,255,255,1);
}
.loginNo .content .left_img{
  width: 669px;
  height: 502px;
}
.loginNo .content .left_img .people{
  width: 100%;
  height: 100%;
}

.loginNo .content .warning{
  margin: auto;
  margin-top: 100px;
  width: 143px;
  height: 128px;
}
.loginNo .content .warning img{
  width: 100%;
  height: 100%;
}
.loginNo .content .permisson_text{
  text-align: center;
}
.loginNo .content .permisson_text{
  font-size: 24px;
  font-weight: 400;
  color: #37424A;
}
.loginNo .content .login_button{
  margin: auto;
  width: 100px;
  height: 100px;
  background-color: #00857c;
  border-radius: 100px;
  text-align: center;
  line-height: 100px;
  cursor:pointer;
  
}
.loginNo .footer{
  display: flex;
  align-items:center;
  width:100%;
  min-height: 100px;
  background: rgba(255,255,255,1);
}
a{
color: #13A29C;  
}
p{
  margin-bottom: 20px;
}
.bottom_footer {
  width: 100%;
  height: 40px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background-color: #37424A;
  text-align: center;
  line-height: 40px;
}

</style>
