<template>
  <div class="role">
    <el-row style="margin-left: 55px;margin-top: 20px;">
      <el-col :span="2">
        <div class="grid-content bg-purple">
          <el-button
            v-if="$global.comparePermisson('系统管理', 'role_add_update')"
            type="primary"
            plain
            class="rolebtn"
            @click="addUser"
          >添加角色</el-button>
          <!-- //角色信息弹窗 -->
          <el-dialog
            :title="title"
            :visible.sync="dialogFormVisible"
            :close-on-click-modal="false"
            @close="closeDialog"
          >
            <el-form v-loading="loading" ref="form" :model="form" :rules="rolerules">
              <el-form-item :label="giveName" :error="errorNameMsg" prop="name" label-width="260">
                <el-input
                  :disabled="disabled"
                  v-model="form.name"
                  autocomplete="off"
                  style="width:232px;height:24px;"
                  @blur="nameBlur"
                ></el-input>
              </el-form-item>
              <el-form-item
                :label="giveDescription"
                :error="errorDescMsg"
                prop="desc"
                label-width="260"
              >
                <el-input
                  :disabled="disabled"
                  v-model="form.desc"
                  autocomplete="off"
                  style="width:232px;height:24px;"
                  @blur="descBlur"
                ></el-input>
              </el-form-item>
              <el-form-item
                v-if="!hasRoleId"
                :error="errorIdMsg"
                label="角色ID"
                prop="roleId"
                label-width="260"
              >
                <el-input
                  :disabled="disabled"
                  v-model="form.roleId"
                  autocomplete="off"
                  placeholder="不可含中文、空格、特殊字符"
                  style="width:232px;height:24px;"
                  @blur="idBlur"
                ></el-input>
              </el-form-item>
              <el-form-item v-else :error="errorIdMsg" label="角色ID" prop="roleId" label-width="260">
                <span
                  style="display:inline-block; width:232px;height:24px;text-indent:15px;"
                >{{ form.roleId }}</span>
              </el-form-item>
              <el-form-item prop="permission" class="permission_content">
                <el-row :gutter="1" style="margin-left:20px;margin-top:12px;">
                  <el-col :span="24">
                    <div class="grid-content bg-purple">
                      <template v-for="item in permissionList">
                        <p
                          style="background: rgb(102, 183, 176);box-sizing:border-box;padding-left:2px;font-size:15px;"
                        >{{ item.groupName }}</p>
                        <div
                          v-for="item2 in item.list"
                          style="border: 1px solid rgba(102, 183, 176, 0.6);margin-top:1px;"
                        >
                          <el-checkbox
                            :disabled="!$global.comparePermisson('系统管理', 'role_add_update')"
                            :indeterminate="item2.indeterminate"
                            v-model="item2.checkStatus"
                            style="width:100%;height:26px;line-height:26px;background: rgba(102, 183, 176, 0.6);margin-bottom:5px;margin-bottom:1px;padding-left:2px;"
                            @change="handlecheckedAllChange($event,item2)"
                          >{{ item2.moduleName }}</el-checkbox>
                          <el-checkbox-group
                            :disabled="!$global.comparePermisson('系统管理', 'role_add_update')"
                            v-model="form.checkedList"
                            style="margin-left:20px;"
                            @change="handleCheckedChange(item2)"
                          >
                            <el-checkbox
                              v-for="item3 in item2.list"
                              :label="item3.value"
                              :key="item3.value"
                              style="width:135px;margin-right:0px;"
                            >{{ item3.name }}</el-checkbox>
                          </el-checkbox-group>
                        </div>
                        <div style="margin-top: 10px;"></div>
                      </template>
                    </div>
                  </el-col>
                </el-row>
              </el-form-item>
              <p style="color: #F56C6C;font-size: 12px;text-align:center;">{{ errorPermisson }}</p>
            </el-form>
            <div
              v-if="$global.comparePermisson('系统管理', 'role_add_update')"
              slot="footer"
              class="dialog-footer"
            >
              <el-button @click="hideDialogFormVisible">取 消</el-button>
              <el-button
                v-if="hasISId"
                :disabled="loading||disabled"
                type="primary"
                @click="editorUserRole"
              >确 定</el-button>
              <el-button
                v-else
                :autofocus="autofocus"
                :disabled="loading"
                type="primary"
                @click="addroleList"
              >确 定</el-button>
            </div>
          </el-dialog>
        </div>
      </el-col>
    </el-row>
    <div class="role-table">
      <el-table
        v-loading="listLoading"
        :data="roleList"
        header-row-class-name="tableHead"
        style="padding-bottom:47px;text-align:center;"
      >
        <el-table-column
          v-if="$global.comparePermisson('系统管理', 'role_details')"
          :show-overflow-tooltip="false"
          prop
          label
          min-width="80"
          max-width="8%"
        >
          <template slot-scope="scope">
            <i class="el-icon-edit" @click="editUser(scope.$index, scope.row)"></i>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="false"
          prop="name"
          label="角色名称"
          min-width="150"
          max-width="17%"
        >
          <template slot-scope="scope">
            <span style="display:inline-block;width:100%; text-align:center;">{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="false"
          prop="id"
          label="角色id"
          min-width="150"
          max-width="17%"
        >
          <template slot-scope="scope">
            <span style="display:inline-block;width:100%; text-align:center;">{{ scope.row.id }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="false"
          prop="desc"
          label="描述"
          class="description"
          min-width="400"
          max-width="21%"
        >
          <template slot-scope="scope">
            <span
              style="display:inline-block;width:100%; text-align:left;color:#606266;"
            >{{ scope.row.desc }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="$global.comparePermisson('系统管理', 'role_delete')"
          :show-overflow-tooltip="false"
          prop="status"
          label
          min-width="100"
          max-width="16%"
        >
          <template slot-scope="scope">
            <i
              v-if="!(scope.row.isSystem == 1)"
              class="el-icon-close"
              @click="deleteRole(scope.row)"
            ></i>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'User',
  data() {
    return {
      autofocus: false,
      loading: false,
      listLoading: false,
      //错误提示字段
      errorNameMsg: '',
      errorDescMsg: '',
      errorIdMsg: '',
      errorPermisson: '',
      //控制输入roleid显示隐藏
      hasRoleId: false,
      disabled: false,
      //权限组内值，可在此定义初始选项
      checkedList: [],
      //添加编辑弹窗内容变量
      title: null,
      giveName: null,
      giveDescription: null,
      //定义添加编辑变化开关
      hasISId: false,
      //table索引值
      userIndex: null,
      //表单数集
      form: {
        name: null,
        desc: null,
        roleId: null,
        checkedList: [],
      },
      permission: [],
      //角色列表数集
      roleList: [],
      //弹窗显示隐藏状态
      dialogFormVisible: false,
      rolerules: {
        name: [
          { min: 1, max: 50, message: '长度需小于50个字符', trigger: 'blur' },
        ],
        desc: [
          { min: 1, max: 50, message: '长度需小于50个字符', trigger: 'blur' },
        ],
        roleId: [
          { min: 1, max: 50, message: '长度需小于50个字符', trigger: 'blur' },
        ],
      },
      permissionList: [],
    }
  },
  created() {
    let data = {
      offset: -1,
    }
    this.getPermissions(data)
    this.getRoleList()
  },
  destroyed() {},
  methods: {
    //角色名称失焦校验
    nameBlur() {
      var reg = /(^\s+)|(\s+$)/g
      var str = this.form.name
      if (!str) {
        this.errorNameMsg = String(Math.random())
        this.$nextTick(() => {
          this.errorNameMsg = '角色命名不能为空'
        })
      } else if (reg.test(str)) {
        this.errorNameMsg = String(Math.random())
        this.$nextTick(() => {
          this.errorNameMsg = '角色命名首尾不能含空格'
        })
      } else {
        this.errorNameMsg = ''
      }
    },
    //角色描述失焦校验
    descBlur() {
      var reg = /(^\s+)|(\s+$)/g

      var str = this.form.desc
      if (!str) {
        this.errorDescMsg = String(Math.random())
        this.$nextTick(() => {
          this.errorDescMsg = '角色描述不能为空'
        })
      }
      if (reg.test(str)) {
        this.errorDescMsg = String(Math.random())
        this.$nextTick(() => {
          this.errorDescMsg = '角色描述首尾不能含空格'
        })
      } else {
        this.errorDescMsg = ''
      }
    },
    //角色id失焦校验
    idBlur() {
      var regEn = /[`~!@#$%^&*()_+<>?:"{},.\/;'[\]]/im
      var regCn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\]]/im
      var reg = /(^\s+)|(\s+$)|\s+|[\u4e00-\u9fa5]+/g
      var str = this.form.roleId
      if (!str) {
        this.errorIdMsg = String(Math.random())
        this.$nextTick(() => {
          this.errorIdMsg = '角色ID不能为空'
        })
      } else if (reg.test(str)) {
        this.errorIdMsg = String(Math.random())
        this.$nextTick(() => {
          this.errorIdMsg = '角色ID不能含中文和空格及特殊字符'
        })
      } else if (regEn.test(str) || regCn.test(str)) {
        this.errorIdMsg = String(Math.random())
        this.$nextTick(() => {
          this.errorIdMsg = '角色ID不能含中文和空格及特殊字符'
        })
      } else {
        this.errorIdMsg = ''
      }
    },
    //获取权限列表
    getPermissions(data) {
      this.$global
        .httpGetWithToken(this, '/permission',data)
        .then((res) => {
          this.loading = false
          this.permissionList = this.returnList(res.data)
        })
        .catch((err) => {
          this.loading = false
        })
    },
    returnList(resList) {
      const list = this.groupList(resList, 'groupName')
      const list2 = this.groupList(resList, 'moduleName', 'groupName')
      for (const i in list) {
        list[i].list = []
        for (const a in list2) {
          if (list2[a].groupName == list[i].groupName) {
            list2[a].checkStatus = false
            list2[a].indeterminate = false
            list[i].list.push(list2[a])
          }
        }
      }
      return list
    },
    //分组
    groupList(dataList, type, type2) {
      var fieldList = []
      var att = []
      dataList.map((e) => {
        fieldList.push(e[type])
      })
      //数组去重
      fieldList = fieldList.filter((e, i, self) => {
        return self.indexOf(e) == i
      })
      for (var j = 0; j < fieldList.length; j++) {
        //过滤出匹配到的数据
        var arr = dataList.filter((e) => {
          return e[type] == fieldList[j]
        })
        if (type2) {
          att.push({
            [type]: arr[0][type],
            list: arr,
            [type2]: arr[0][type2],
          })
        } else {
          att.push({
            [type]: arr[0][type],
            list: arr,
          })
        }
      }
      return att
    },
    //获取角色列表
    getRoleList() {
      let data = {
        offset: -1,
      }
      this.listLoading = true
      this.$global
        .httpGetWithToken(this, '/role', data)
        .then((res) => {
          this.roleList = []
          for (const attr in res.data) {
            this.roleList.push(res.data[attr])
          }
          this.listLoading = false
        })
        .catch((error) => {
          this.listLoading = false
        })
    },
    //获取角色详情
    getRoleDetail(id) {
      this.$global
        .httpGetWithToken(this, '/role/' + id)
        .then((res) => {
          for (const i in res.data.permission) {
            this.form.checkedList.push(res.data.permission[i].value)
          }
          for (const i in this.permissionList) {
            for (const a in this.permissionList[i].list) {
              this.handleCheckedChange(this.permissionList[i].list[a])
            }
          }
          console.log('权限。。。',this.form.checkedList)
          //已有权限存储到数组中
          this.loading = false
        })
        .catch(() => {})
    },
    // 修改角色接口
    putRole(id, data) {
      this.loading = true
      this.$global
        .httpPutWithToken(this, '/role/' + id, data)
        .then(() => {
          this.dialogFormVisible = false
          this.loading = false
          this.$notify({
            title: '成功',
            message: '修改成功',
            type: 'success',
            offset: 38,
          })

          this.getRoleList()
        })
        .catch(() => {
          this.loading = false
        })
    },
    //加权存储
    mumPermission() {
      this.permission = []
      for (const attr in this.form.checkedList) {
        this.permission.push({ value: this.form.checkedList[attr] })
      }
      if (this.permission.length > 0) {
        this.errorPermisson = ''
      } else if (this.permission.length == 0) {
        this.errorPermisson = '角色权限不能为空,请选择角色权限'
      }
    },
    //权限多选状态改变
    //检测全选状态
    handlecheckedAllChange(val, moduleItem) {
      const value = []
      for (const i in moduleItem['list']) {
        value.push(moduleItem['list'][i].value)
      }

      if (val || moduleItem.indeterminate) {
        const arr = value.concat(this.form.checkedList)
        const arrNew = new Set(arr)
        this.form.checkedList = Array.from(arrNew)
        moduleItem.indeterminate = false
        moduleItem.checkStatus = true
      } else {
        this.deleteList(this.form.checkedList, value)
        moduleItem.indeterminate = false
        moduleItem.checkStatus = false
      }

      this.mumPermission()
    },
    deleteList(checkedList, value) {
      var arr = checkedList
      var brr = value
      var temp = []
      var temparray = []
      for (var i = 0; i < brr.length; i++) {
        temp[brr[i]] = typeof brr[i]
      }
      for (let i = 0; i < arr.length; i++) {
        var type = typeof arr[i]
        if (!temp[arr[i]]) {
          temparray.push(arr[i])
        } else if (temp[arr[i]].indexOf(type) < 0) {
          temparray.push(arr[i])
        }
      }
      this.form.checkedList = temparray
    },
    //选择时触发
    handleCheckedChange(moduleItem) {
      const value = []
      for (const i in moduleItem['list']) {
        value.push(moduleItem['list'][i].value)
      }
      const nowCheckedName = []
      for (const i in this.form.checkedList) {
        if (value.indexOf(this.form.checkedList[i]) != -1) {
          nowCheckedName.push(this.form.checkedList[i])
        }
      }

      if (nowCheckedName.length == 0) {
        moduleItem.indeterminate = false
        moduleItem.checkStatus = false
      } else if (nowCheckedName.length < value.length) {
        moduleItem.indeterminate = true
      } else {
        moduleItem.indeterminate = false
        moduleItem.checkStatus = true
      }
      this.mumPermission()
    },
    //添加角色弹窗设置
    addUser() {
      this.closeDialog()
      this.title = '权限设置'
      this.giveName = '角色命名'
      this.giveDescription = '角色描述'
      this.dialogFormVisible = true
      this.hasISId = false
      if (this.permissionList.length == 0) {
        this.loading = true
      }
    },
    //修改角色弹窗设置
    editUser(index, row) {
      this.loading = true
      this.userIndex = index
      this.hasRoleId = true
      const id = this.roleList[this.userIndex].id
      this.hasISId = true
      this.title = '角色修改'
      this.giveName = '修改名称'
      this.giveDescription = '修改描述'
      this.dialogFormVisible = true

      this.form.name = row.name
      this.form.desc = row.desc
      this.form.roleId = row.id
      this.getRoleDetail(id)
    },
    //修改角色状态改变
    editorUserRole() {
      this.mumPermission()
      const t = this
      let status = true
      if (this.permission.length == 0) {
        this.errorPermisson = String(Math.random())
        this.$nextTick(() => {
          this.errorPermisson = '角色权限不能为空,请选择角色权限'
        })
        status = false
      }
      if (!this.form.name) {
        this.errorNameMsg = String(Math.random())
        this.$nextTick(() => {
          this.errorNameMsg = '角色命名不能为空'
        })
        status = false
      }
      if (!this.form.desc) {
        this.errorDescMsg = String(Math.random())
        this.$nextTick(() => {
          this.errorDescMsg = '角色描述不能为空'
        })
        status = false
      }
      if (this.errorPermisson || this.errorNameMsg || this.errorDescMsg) {
        status = false
      }
      if (status) {
        //form rolues通过方可继续
        t.$refs['form'].validate((valid) => {
          if (valid) {
            this.loading = true
            const data = {
              data: {
                desc: this.form.desc,
                name: this.form.name,
                permission: this.permission,
              },
            }
            const id = this.roleList[this.userIndex].id
            this.putRole(id, data)
          }
        })
      }
    },
    //添加角色状态改变
    addroleList() {
      this.mumPermission()
      const t = this
      let status = true
      if (this.permission.length == 0) {
        this.errorPermisson = String(Math.random())
        this.$nextTick(() => {
          this.errorPermisson = '角色权限不能为空,请选择角色权限'
        })
        status = false
      }
      if (!this.form.name) {
        this.errorNameMsg = String(Math.random())
        this.$nextTick(() => {
          this.errorNameMsg = '角色命名不能为空'
        })
        status = false
      }
      if (!this.form.desc) {
        this.errorDescMsg = String(Math.random())
        this.$nextTick(() => {
          this.errorDescMsg = '角色描述不能为空'
        })
        status = false
      }
      if (!this.form.roleId) {
        this.errorIdMsg = String(Math.random())
        this.$nextTick(() => {
          this.errorIdMsg = '角色ID不能为空'
        })
        status = false
      }
      if (
        this.errorPermisson ||
        this.errorNameMsg ||
        this.errorDescMsg ||
        this.errorIdMsg
      ) {
        status = false
      }
      if (status) {
        //form rolues通过方可继续
        t.$refs['form'].validate((valid) => {
          if (valid) {
            this.loading = true
            const data = {
              data: {
                permission: this.permission,
                id: this.form.roleId,
                name: this.form.name,
                desc: this.form.desc,
              },
            }
            this.$global
              .httpPostWithToken(this, '/role', data)
              .then((res) => {
                this.loading = false
                this.dialogFormVisible = false
                this.$notify({
                  title: '成功',
                  message: '添加成功',
                  type: 'success',
                  offset: 38,
                })
                this.getRoleList()
              })
              .catch((err) => {
                this.loading = false
              })
          }
        })
      }
    },
    //执行删除角色操作弹窗
    deleteRole(row) {
      const _this = this
      this.$confirm('是否确认删除该角色?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        cancelButtonClass: '',
        autofocus: false,
        type: 'warning',
        center: true,
        beforeClose(action, instance, done) {
          if (action == 'confirm') {
            this.$nextTick(()=>{
              if (window.event.detail != 0) {
               //执行删除
                done()
                _this.listLoading = true
                _this.$global
                  .httpDeleteWithToken(_this, '/role/' + row.id)
                  .then((res) => {
                    _this.$notify({
                      title: '成功',
                      message: '删除成功',
                      type: 'success',
                      offset: 38,
                    })
                    _this.getRoleList()
                  })
                  .catch((err) => {
                    _this.listLoading = false
                  })
              }
            })
          } else {
            done()
          }
        },
      }).catch(() => {})
    },
    //取消操作
    hideDialogFormVisible() {
      this.dialogFormVisible = false
      this.$notify.info({
        title: '消息',
        message: '取消操作',
        offset: 38,
      })
    },
    //关闭弹窗时，初始化字段。
    closeDialog() {
      if (this.$refs['form']) {
        //初始化 form roules状态
        this.$refs['form'].resetFields()
      }
      this.loading = false
      this.disabled = false
      this.hasRoleId = false
      this.permission = []
      this.checkedList = []
      this.form.name = null
      this.form.desc = null
      this.form.roleId = null

      this.form.checkedList = []

      this.clearErrorMsg()

      for (const i in this.permissionList) {
        for (const a in this.permissionList[i].list) {
          this.clearIndeterminateUser(this.permissionList[i].list[a])
        }
      }
    },
    clearIndeterminateUser(moduleItem) {
      moduleItem.indeterminate = false
      moduleItem.checkStatus = false
    },
    //清除错误提示
    clearErrorMsg() {
      this.errorNameMsg = ''
      this.errorDescMsg = ''
      this.errorIdMsg = ''
      this.errorPermisson = ''
    },
  },
}
</script>
<style scoped>
.role {
  /* min-width: 1116px; */
  height: 100%;
}
.role-btn {
  margin: 10px;
}
.role-red {
  background-color: red;
  color: white;
  padding: 5px;
}
.role-green {
  background-color: green;
  color: white;
  padding: 5px;
}

.role-table {
  /* min-width: 941.76px; */
  width: 83.333%;
  margin-left: 55px;
  margin-top: 29px;
  box-shadow: 0px 0px 10px 0px rgba(99, 99, 99, 0.33);
}
.role .el-input {
  width: 750px;
}
.role .el-icon-edit:before {
  font-size: 20px;
  margin-left: 20px;
}
.role .el-icon-user:before {
  font-size: 20px;
  margin-left: 10px;
}
.role .el-input__prefix > .el-input__icon {
  position: absolute;
  left: 0;
  top: -5px;
  height: 100%;
  width: 16px;
  font-size: 16px;
  color: rgba(61, 143, 227, 1);
}
.role .el-input input {
  width: 100%;
}
.rolebtn {
  width: 74px;
  height: 27px;
  font-size: 12px;
  text-align: center;
  background: #00857c;
  border-color: #00857c;
  color: #ffffff;
  padding: 0;
}
.rolebtn:hover {
  background: #00857c !important;
  color: #ffffff !important;
}
.el-button--primary.is-plain {
  color: #ffffff;
  background: #00857c;
  border-color: #00857c;
}
.role .el-dialog .rolebtn {
  width: 55px;
  height: 24px;
}
</style>
<style type="text/css">
</style>
