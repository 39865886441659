<template>
  <div class="role">
    <el-row style="margin-left: 55px;margin-top: 20px;">
      <el-col :span="2">
        <div class="grid-content bg-purple">
          <el-button
            v-if="$global.comparePermisson('系统管理', 'client_add_update')"
            type="primary"
            plain
            class="rolebtn"
            @click="addClientDialo"
          >添加客户端</el-button>
          <!-- //客户端信息弹窗 -->
          <el-dialog
            :title="title"
            class="client"
            :visible.sync="dialogFormVisible"
            :close-on-click-modal="false"
            @close="closeDialog"
          >
            <el-form id="clientForm" ref="form" :model="form" :rules="rolerules" style="overflow-y: scroll;height:370px;">
              <el-collapse v-loading="loading" v-model="activeNames" @change="handleChange">
                <el-collapse-item title="基本信息" name="1">
                  <el-form-item
                    :error="errorIdMsg"
                    label="客户端ID"
                    prop="roleId"
                    label-width="260"
                  >
                    <el-input
                      :disabled="title=='修改客户端'"
                      v-model="form.clientId"
                      autocomplete="off"
                      placeholder="不可含中文、空格、特殊字符"
                      style="width:400px;height:24px;"
                      @blur="commentbBlur('clientId','errorIdMsg','客户端ID')"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    :error="errorSecretMsg"
                    label="客户端密钥"
                    prop="roleId"
                    label-width="260"
                  >
                    <el-input
                      :disabled="disabled"
                      v-model="form.clientSecret"
                      autocomplete="off"
                      placeholder="不可含中文、空格、特殊字符"
                      style="width:400px;height:24px;"
                      @blur="commentbBlur('clientSecret','errorSecretMsg','客户端密钥')"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    :error="errorNameMsg"
                    label="客户端名称"
                    prop="roleId"
                    label-width="260"
                  >
                    <el-input
                      :disabled="disabled"
                      v-model="form.clientName"
                      autocomplete="off"
                      placeholder="不可含中文、空格、特殊字符"
                      style="width:400px;height:24px;"
                      @blur="commentbBlur('clientName','errorNameMsg','客户端名称')"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="客户端描述"
                    :error="errorDescMsg"
                    prop="desc"
                    label-width="260"
                  >
                    <el-input
                      :disabled="disabled"
                      v-model="form.clientDes"
                      autocomplete="off"
                      placeholder="请输入客户端描述"
                      style="width:400px;height:24px;"
                      @blur="descBlur"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    v-if="showDomain"
                    :error="errorDomainMsg"
                    label="客户端域名"
                    prop="roleId"
                    label-width="260"
                  >
                    <el-input
                      :disabled="disabled"
                      v-model="form.authDomain"
                      autocomplete="off"
                      placeholder="不可含中文、空格、特殊字符"
                      style="width:400px;height:24px;"
                      @blur="domainBlur"
                    ></el-input>
                  </el-form-item>
                </el-collapse-item>
                <el-collapse-item title="OAuth" name="2">
                  <el-form-item
                    :error="errorgrantTypeMsg"
                    label="认证模式"
                    prop="roleId"
                    id="grantType"
                    label-width="260"
                  >
                    <el-select
                      v-model="form.grantType"
                      multiple
                      placeholder="请选择认证模式"
                      style="width:400px;min-height:24px;"
                      @change="grantTypeChange()"
                    >
                      <el-option label="authorization_code" value="authorization_code"></el-option>
                      <el-option label="implicit" value="implicit"></el-option>
                      <el-option label="client_credentials" value="client_credentials"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    v-show="showDomain"
                    :error="errorRequestMsg"
                    label="认证请求源"
                    id="requestMode"
                    prop="roleId"
                    label-width="260"
                  >
                    <el-select
                      v-model="form.requestMode"
                      multiple
                      placeholder="请选择认证请求源"
                      style="width:400px;min-height:24px;"
                      @change="requestModeChange()"
                    >
                      <el-option label="wechat" value="wechat"></el-option>
                      <el-option label="wechat_work" value="wechat_work"></el-option>
                      <el-option label="scan" value="scan"></el-option>   
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    v-show="showDomain"
                    :error="errorRedirectMsg"
                    label="认证回调地址"
                    prop="roleId"
                    label-width="260"
                  >
                    <el-input
                      :disabled="disabled"
                      v-model="form.authRedirectUri"
                      autocomplete="off"
                      placeholder="认证回调地址仅支持http或https请求"
                      style="width:400px;height:24px;"
                      @blur="httpBlur"
                    ></el-input>
                  </el-form-item>
                </el-collapse-item>
                <el-collapse-item title="隐私信息" name="3">
                  <div style="margin-left:20px;margin-top:8px;">
                    <p style="margin-bottom:10px;">客户端返回信息</p>
                    <el-checkbox-group v-model="form.clientInfo" @change="clientInfoChange()">
                      <el-checkbox style="width:135px;margin-right:0px;margin-bottom:5px" label="mdmDetail">医生信息</el-checkbox>
                      <el-checkbox style="width:135px;margin-right:0px;margin-bottom:5px" label="registerDetail">注册信息</el-checkbox>
                      <el-checkbox style="width:135px;margin-right:0px;margin-bottom:5px" label="employeeDetail">员工信息</el-checkbox>
                    </el-checkbox-group>
                    <p style="height:10px;"></p>
                  </div>
                  <div v-if="showPermission" style="margin-left:20px;margin-top:8px;">
                    <p style="margin-bottom:10px;">服务端接口权限</p>
                    <el-checkbox-group v-model="form.clientPromession" @change="clientPermissionChange()" v-for="item in clientPermissionList" :key="item.name">
                      <el-checkbox style="width:135px;margin-right:0px;margin-bottom:5px;" :label="item.name">{{item.name}}</el-checkbox>
                    </el-checkbox-group>
                    <!-- <p style="height:10px;"></p> -->
                    <p style="color: #F56C6C;font-size: 12px;text-align:left;">{{ errorPermisson }}</p>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </el-form>
            <div
              v-if="$global.comparePermisson('系统管理', 'client_add_update')"
              slot="footer"
              class="dialog-footer"
            >
              <el-button @click="hideDialogFormVisible">取 消</el-button>
              <el-button
                v-if="hasClientId"
                :disabled="loading||disabled"
                type="primary"
                @click="editorClient"
              >确 定</el-button>
              <el-button
                v-else
                :autofocus="autofocus"
                :disabled="loading"
                type="primary"
                @click="addClient"
              >确 定</el-button>
            </div>
          </el-dialog>
        </div>
      </el-col>
    </el-row>
    <div class="role-table">
      <el-table
        v-loading="listLoading"
        :data="roleList"
        header-row-class-name="tableHead"
        style="padding-bottom:47px;text-align:center;"
      >
        <el-table-column
          v-if="$global.comparePermisson('系统管理', 'client_details')"
          :show-overflow-tooltip="false"
          prop
          label
          min-width="80"
          max-width="8%"
        >
          <template slot-scope="scope">
            <i class="el-icon-edit" @click="editUser(scope.$index, scope.row)"></i>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="false"
          prop="name"
          label="客户端名称"
          min-width="100"
          max-width="12%"
        >
          <template slot-scope="scope">
            <span style="display:inline-block;width:100%; text-align:center;">{{ scope.row.clientName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="false"
          prop="id"
          label="客户端ID"
          min-width="150"
          max-width="17%"
        >
          <template slot-scope="scope">
            <span style="display:inline-block;width:100%; text-align:center;">{{ scope.row.id }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="false"
          prop="desc"
          label="客户端描述"
          class="description"
          min-width="100"
          max-width="12%"
        >
          <template slot-scope="scope">
            <span
              style="display:inline-block;width:100%; text-align:left;color:#606266;"
            >{{ scope.row.clientDescription }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="false"
          prop="id"
          label="认证模式"
          min-width="250"
          max-width="17%"
        >
          <template slot-scope="scope">
            <span style="display:inline-block;width:100%; text-align:center;">{{ scope.row.grantType }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="$global.comparePermisson('系统管理', 'client_status')"
          :show-overflow-tooltip="false"
          prop="activeState"
          label
          min-width="100"
          max-width="16%"
        >
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.activeStatus"
              @change="editorClientState(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'User',
  data() {
    return {
      autofocus: false,
      loading: false,
      listLoading: false,
      activeNames: ['1','2','3'],
      //错误提示字段
      errorIdMsg: '',
      errorSecretMsg: '',
      errorNameMsg: '',
      errorDescMsg: '',
      errorDomainMsg:'',
      errorRedirectMsg:'',
      errorRequestMsg:'',
      errorgrantTypeMsg:'',
      errorPermisson:'',
      //控制输入roleid显示隐藏
      scroll: '',
      disabled: false,
      //权限组内值，可在此定义初始选项
      checkedList: [],
      //添加编辑弹窗内容变量
      title: null,
      giveName: null,
      giveDescription: null,
      //定义添加编辑变化开关
      hasClientId: false,
      showDomain:true,
      showPermission:true,
      //table索引值
      userIndex: null,
      //表单数集
      form: {
        clientId: null,
        clientSecret: null,
        clientName: null,
        clientDes: null,
        authDomain: null,
        authRedirectUri: null,
        grantType:[],
        requestMode: [],
        clientInfo:[],
        clientPromession:[],
      },
      permission: [],
      clientPermissionList:[
        {
            "uri": "^/auth/authorize$",
            "method": "GET",
            "name": "/auth/authorize"
        },
        {
            "uri": "^/auth/token$",
            "method": "POST",
            "name": "/auth/token"
        },
        {
            "uri": "^/user/info$",
            "method": "GET",
            "name": "/user/info"
        }
      ],
      checkedPermissionList:[],
      DetailSwitchList:[
        {
          name:"mdmDetail"
        },
        {
          name:"registerDetail"
        },
        {
          name:"employeeDetail"
        }
      ],
      //客户端列表数集
      roleList: [],
      //弹窗显示隐藏状态
      dialogFormVisible: false,
      rolerules: {
        name: [
          { min: 1, max: 50, message: '长度需小于50个字符', trigger: 'blur' },
        ],
        desc: [
          { min: 1, max: 50, message: '长度需小于50个字符', trigger: 'blur' },
        ],
        roleId: [
          { min: 1, max: 50, message: '长度需小于50个字符', trigger: 'blur' },
        ],
      },
      permissionList: [],
    }
  },
  created() {
    this.getPermissions()
    this.getClientList()
  },
  mounted() {
  },
  destroyed() {
    
  },
  methods: {
    handleScroll(){
      var checkSelectDomdocument = document.getElementsByClassName('el-select-dropdown')[0]
      var firstselectDom = document.getElementsByClassName('el-select-dropdown')[1]
      var selectDom  = ''
      firstselectDom?selectDom = firstselectDom:selectDom = checkSelectDomdocument
      if(document.getElementsByClassName('el-select')[0].children[1].className.indexOf('is-focus')!=-1 ||document.getElementsByClassName('el-select')[1].children[1].className.indexOf('is-focus')!=-1){
        if(selectDom.style.display != 'none'){
          if(selectDom.style.top.slice(0,selectDom.style.top.indexOf('px'))>450){
            selectDom.style.display = 'none'
          }
        }
        if(selectDom.style.display == 'none'){
          if(selectDom.style.top.slice(0,selectDom.style.top.indexOf('px'))<450){
            selectDom.style.display = 'block'
          }
        }
      }

    },
    cardBlur(number){
      if(number==1){
        this.commentbBlur('clientId','errorIdMsg','客户端ID')
        this.commentbBlur('clientSecret','errorSecretMsg','客户端密钥')
        this.commentbBlur('clientName','errorNameMsg','客户端名称')
        this.descBlur()
        if(this.showDomain){
          this.domainBlur()
        }
      }else if(number ==2){
        this.selectBlur('grantType','errorgrantTypeMsg','认证模式')
        if(this.showDomain){
          this.selectBlur('requestMode','errorRequestMsg','认证请求源')
          this.httpBlur()
        }
      }else if(number ==3){
        if(this.showPermission){
          this.clientPermissionChange()
        }
      }
    },
    handleChange(val) {
      console.log(val)
      let check = true
      if(this.screenFormCard(val)[0] == 1){
        this.cardBlur(1)
        if(this.showDomain){
          if(this.errorIdMsg||this.errorSecretMsg||this.errorNameMsg||this.errorDescMsg||this.errorDomainMsg){
            return
          }else{
            this.activeNames = val
          }
        }else{
          if(this.errorIdMsg||this.errorSecretMsg||this.errorNameMsg||this.errorDescMsg){
            return
          }else{
            this.activeNames = val
          }  
        }

      }else if(this.screenFormCard(val)[0] == 2){
        this.cardBlur(2)
        if(this.errorRequestMsg||this.errorgrantTypeMsg||this.errorRedirectMsg){
          return
        }else{
          this.activeNames = val
        }
      }else if(this.screenFormCard(val)[0] == 3){
        this.cardBlur(3)
        if(this.errorPermisson){
          return
        }else{
          this.activeNames = val
        }
      }
    },
    screenFormCard(val){
      this.activeNames = ['1','2','3']
      let result = this.activeNames.length>val.length ? this.activeNames : val
      let arr = this.activeNames.length>val.length ? val : this.activeNames
      let shopArr = []
      result.forEach((item, index) =>{
        var isExist = false;
        arr.forEach(li=>{
          if(item=== li){
            isExist = true
            return false
          }
        })
        if(!isExist){
            shopArr.push(item);
          }
      })
      return shopArr
    },
    editorClientState(row) {
      const _this = this
      var txt = ''
      row.activeStatus == true ? txt = '是否确认启用该客户端?' : txt = '是否确认禁用该客户端?'
      this.$confirm(txt, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        cancelButtonClass: '',
        autofocus: false,
        type: 'warning',
        center: true,
        beforeClose(action, instance, done) {
          if (action == 'confirm') {
            this.$nextTick(()=>{
              if (window.event.detail != 0) {
                done()
                _this.listLoading = true
                _this.dialogFormVisible = false
                const data = {
                  data: {
                    activeStatus: row.activeStatus == true ? 1 : 0,
                  },
                }
                _this.putClientStatus(_this, '/client/status/' + row.id, data)
              }
            })
          } else {
            done(
              row.activeStatus == true ? row.activeStatus = false : row.activeStatus = true,
            )
          }
        },
      }).catch(() => {
      })
    },
    putClientStatus(_this, url, dataJson) {
      this.listLoading = true
      this.loading = true
      this.$global
        .httpPutWithToken(_this, url, dataJson)
        .then((res) => {
          console.log(res)
          this.dialogFormVisible = false
          this.getClientList()
          this.loading = false
          this.$notify({
            title: '成功',
            message: '修改成功',
            type: 'success',
            offset: 38,
          })
        })
        .catch(() => {
          this.getClientList()
        })
    },
    //input失焦校验
    commentbBlur(content,errorMsg,msg){
      var regEn = /[`~!@#$%^&*()+<>?:"{},.\/;'[\]]/im
      var regCn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\]]/im
      var reg = /(^\s+)|(\s+$)|\s+|[\u4e00-\u9fa5]+/g
      var str = this.form[content]
      if (!str) {
        this[errorMsg] = String(Math.random())
        this.$nextTick(() => {
          this[errorMsg] = msg+'不能为空'
        })
      } else if (reg.test(str)) {
        this[errorMsg] = String(Math.random())
        this.$nextTick(() => {
          this[errorMsg] = msg+'不能含中文和空格及特殊字符'
        })
      } else if (regEn.test(str) || regCn.test(str)) {
        this[errorMsg] = String(Math.random())
        this.$nextTick(() => {
          this[errorMsg] = msg+'不能含中文和空格及特殊字符'
        })
      } else {
        this[errorMsg] = ''
      }
    },
    //select失焦验证
    selectBlur(content,errorMsg,msg){
      var str = this.form[content]
      console.log(str)
      if (str.length == 0) {
        this[errorMsg] = String(Math.random())
        this.$nextTick(() => {
          this[errorMsg] = msg+'不能为空'
        })
      } else {
        this[errorMsg] = ''
      }
    },
    descBlur() {
      var reg = /(^\s+)|(\s+$)/g
      var str = this.form.clientDes
      if (!str) {
        this.errorDescMsg = String(Math.random())
        this.$nextTick(() => {
          this.errorDescMsg = '客户端描述不能为空'
        })
      }else if (reg.test(str)) {
        this.errorDescMsg = String(Math.random())
        this.$nextTick(() => {
          this.errorDescMsg = '客户端描述首尾不能含空格'
        })
      } else {
        this.errorDescMsg = ''
      }
    },
    grantTypeChange(){
      if(this.form.grantType.length != 0){
        this.errorgrantTypeMsg = ''
      }
      this.queryDom()

    },
    queryDom(){
      if(this.form.grantType.indexOf('client_credentials')!= -1){
        this.showPermission = true
      }else{
        this.checkedPermissionList = []
        this.errorPermisson = ''
        this.showPermission = false
      }
      if(this.form.grantType.length == 1 && this.form.grantType.indexOf('client_credentials')!= -1){
        this.showDomain = false
        this.errorRequestMsg = ''
        this.errorRedirectMsg = ''
        this.errorDomainMsg = ''
      }else{
        this.showDomain = true
        if(this.errorDomainMsg){
          this.handleChange(['1','2','3'])
        }
      }
    },
    requestModeChange(){
      if(this.form.requestMode.length != 0){
        this.errorRequestMsg = ''
      }
    },
    clientInfoChange(){
      console.log(this.form.clientInfo)
    },
    clientPermissionChange(){
      this.checkedPermissionList = []
      for(let i in this.form.clientPromession){
        this.clientPermissionList.forEach(item=>{
          let data = {
              uri: "",
              method: ""
          }
          if(this.form.clientPromession[i]==item.name){
            data.uri = item.uri
            data.method = item.method
            this.checkedPermissionList.push(data)
          }
        })
      }
      if(this.checkedPermissionList.length == 0){
        this.errorPermisson = '服务端接口权限不能为空'
      }else{
        this.errorPermisson = ''
      }
    },
    domainBlur() {
      var regEn = /[`~!@#$%^&*()+<>?"{},\/;'[\]]/im
      var regCn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\]]/im
      var reg = /(^\s+)|(\s+$)|\s+|[\u4e00-\u9fa5]+/g
      var str = this.form.authDomain
      if (!str) {
        this.errorDomainMsg = String(Math.random())
        this.$nextTick(() => {
          this.errorDomainMsg = '客户端域名不能为空'
        })
      } else if (reg.test(str)) {
        this.errorDomainMsg = String(Math.random())
        this.$nextTick(() => {
          this.errorDomainMsg = '客户端域名不能含中文和空格及特殊字符'
        })
      } else if (regEn.test(str) || regCn.test(str)) {
        this.errorDomainMsg = String(Math.random())
        this.$nextTick(() => {
          this.errorDomainMsg = '客户端域名不能含中文和空格及特殊字符'
        })
      } else {
        this.errorDomainMsg = ''
      }
    },
    httpBlur() {
      var reg = /(^\s+)|(\s+$)|\s+|[\u4e00-\u9fa5]+/g
      var http = /(http|https):\/\//
      var str = this.form.authRedirectUri
      console.log(http.test(str))
      if (!str) {
        this.errorRedirectMsg = String(Math.random())
        this.$nextTick(() => {
          this.errorRedirectMsg = '认证回调地址不能为空'
        })
      } else if (reg.test(str)) {
        this.errorRedirectMsg = String(Math.random())
        this.$nextTick(() => {
          this.errorRedirectMsg = '认证回调地址不能含中文和空格'
        })
      } else if (!http.test(str)||(http.test(str)&&str.length == 7)||(http.test(str)&&str.length == 8)) {
        this.errorRedirectMsg = String(Math.random())
        this.$nextTick(() => {
          this.errorRedirectMsg = '认证回调地址不合法'
        })
      } else {
        this.errorRedirectMsg = ''
      }
    },
    //获取权限列表
    getPermissions() {
      this.$global
        .httpGetWithToken(this, '/client/permission')
        .then((res) => {
          console.log(res)
          this.loading = false
          this.clientPermissionList = res.data
        })
        .catch((err) => {
          this.loading = false
        })
    },
    //获取客户端列表
    getClientList() {
      let data = {
        offset: -1,
      }
      this.listLoading = true
      this.$global
        .httpGetWithToken(this, '/client', data)
        .then((res) => {
          console.log(res)
          this.roleList = []
          for (const attr in res.data) {
            if (res.data[attr].activeStatus == 0) {
              res.data[attr].activeStatus = false
            } else if (res.data[attr].activeStatus == 1) {
              res.data[attr].activeStatus = true
            }
            this.roleList.push(res.data[attr])
          }
          this.listLoading = false
        })
        .catch((error) => {
          this.listLoading = false
        })
    },
    //获取客户端详情
    getClientDetail(id) {
      this.$global
        .httpGetWithToken(this, '/client/' + id)
        .then((res) => {
        this.loading = false
        this.form.clientId = res.data.id
        this.form.clientSecret = res.data.clientSecret
        this.form.clientName = res.data.clientName
        this.form.clientDes = res.data.clientDescription
        this.form.authDomain = res.data.authDomain
        this.form.authRedirectUri = res.data.authRedirectUri
        if(res.data.grantType){
          this.form.grantType = res.data.grantType.split(',')
        }
        if(res.data.requestMode){
          this.form.requestMode = res.data.requestMode.split(',')
        }
        if(res.data.mdmDetail == 1 )this.form.clientInfo.push('mdmDetail')
        if(res.data.registerDetail == 1 )this.form.clientInfo.push('registerDetail')
        if(res.data.employeeDetail == 1 )this.form.clientInfo.push('employeeDetail')
        this.form.clientPromession = []
        for(let i in res.data.permission){
          this.clientPermissionList.forEach(item=>{
            let data = {
                uri: "",
                method: ""
            }
            if(res.data.permission[i].uri==item.uri){
              this.form.clientPromession.push(item.name)
              data.uri = item.uri
              data.method = item.method
              this.checkedPermissionList.push(data)
            }
          })
        }
        this.queryDom()
          //已有权限存储到数组中
        })
        .catch(() => {})
    },
    // 修改客户端接口
    putClient(id, data) {
      this.loading = true
      this.$global
        .httpPutWithToken(this, '/client/' + id, data)
        .then(() => {
          this.dialogFormVisible = false
          this.loading = false
          this.$notify({
            title: '成功',
            message: '修改成功',
            type: 'success',
            offset: 38,
          })

          this.getClientList()
        })
        .catch(() => {
          this.loading = false
        })
    },
    //添加客户端弹窗设置
    addClientDialo() {
      this.closeDialog()
      this.title = '添加客户端'
      this.giveName = 'clientId'
      this.giveDescription = 'clientDes'
      this.dialogFormVisible = true
      this.$nextTick(()=>{
        var dom = document.getElementById('clientForm')
        dom.addEventListener('scroll', this.handleScroll, false)
      })
      this.hasClientId = false
      if (this.clientPermissionList.length == 0) {
        this.loading = true
      }
    },
    //修改客户端弹窗设置
    editUser(index, row) {
      this.loading = true
      this.userIndex = index
      const id = this.roleList[this.userIndex].id
      this.hasClientId = true
      this.title = '修改客户端'
      this.giveName = 'clientId'
      this.giveDescription = 'clientDes'
      this.dialogFormVisible = true
      this.getClientDetail(id)
    },
    //修改客户端状态改变
    editorClient() {
      // this.mumPermission()
      const t = this
      let status = true
      this.checkBlur()
      if(this.errorgrantTypeMsg||this.errorIdMsg||this.errorSecretMsg||this.errorNameMsg||this.errorDescMsg||this.errorDomainMsg||this.errorRedirectMsg||this.errorRequestMsg||this.errorPermisson){
        status = false
      }
      if (status) {
        //form rolues通过方可继续
        t.$refs['form'].validate((valid) => {
          if (valid) {
            this.loading = true
            const data = {
              data: {
                clientSecret: this.form.clientSecret,
                clientName: this.form.clientName,
                clientDescription: this.form.clientDes,
                grantType: this.form.grantType,
              },
            }
            if(this.showDomain){
              data.data["authDomain"] = this.form.authDomain
              data.data["requestMode"] = this.form.requestMode
              data.data["authRedirectUri"] = this.form.authRedirectUri
            }
            data.data["mdmDetail"] = this.form.clientInfo.indexOf('mdmDetail') != -1 ? 1 : 0
            data.data["registerDetail"] = this.form.clientInfo.indexOf('registerDetail') != -1 ? 1 : 0
            data.data["employeeDetail"] = this.form.clientInfo.indexOf('employeeDetail') != -1 ? 1 : 0
            if(this.checkedPermissionList.length!=0){
              data.data["permission"] = this.checkedPermissionList
            }
            const id = this.roleList[this.userIndex].id
            this.putClient(id, data)
          }
        })
      }
    },
    checkBlur(){
      this.descBlur()
      if(this.showDomain){
        this.domainBlur()
        this.httpBlur()
        this.selectBlur('requestMode','errorRequestMsg','认证请求源')
      }
      if(this.showPermission){
        this.clientPermissionChange()
      }
      this.commentbBlur('clientId','errorIdMsg','客户端ID')
      this.commentbBlur('clientSecret','errorSecretMsg','客户端密钥')
      this.commentbBlur('clientName','errorNameMsg','客户端名称')
      this.selectBlur('grantType','errorgrantTypeMsg','认证模式')
    },
    addClient() {
      // this.mumPermission()
      const t = this
      let status = true
      this.checkBlur()
      if(this.errorgrantTypeMsg||this.errorIdMsg||this.errorSecretMsg||this.errorNameMsg||this.errorDescMsg||this.errorDomainMsg||this.errorRedirectMsg||this.errorRequestMsg||this.errorPermisson){
        status = false
      }
      if (status) {
        //form rolues通过方可继续
        t.$refs['form'].validate((valid) => {
          if (valid) {
            this.loading = true
            const data = {
              data: {
                id: this.form.clientId,
                clientSecret: this.form.clientSecret,
                clientName: this.form.clientName,
                clientDescription: this.form.clientDes,
                activeStatus: 0,
                grantType: this.form.grantType
              },
            }
            if(this.showDomain){
              data.data["authDomain"] = this.form.authDomain
              data.data["requestMode"] = this.form.requestMode
              data.data["authRedirectUri"] = this.form.authRedirectUri
            }
            data.data["mdmDetail"] = this.form.clientInfo.indexOf('mdmDetail') != -1 ? 1 : 0
            data.data["registerDetail"] = this.form.clientInfo.indexOf('registerDetail') != -1 ? 1 : 0
            data.data["employeeDetail"] = this.form.clientInfo.indexOf('employeeDetail') != -1 ? 1 : 0
            if(this.checkedPermissionList.length!=0){
              data.data["permission"] = this.checkedPermissionList
            }

            this.$global
              .httpPostWithToken(this, '/client', data)
              .then((res) => {
                this.loading = false
                this.dialogFormVisible = false
                this.$notify({
                  title: '成功',
                  message: '添加成功',
                  type: 'success',
                  offset: 38,
                })
                this.getClientList()
              })
              .catch((err) => {
                this.loading = false
              })
          }
        })
      }
    },
    //取消操作
    hideDialogFormVisible() {
      var dom = document.getElementById('clientForm')
      dom.removeEventListener('scroll', this.handleScroll, false)
      this.dialogFormVisible = false
      this.$notify.info({
        title: '消息',
        message: '取消操作',
        offset: 38,
      })
    },
    //关闭弹窗时，初始化字段。
    closeDialog() {
      if (this.$refs['form']) {
        //初始化 form roules状态
        this.$refs['form'].resetFields()
      }

      this.loading = false
      this.disabled = false
      this.form.clientId = null
      this.showDomain = true
      this.form.clientSecret = null
      this.form.clientName = null
      this.form.clientDes = null
      this.form.authDomain = null
      this.form.authRedirectUri = null
      this.activeNames = ['1','2','3']
      this.form.grantType = []
      this.form.requestMode = []
      this.form.clientInfo = []
      this.form.clientPromession = []
      this.checkedPermissionList = []
      this.clearErrorMsg()

    },
    //清除错误提示
    clearErrorMsg() {
      this.errorIdMsg = ''
      this.errorSecretMsg = ''
      this.errorNameMsg = ''
      this.errorDescMsg = ''
      this.errorDomainMsg = ''
      this.errorRedirectMsg = ''
      this.errorRequestMsg = ''
      this.errorgrantTypeMsg = ''
      this.errorPermisson = ''
    },
  },
}
</script>
<style scoped>
.role {
  /* min-width: 1116px; */
  height: 100%;
}
.role-btn {
  margin: 10px;
}
.role-red {
  background-color: red;
  color: white;
  padding: 5px;
}
.role-green {
  background-color: green;
  color: white;
  padding: 5px;
}

.role-table {
  /* min-width: 941.76px; */
  width: 83.333%;
  margin-left: 55px;
  margin-top: 29px;
  box-shadow: 0px 0px 10px 0px rgba(99, 99, 99, 0.33);
}
.role .el-input {
  width: 750px;
}
.role .el-icon-edit:before {
  font-size: 20px;
  margin-left: 20px;
}
.role .el-icon-user:before {
  font-size: 20px;
  margin-left: 10px;
}
.role .el-input__prefix > .el-input__icon {
  position: absolute;
  left: 0;
  top: -5px;
  height: 100%;
  width: 16px;
  font-size: 16px;
  color: rgba(61, 143, 227, 1);
}
.role .el-input input {
  width: 100%;
}
.rolebtn {
  width: 74px;
  height: 27px;
  font-size: 12px;
  text-align: center;
  background: #00857c;
  border-color: #00857c;
  color: #ffffff;
  padding: 0;
}
.rolebtn:hover {
  background: #00857c !important;
  color: #ffffff !important;
}
.el-button--primary.is-plain {
  color: #ffffff;
  background: #00857c;
  border-color: #00857c;
}
.role .el-dialog .rolebtn {
  width: 55px;
  height: 24px;
}
.role .el-form-item__error {
    padding-top: 10px;
}
.role .el-dialog__body .permission_content .el-form-item__content {
}
</style>
<style type="text/css">
.role .el-form-item__error {
    /* padding-top: 10px; */
}
#clientForm .el-form-item__label{
  padding-bottom:5px;
  padding-top:2px;
}
 .role-table .el-switch.is-checked .el-switch__core {
    background-color: #00857c;
    border-color: #00857c;
  }
  #clientForm .el-loading-mask{
    /* height: 800px; */
  }
  .client .el-dialog__body {
    height: 400px;
  }
</style>
