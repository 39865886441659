const ErrorCode = {
  200: {
    10002: '签名错误',
    10003: '系统设置无法更改',
    10005: '请求频率过快',
    //角色相关错误码
    20001: '角色ID不存在',
    20002: '角色ID格式错误',
    20003: '角色ID已存在请勿重复添加',
    20004: '角色已经使用无法删除',
    20005: '角色名称已存在请勿重复添加',
    20006: '角色id和角色名称已存在请勿重复添加',
    20007: '您暂无权限进入本系统请联系管理员',
    20008: 'ISID不存在',
    20009: 'ISID已存在',
    20010: '超级管理员受控角色',
    20011: '管理员不存在',
    20012: '系统已禁用该员工，禁止激活',
    //用户相关错误码
    20020: 'mdmCode不存在',
    20021: '该医生已添加',
    20026: '客户端id已存在请勿重复添加',
    20027: '客户端密钥已存在请勿重复添加',
    20028: '客户端名称已存在请勿重复添加',
    20029: '客户端不存在',
    //auth
    30001: '医院信息不存在',
    30002: '用户类型不存在',
    30003: '部门信息不存在',
    30004: '该医生已经绑定其他微信',
    30005: 'maas api错误',
    30006: 'dcr api错误',
    30007: 'mobile错误',
    30008: '验证码错误',
    //hcp
    40001: '医生信息不存在',
  },
  400: {
    //参数校验失败
    //参数校验错误
    101: '操作失败',
    102: '没有对应数据',
    103: '无效的参数',
    20030: '客户端权限不能为空',
    10004: '参数校验错误', // 400
  },
  401: {
    //token无效
    90001: '时间戳错误',
    90002: 'domain错误',
    90003: '登录已失效，请重新登录',
    90004: '权限错误',
    90005: 'token格式错误',
  },
  403: {
    //权限不足
  },
  404: {
    //资源不存在
  },
  500: {
    //服务端错误
    10001: '系统错误', // 500
  },
}

export default ErrorCode
