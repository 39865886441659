export default {
  // NODE_ENV: 'testing',
  VUE_APP_URL: 'https://vapitst.msdp.cn',
  VUE_SIGN_URL: 'vapitst.msdp.cn',
  VUE_CLIENT_ID: '8GQtw4C6iDchJWNmn0SJeA',
  VUE_GRANT_TYPE: 'implicit',
  VUE_REQUEST_MODE: 'sso',
  // VUE_SKIP_REGISTRATION: '1',//默认不传
  VUE_REDIRECT_URL: 'https://vadmintst.msdp.cn/login',
  VUE_RESPONSE_TYPEL: 'token',
  VUE_STATE: 'state'
}
