import Vue from 'vue'
import App from './App'
import router from './router'
import ElementUI from 'element-ui'
var VueCookie = require('vue-cookie')
import 'element-ui/lib/theme-chalk/index.css'
import Vuex from 'vuex'
import store from './vuex/store.js'
import global from './global/global.js'
import dtime from 'time-formater'
import clipboard from 'clipboard'

import './assets/css/hcp.css'
import './assets/css/layout.css'
import './assets/css/message.css'
import './assets/css/role.css'
import './assets/css/user.css'
import './assets/css/channel.css'

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(Vuex)
Vue.use(VueCookie)
Vue.prototype.$global = global
Vue.prototype.$dtime = dtime
Vue.prototype.Clipboard = clipboard

// 路由拦截

router.beforeEach((to, from, next) => {
  store.commit('clearToken') // 取消请求
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})
// this.$cookies.config(60 * 30 * 1 * 1,'')
new Vue({
  el: '#app',
  router,
  store,
  // components: { App },
  // template: '<App/>',
  render: h => h(App)
}).$mount('#app')
