<style>
.channel .el-form-item__error {
  margin-left: 120px;
 
}
.el-form-item__error{
  padding-top:0px !important;
}
</style>
<template>
  <div class="channel">
    <el-row style="margin-left: 55px;margin-top: 20px;">
      <el-col :span="6">
        <div class="label">
          Client Id: 
          <el-input
            placeholder=""
            v-model="query.f_client_id"
            style="width: 134px;"
            >
          </el-input>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="label">
          Channel Code: 
          <el-input
            placeholder=""
            v-model="query.f_channel_code"
            style="width: 134px;"
            >
          </el-input>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="label">
          Mdm Code: 
          <el-input
            placeholder=""
            v-model="query.f_mdm_code"
            style="width: 134px;"
            >
          </el-input>
        </div>
      </el-col>
    </el-row>

    <el-row style="margin-left: 55px;margin-top: 0px;">
      <el-col :span="2">
        <el-button type="primary" @click="search" plain class="rolebtn">搜索</el-button>
      </el-col>

      <el-col :span="2">
        <div class="grid-content bg-purple">
          <el-button
            type="primary"
            plain
            class="rolebtn"
            style="width: 126px"
            @click="addBlacklistForm"
            >添加高频禁用名单</el-button
          >
          <!-- //角色信息弹窗 -->
          <el-dialog
            :title="title"
            :visible.sync="dialogFormVisible"
            :close-on-click-modal="false"
            @close="closeDialog"
          >
            <el-form
              v-loading="loading"
              ref="form"
              :model="query"
              :rules="rolerules"
            >
            <el-form-item
                label="client id"
                :error="errorClientIdMsg"
                error-style="margin-top:-8px"
                prop="client id"
                label-width="200"
              >
              <el-input
                  :disabled="disabled"
                  v-model="query.client_id"
                  type="textbox"
                  autocomplete="off"
                  style="width:252px;"
                  @blur="clientIdBlur"
                  readonly="true"
                ></el-input>
              </el-form-item>

              <el-form-item
                label="channel code"
                prop="channel code"
                label-width="200"
              >
              <el-input
                  :disabled="disabled"
                  v-model="query.channel_code"
                  type="textbox"
                  autocomplete="off"
                  style="width:252px;"
                ></el-input>
              </el-form-item>

              <el-form-item
                label="Mdm_codes"
                :error="errorUnionIdsMsg"
                prop="Mdm_codes"
                label-width="200"
              >
                <el-input
                  :disabled="disabled"
                  v-model="query.mdm_codes"
                  rows="6"
                  type="textarea"
                  autocomplete="off"
                  style="width:252px;"
                  @blur="unionIdsBlur"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="备注"
                :error="errorCommentMsg"
                prop="blacklistComment"
                label-width="200"
                style="margin-top:20px;"
              >
                <el-input
                  :disabled="disabled"
                  v-model="query.comment"
                  rows="3"
                  type="textarea"
                  autocomplete="off"
                  style="width:252px;"
                  @blur="commentBlur"
                ></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="hideDialogFormVisible">取 消</el-button>

              <el-button
                :autofocus="autofocus"
                :disabled="loading"
                type="primary"
                @click="addBlacklist"
                >确 定</el-button
              >
            </div>
          </el-dialog>
        </div>
      </el-col>
    </el-row>
    <div class="role-table" style="padding-bottom: 50px;">
      <el-table
        v-loading="listLoading"
        :data="rows"
        style="padding-bottom:0px;text-align:center;"
      >
      <el-table-column
          :show-overflow-tooltip="false"
          prop="client_id"
          label="client_id"
          min-width="220"
          max-width="17%"
        >
          <template slot-scope="scope">
            <span style="display:inline-block;width:100%; text-align:center;">{{
              scope.row.clientId
            }}</span>
          </template>
        </el-table-column>

        <el-table-column
          :show-overflow-tooltip="false"
          prop="channel_code"
          label="channel_code"
          min-width="220"
          max-width="17%"
        >
          <template slot-scope="scope">
            <span style="display:inline-block;width:100%; text-align:center;">{{
              scope.row.channelCode
            }}</span>
          </template>
        </el-table-column>

        <el-table-column
          :show-overflow-tooltip="false"
          prop="mdm_code"
          label="mdm_code"
          min-width="220"
          max-width="17%"
        >
          <template slot-scope="scope">
            <span style="display:inline-block;width:100%; text-align:center;">{{
              scope.row.mdmCode
            }}</span>
          </template>
        </el-table-column>

        <el-table-column
          :show-overflow-tooltip="false"
          prop="bind_status"
          label="绑定状态"
          min-width="150"
          max-width="17%"
        >
          <template slot-scope="scope">
            <span style="display:inline-block;width:100%; text-align:center;">{{
              scope.row.bindStatus
            }}</span>
          </template>
        </el-table-column>

        <el-table-column
          :show-overflow-tooltip="false"
          prop="createTime"
          label="创建时间"
          min-width="150"
          max-width="17%"
        >
          <template slot-scope="scope">
            <span style="display:inline-block;width:100%; text-align:center;">{{
              scope.row.createTime
            }}</span>
          </template>
        </el-table-column>

        <el-table-column
          :show-overflow-tooltip="false"
          prop="operator"
          label="操作者"
          min-width="150"
          max-width="17%"
        >
          <template slot-scope="scope">
            <span style="display:inline-block;width:100%; text-align:center;">{{
              scope.row.operator
            }}</span>
          </template>
        </el-table-column>

        <el-table-column
          :show-overflow-tooltip="false"
          prop="comment"
          label="备注"
          min-width="150"
          max-width="20%"
        >
          <template slot-scope="scope">
            <span style="display:inline-block;width:100%; text-align:center;">{{
              scope.row.comment
            }}</span>
          </template>
        </el-table-column>

        <el-table-column
          :show-overflow-tooltip="false"
          prop="id"
          label="操作"
          width="70"
        >
          <template slot-scope="scope">
            <el-button
              type="primary"
              class="sub_review sub"
              style="width:50px;margin-top: -3px;"
              plain
              @click="removeBlacklistForm(scope.row)"
              >解禁</el-button
            >
          </template>
        </el-table-column>

        <!-- <el-table-column
          v-if="$global.comparePermisson('系统管理', 'role_delete')"
          :show-overflow-tooltip="false"
          prop="status"
          label
          min-width="100"
          max-width="16%"
        >
          <template slot-scope="scope">
            <i
              v-if="!(scope.row.isSystem == 1)"
              class="el-icon-close"
              @click="deleteRole(scope.row)"
            ></i>
          </template>
        </el-table-column> -->
      </el-table>
      <el-pagination
        :current-page="currentPage"
        :page-sizes="[20, 50, 100]"
        :page-size="parseInt(page.limit)"
        :total="page.total"
        style="margin:center;margin-top:12px;margin-bottom:12px;height:46px;text-align:center;"
        layout="total,sizes,prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>

    <el-dialog
      :title="title"
      :visible.sync="dialogResultVisible"
      :close-on-click-modal="false"
      @close="closeDialog"
    >
      <div class="qrcode">
        <div>已成功添加{{ res_data.success.length }}个高频禁用</div>
        <div>失败{{ res_data.failed.length }}个</div>
        <div v-if="res_data.failed.length > 0">
          以下{{ res_data.failed.length }}个添加失败的mdm_code
        </div>
        <div v-for="row in res_data.failed">{{ row }}</div>
      </div>
    </el-dialog>

    <!-- 解禁-->
    <el-dialog
      :title="title"
      :visible.sync="dialogRemoveBlacklistVisible"
      :close-on-click-modal="false"
      @close="closeDialog"
    >
      <el-form v-loading="loading" ref="form" :model="query">
        <el-form-item label="mdm_code" prop="mdm_code" label-width="200">
          {{ query.remove_mdm_code }}
        </el-form-item>
        <el-form-item
          label="解禁原因"
          :error="errorRemoveCommentMsg"
          prop="blacklistComment"
          label-width="200"
          style="margin-top:20px;"
        >
          <el-input
            :disabled="disabled"
            v-model="query.remove_comment"
            rows="6"
            type="textarea"
            autocomplete="off"
            style="width:252px;"
            @blur="removeCommentBlur"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="hideDialogRemoveBlacklistVisible">取 消</el-button>

        <el-button
          :autofocus="autofocus"
          :disabled="loading"
          type="primary"
          @click="removeBlacklist"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  name: "User",
  data() {
    return {
      query: {
        remove_id: "",
        remove_mdm_code: "",
        remove_comment: "",
        f_mdm_code: "",
        f_client_id: "",
        f_channel_code: "",
        mdm_codes: "",
        client_id:"sfa",
        channel_code:"",
        comment: "",
      },
      //分页默认设置
      page: {
        limit: 20,
        offset: 0,
        total: 0,
      },
      order: "+",
      sortProp: "createTime",
      //初始显示页码
      currentPage: 1,
      autofocus: false,
      loading: false,
      listLoading: false,
      //错误提示字段
      errorUnionIdsMsg: "",
      errorClientIdMsg: "",
      errorCommentMsg: "",
      errorRemoveCommentMsg: "",
      errorIdMsg: "",
      errorPermisson: "",
      //控制输入roleid显示隐藏
      // hasRoleId: false,
      disabled: false,
      //权限组内值，可在此定义初始选项
      checkedList: [],
      //添加编辑弹窗内容变量
      title: null,
      // giveName: null,
      // giveDescription: null,
      //定义添加编辑变化开关
      isAdd: true,
      //table索引值
      // userIndex: null,
      //表单数集
      // form: {
      //   channelCode: null,
      //   channelDescription: null,
      //   isQrcode: 0,
      // },

      //列表数据
      rows: [],
      //弹窗显示隐藏状态
      dialogFormVisible: false,
      dialogResultVisible: false,
      dialogRemoveBlacklistVisible: false,
      res_data: { success: [], failed: [], blacklist: [] },
      rolerules: {
        mdm_codes: [
          { min: 1, max: 50, message: "长度需小于10个字符", trigger: "blur" },
        ],
        comment: [
          { min: 1, max: 50, message: "长度需小于3个字符", trigger: "blur" },
        ],
      },
      permissionList: [],
    };
  },
  created() {
    let data = {
      offset: -1,
    };
    // this.getPermissions(data)
    // this.getRoleList()

    this.loadData();
  },
  destroyed() {},
  methods: {
    search(){
    let data = {
      offset: -1,
    };
    this.loadData();
  },
    //union_ids失焦校验
    unionIdsBlur() {
      var str = this.query.mdm_codes;
      if (!str) {
        this.errorUnionIdsMsg = "mdm_codes不能为空";
      } else {
        this.errorUnionIdsMsg = "";
      }
      this.$forceUpdate();
    },
    clientIdBlur() {
      var str = this.query.client_id;
      if (!str) {
        this.errorClientIdMsg = "client id不能为空";
      } else {
        this.errorClientIdMsg = "";
      }
      this.$forceUpdate();
    },
    //备注失焦校验
    commentBlur() {
      console.log(111);

      // var reg = /(^\s+)|(\s+$)/g;
      var str = this.query.comment;
      if (!str) {
        this.errorCommentMsg = "备注不能为空";
      } else {
        this.errorCommentMsg = "";
      }
      // if (reg.test(str)) {
      //   this.errorCommentMsg = "备注首尾不能含空格";
      // } else {
      //   this.errorCommentMsg = "";
      // }
      this.$forceUpdate();
    },
    //备注失焦校验
    removeCommentBlur() {
      // var reg = /(^\s+)|(\s+$)/g;
      var str = this.query.remove_comment;
      if (!str) {
        this.errorRemoveCommentMsg = "解禁原因不能为空";
      } else {
        this.errorRemoveCommentMsg = "";
      }

      this.$forceUpdate();
    },
    //获取列表
    loadData() {
      let data = {
        offset: this.page.offset,
        limit: this.page.limit,
        order: this.order + this.sortProp,
        channel_code: this.query.f_channel_code,
        client_id: this.query.f_client_id,
        mdm_code: this.query.f_mdm_code,
      };
      this.listLoading = true;
      this.$global
        .httpGetWithToken(this, "/overclock/list", data)
        .then((res) => {
          console.log("列表", res);
          this.rows = res.data;
          // for (const attr in res.data) {
          //   this.roleList.push(res.data[attr]);
          // }

          this.page.total = res.total;
          this.listLoading = false;
          this.$forceUpdate();
        })
        .catch((error) => {
          this.listLoading = false;
        });
    },
    //点击分页时触发,改变列表内容
    handleSizeChange(val) {
      this.currentPage = 1;
      this.page.limit = val;
      this.page.offset = 0;

      this.loadData();
    },
    //按页数展示
    handleCurrentChange(val) {
      this.currentPage = val;
      this.page.offset = (val - 1) * this.page.limit;

      this.loadData();
    },
    //添加渠道弹窗设置
    addBlacklistForm() {
      this.closeDialog();
      this.title = "添加高频禁用";
      this.dialogFormVisible = true;
      this.isAdd = true;
      // if (this.permissionList.length == 0) {
      //   this.loading = true
      // }
    },

    //解禁
    removeBlacklistForm(row) {
      console.log("row", row);
      this.closeDialog();
      this.query.remove_id = row.id;
      this.query.remove_mdm_code = row.mdmCode;
      this.title = "解禁高频禁用";
      this.dialogRemoveBlacklistVisible = true;
      this.$forceUpdate();
    },
    // remove(row) {
    //   const _this = this;
    //   this.$confirm("是否确认删除该渠道?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     cancelButtonClass: "",
    //     autofocus: false,
    //     type: "warning",
    //     center: true,
    //     beforeClose(action, instance, done) {
    //       if (action == "confirm") {
    //         this.$nextTick(() => {
    //           if (window.event.detail != 0) {
    //             //执行删除
    //             done();
    //             _this.listLoading = true;
    //             _this.$global
    //               .httpDeleteWithToken(_this, "/channel/remove/" + row.id)
    //               .then((res) => {
    //                 _this.$notify({
    //                   title: "成功",
    //                   message: "删除成功",
    //                   type: "success",
    //                   offset: 38,
    //                 });
    //                 _this.loadData();
    //               })
    //               .catch((err) => {
    //                 _this.listLoading = false;
    //                 _this.loadData();
    //               });
    //           }
    //         });
    //       } else {
    //         done();
    //       }
    //     },
    //   }).catch(() => {});
    // },
    showResulut(res) {
      // console.log(row);
      //https://vapi.msdp.cn/auth/wechat/register?channel_code=vbu

      this.title = `提示`;
      this.dialogResultVisible = true;
      this.res_data = res.data;
    },
    resetForm() {
      this.query = {
        client_id: "sfa",
        mdm_codes: "",
        comment: "",
        remove_id: "",
        remove_mdm_code: "",
        remove_comment: "",
      };
      this.dialogFormVisible = false;
      this.dialogRemoveBlacklistVisible = false;
    },
    addBlacklist() {
      if (!this.query.mdm_codes) {
        this.errorUnionIdsMsg = "mdm_code不能为空";
        return;
      }

      if (!this.query.comment) {
        this.errorCommentMsg = "备注不能为空";
        return;
      }
      let query = {
        client_id: this.query.client_id,
        channel_code: this.query.channel_code,
        mdm_codes: [],
        comment: this.query.comment,
      };

      let arr = this.query.mdm_codes.split("\n");

      for (let row of arr) {
        if (!row) continue;
        query.mdm_codes.push(row);
      }

      console.log("---", query);

      this.$global
        .httpPostWithToken(this, "/overclock/add", { data: query })
        .then((res) => {
          this.listLoading = false;
          // this.roleList = []
          // for (const attr in res.data) {
          //   this.roleList.push(res.data[attr])
          // }

          if (res.err) {
            this.$notify({
              title: "错误",
              message: res.msg,
              type: "error",
              offset: 38,
              duration: 0,
            });
            return;
          }
          // this.$notify({
          //   title: "成功",
          //   message: "添加成功",
          //   type: "success",
          //   offset: 38,
          // });
          this.showResulut(res);
          this.resetForm();
          //this.handleSizeChange(res.total);
          //this.page.total = res.total;
          this.loadData();
        })
        .catch((error) => {
          console.log("--error", error);
          this.$notify({
            title: "错误",
            message: error,
            type: "error",
            offset: 38,
            duration: 0,
          });

          this.listLoading = false;
        });
    },
    removeBlacklist() {
      if (!this.query.remove_comment) {
        this.errorRemoveCommentMsg = "解禁原因不能为空";
        return;
      }
      let query = {
        id: this.query.remove_id,
        comment: this.query.remove_comment,
      };

      console.log("---", query);

      this.$global
        .httpPostWithToken(this, "/overclock/update", { data: query })
        .then((res) => {
          this.listLoading = false;
          // this.roleList = []
          // for (const attr in res.data) {
          //   this.roleList.push(res.data[attr])
          // }

          if (res.err) {
            this.$notify({
              title: "错误",
              message: res.msg,
              type: "error",
              offset: 38,
              duration: 0,
            });
            return;
          }
          // this.$notify({
          //   title: "成功",
          //   message: "添加成功",
          //   type: "success",
          //   offset: 38,
          // });
          // this.showResulut(res);
          this.resetForm();
          //this.handleSizeChange(res.total);
          //this.page.total = res.total;
          this.loadData();
        })
        .catch((error) => {
          console.log("--error", error);
          this.$notify({
            title: "错误",
            message: error,
            type: "error",
            offset: 38,
            duration: 0,
          });

          this.listLoading = false;
        });
    },
    //取消操作
    hideDialogRemoveBlacklistVisible() {
      this.dialogRemoveBlacklistVisible = false;
      this.$notify.info({
        title: "消息",
        message: "取消操作",
        offset: 38,
      });
    },
    //取消操作
    hideDialogFormVisible() {
      this.dialogFormVisible = false;
      this.$notify.info({
        title: "消息",
        message: "取消操作",
        offset: 38,
      });
    },
    //关闭弹窗时，初始化字段。
    closeDialog() {
      if (this.$refs["form"]) {
        //初始化 form roules状态
        this.$refs["form"].resetFields();
      }
      this.loading = false;
      this.disabled = false;
      this.hasRoleId = false;
      this.permission = [];
      this.checkedList = [];
      this.query.client_id = "sfa";
      this.query.mdm_codes = "";
      this.query.comment = "";
      this.query.remove_id = "";
      this.query.remove_mdm_code = "";
      this.query.remove_comment = "";
      // this.form.roleId = null;

      // this.form.checkedList = [];

      this.clearErrorMsg();
    },
    clearIndeterminateUser(moduleItem) {
      moduleItem.indeterminate = false;
      moduleItem.checkStatus = false;
    },
    //清除错误提示
    clearErrorMsg() {
      this.errorUnionIdsMsg = "";
      this.errorClientIdMsg = "";
      this.errorCommentMsg = "";
      this.errorRemoveCommentMsg = "";
      
    },
  },
};
</script>
<style scoped>
.channel {
  /* min-width: 1116px; */
  height: 100%;
}
.role-btn {
  margin: 10px;
}
.role-red {
  background-color: red;
  color: white;
  padding: 5px;
}
.role-green {
  background-color: green;
  color: white;
  padding: 5px;
}

.role-table {
  /* min-width: 941.76px; */
  width: 83.333%;
  margin-left: 55px;
  margin-top: 29px;
  box-shadow: 0px 0px 10px 0px rgba(99, 99, 99, 0.33);
}
.channel .el-input {
  width: 750px;
}
.channel .el-icon-edit:before {
  font-size: 20px;
  margin-left: 20px;
}
.channel .el-icon-user:before {
  font-size: 20px;
  margin-left: 10px;
}
.channel .el-input__prefix > .el-input__icon {
  position: absolute;
  left: 0;
  top: -5px;
  height: 100%;
  width: 16px;
  font-size: 16px;
  color: rgba(61, 143, 227, 1);
}
.channel .el-input input {
  width: 100%;
}
.rolebtn {
  width: 74px;
  height: 27px;
  font-size: 12px;
  text-align: center;
  background: #00857c;
  border-color: #00857c;
  color: #ffffff;
  padding: 0;
}
.rolebtn:hover {
  background: #00857c !important;
  color: #ffffff !important;
}
.el-button--primary.is-plain {
  color: #ffffff;
  background: #00857c;
  border-color: #00857c;
}
.channel .el-dialog .rolebtn {
  width: 55px;
  height: 24px;
}
.channel .el-input input {
  width: 100%;
  height: 24px!important;
  border: 1px solid #DCDFE6!important;
}
.channel .el-button {
  margin-top: 24px;
}

.channel .label {
    font-size: 12px;
    font-weight: 400;
    color: #606266;
}
/* 
.channel .el-input__inner {
    height: 24px!important;
    line-height: 40px;
    color: #979797;
}

.channel .el-input__inner {
    -webkit-appearance: none;
    background-color: #FFF;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6!important;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    width: 100%;
} */

.el-radio__input.is-checked .el-radio__inner {
  border-color: #00857c !important;
  background-color: #00857c !important;
}
.el-radio__input.is-checked + .el-radio__label {
  color: #00857c !important;
}
.qrcode img {
  margin: 0 auto;
}
.sub {
  width: 44px;
  height: 20px !important;
  font-size: 12px !important;
  text-align: center !important;
  background: rgba(240, 242, 245, 1);
  color: #00857c;
  padding: 0 !important;
}
            


</style>
